import {
  GET_SOUND_DATA_FAILURE,
  GET_SOUND_DATA_REQUEST,
  GET_SOUND_DATA_SUCCESS,
} from "../../Utils/Constants";

export const getSoundDataRequest = (payload) => {
  return {
    type: GET_SOUND_DATA_REQUEST,
    payload: payload,
  };
};
export const getSoundDataSuccess = (data) => {
  return {
    type: GET_SOUND_DATA_SUCCESS,
    payload: data,
  };
};
export const getSoundDataFailure = (error) => {
  return {
    type: GET_SOUND_DATA_FAILURE,
    payload: error,
  };
};
