import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { SpinnerLoader } from "../Loaders/SpinnerLoader";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { Select } from "../../components/select/Select";

export const PasswordModal = ({
  heading,
  nameLabel,
  submitLabel,
  submitLabelsm,
  handleSubmit,
  setOpenModal,
  data,
  isLoading,
  flage,
  secondNameLable,
  prevValue,
}) => {
  const [placeholder, setPlaceholder] = useState("");
  const [name, setName] = useState(data?.name ? data?.name : "");
  const [secondInput, setSecondInput] = useState(
    data?.maxAmount ? data?.maxAmount : ""
  );
  const [botRank, setBotRank] = useState(
    data?.botWinnerCount ? data?.botWinnerCount : ""
  );
  const [passwordError, setPasswordError] = useState("");
  const [isToggleTrue, setIsToggleTrue] = useState(data?.isEnabled);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleBotRankAndPercentage = (id, value) => {
    if (id === "dropdownOptions") {
      setBotRank(value);
    }
  };

  const handleSecondInput = (event) => {
    const confirmPassword = event.target.value;
    setSecondInput(confirmPassword);

    // Check if passwords match
    if (name !== confirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const handleMaxInput = (event) => {
    setSecondInput(event.target.value);
  };
  useEffect(() => {
    if (flage === "commitionRate") {
      setPlaceholder(prevValue);
    }
    if (
      flage === "quizBotsProbability" ||
      flage === "quizWinningBotsProbability"
    ) {
      setPlaceholder(prevValue);
    }
    if (flage === "quizSpotPercentageRate") {
      setPlaceholder(prevValue);
    }

    if (flage === "review") {
      setPlaceholder(prevValue);
    }
    if (flage === "reward") {
      setPlaceholder(data?.name);
    }
    if (data?.name) {
      setPlaceholder(`Current Name : ${data.name}`);
    }
  }, [data]);

  const [dropdownOptions, setDropdownOptions] = useState([
    {
      id: 0,
      name: "0",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3",
    },
  ]);
  return (
    <div
      className="fixed h-[100vh] w-full top-0 right-0 z-50 flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
    >
      <div tabIndex="-1" aria-hidden="true">
        <div className="relative w-[300px] sm:w-[420px]">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
              data-modal-hide="authentication-modal"
            >
              <svg
                onClick={() => {
                  setOpenModal(false);
                }}
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-3 md:mb-4 text-xl font-medium text-gray-900">
                {heading}
              </h3>
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (flage === "reward") {
                    handleSubmit(name, secondInput, isToggleTrue);
                  } else if (flage === "quizWinningBotsProbability") {
                    handleSubmit(name, botRank);
                  } else {
                    handleSubmit(name);
                  }
                  setOpenModal((prev) => !prev);
                }}
              >
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-1 sm:mb-2 text-sm font-medium text-gray-900"
                  >
                    {nameLabel}
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder={placeholder}
                    value={name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4CAF50] focus:border-[#4CAF50] block w-full p-1 placeholder:px-1"
                    onChange={handleNameChange}
                    required
                  />
                </div>
                {flage === "quizWinningBotsProbability" && (
                  <div className="">
                    <label
                      htmlFor="password"
                      className="block mb-1 sm:mb-2 text-sm font-medium text-gray-900"
                    >
                      Bots Rank
                    </label>
                    <Select
                      id="dropdownOptions"
                      options={dropdownOptions}
                      callbackFn={handleBotRankAndPercentage}
                      selectedValue={botRank}
                    />
                  </div>
                )}
                {flage === "resetPassword" && (
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-1 sm:mb-2 text-sm font-medium text-gray-900"
                    >
                      {secondNameLable}
                    </label>
                    <input
                      type="password"
                      name="name"
                      placeholder={data?.name && `Current Name : ${data.name}`}
                      value={secondInput}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4CAF50] focus:border-[#4CAF50] block w-full p-1"
                      onChange={handleSecondInput}
                      required
                    />
                    {passwordError && (
                      <div className="text-red-500 text-sm mt-1">
                        {passwordError}
                      </div>
                    )}
                  </div>
                )}
                {flage === "reward" && (
                  <div>
                    <div>
                      <label
                        htmlFor="maxAmount"
                        className="block mb-1 sm:mb-2 text-sm font-medium text-gray-900"
                      >
                        {secondNameLable}
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder={
                          data?.maxAmount && `Current Name : ${data.maxAmount}`
                        }
                        value={secondInput}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4CAF50] focus:border-[#4CAF50] block w-full p-1"
                        onChange={handleMaxInput}
                        required
                      />
                      {/* {passwordError && (
                      <div className="text-red-500 text-sm mt-1">
                        {passwordError}
                      </div>
                    )} */}
                    </div>
                    {/* <div className=" mt-4 flex justify-between gap-4">
                      <span className="block mb-1 sm:mb-2  font-medium text-gray-900">
                        Active :{" "}
                      </span>
                      <Switch
                        defaultChecked={data?.isEnabled}
                        checked={isToggleTrue}
                        onChange={(checked) => setIsToggleTrue(checked)}
                      />
                    </div> */}
                  </div>
                )}
                <button
                  type="submit"
                  className="w-full text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  disabled={passwordError !== ""}
                >
                  {isLoading ? (
                    <span className="ml-2">
                      <SpinnerLoader size="xs" />
                    </span>
                  ) : (
                    <>
                      <span className="hidden md:inline-block">
                        {submitLabel}
                      </span>
                      <span className="inline-block md:hidden">
                        {submitLabelsm}
                      </span>
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
