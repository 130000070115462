import React from "react";
import { formatNumber } from "../../Utils/numberConversion";
import GreenLoader from "../Loaders/GreenLoader";
import TooltipATM from "../ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

const DashBoardCard2 = ({
  title,
  loading,
  value,
  message,
  totalCashCount,
  totalFreeCount,
  placeTooltip,
  contentTooltip,
}) => {
  return (

    <div className="border max-w-[9.5rem] mb-2 shadow-md rounded-lg p-2 flex-grow bg-cover bg-center">
        <div className="text-sm text-gray-500 font-semibold">
          <div className="flex flex-row gap-0 justify-start align-middle text-center align-content-center">
            {title}
            <div className="my-auto ml-1">
              <TooltipATM
                dataTooltipId={"dashboard-card2-tooltip"}
                dataTooltipPlace={placeTooltip}
                dataTooltipHtml={contentTooltip}
                className={"cursor-pointer"}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="min-h-12 flex items-center">
            <GreenLoader />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mt-2 ">
              <div className="text-[2.1rem] my-2 px-2 font-bold">
                {formatNumber(value)}
              </div>
              <div className="flex gap-4">
                {totalFreeCount !== null && totalFreeCount !== undefined && (
                  <div className="text-center mt-3">
                    <div className="text-[0.8rem] font-semibold text-gray-500">
                      Free
                    </div>
                    <div className="text-black text-[0.8rem] font-bold">
                      {formatNumber(totalFreeCount)}
                    </div>
                  </div>
                )}

                {totalCashCount !== null && totalCashCount !== undefined && (
                  <div className="text-center mt-3">
                    <div className="text-[0.8rem] font-semibold text-gray-500">
                      Cash
                    </div>
                    <div className="text-black text-[0.8rem] font-bold">
                      {formatNumber(totalCashCount)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="text-[0.7rem] my-1 text-gray-500">{message}</div>
        <ReactTooltip
          id="dashboard-card2-tooltip"
          className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50 ibutton"
          style={{ zIndex: "1000" }}
        />
    </div>
  );
};

export default DashBoardCard2;
