import { useEffect, useState } from "react";

export const Select = ({
  id,
  header,
  initialValue,
  options,
  callbackFn,
  showInitial,
  dropDown,
  error,
  disabled,
  selectedValue,
}) => {
  const [key, setKey] = useState(0);
  const [selected, setSelectedValue] = useState(selectedValue);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // 768px is typically the breakpoint for tablets
    };

    // Set initial value
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (showInitial) {
      setKey((prevKey) => prevKey + 1);
      const storedValue = localStorage.getItem(`${id}_selectedValue`);

      if (storedValue) {
        setSelectedValue(storedValue);
        callbackFn(id, storedValue);
      } else {
        setSelectedValue("");
      }
    }
  }, [showInitial]);

  const handleSelectChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedValue(selectedOption);
    localStorage.setItem(`${id}_selectedValue`, selectedOption);
    callbackFn(id, selectedOption);
  };

  const handleOptionSelect = (optionId) => {
    setSelectedValue(optionId);
    setIsDropdownOpen(false);
    localStorage.setItem(`${id}_selectedValue`, optionId);
    callbackFn(id, optionId);
  };

  const handleReset = () => {
    setSelectedValue("");
    localStorage.removeItem(`${id}_selectedValue`);
    callbackFn(id, "");
  };

  useEffect(() => {
    if (!dropDown) {
      handleReset();
    }
  }, [dropDown, error]);

  useEffect(() => {
    setSelectedValue(selectedValue);
  }, [selectedValue]);

  return (
    <div className="relative w-full md:w-[100px] lg:w-[160px]">
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
        {header}
      </label>
      
      {/* Desktop/Tablet - Native Select */}
      {!isMobile && (
        <select
          id={id}
          disabled={disabled}
          onChange={handleSelectChange}
          value={selected}
          className={`w-full p-2.5 text-sm rounded-lg border focus:ring-green-500 focus:border-green-500
            ${error ? "border-red-800 bg-red-200" : "border-gray-300"}
            ${disabled ? "text-gray-400" : "text-gray-900"}`}
        >
          {initialValue && (
            <option value="" disabled>
              {initialValue}
            </option>
          )}
          {options?.map((option, index) => (
            <option value={option.id} key={index}>
              {option.name}
            </option>
          ))}
        </select>
      )}

      {/* Mobile - Custom Dropdown */}
      {isMobile && (
        <div className="relative">
          <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={`w-full p-2.5 text-sm rounded-lg border focus:ring-green-500 focus:border-green-500 cursor-pointer
              ${error ? "border-red-800 bg-red-200" : "border-gray-300"}
              ${disabled ? "text-gray-400 cursor-not-allowed" : "text-gray-900"}`}
          >
            {options?.find(opt => opt.id === selected)?.name || initialValue || "Select an option"}
          </div>
          
          {isDropdownOpen && (
            <ul className="absolute left-0 top-full w-full bg-white border border-gray-300 shadow-lg rounded-lg mt-1 z-10 max-h-60 overflow-y-auto">
              {options?.map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() => handleOptionSelect(option.id)}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};