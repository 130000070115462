import {
    UPDATE_USER_ROLE_REQUEST,
    UPDATE_USER_ROLE_SUCCESS,
    UPDATE_USER_ROLE_FAILURE,
} from "../../Utils/Constants";

  
const initialState = {
    updateUserRole: {},
    loading: false,
    error: null,
  };
  
 const updateUserRoleReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_USER_ROLE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case UPDATE_USER_ROLE_SUCCESS:
        return {
          ...state,
          updateUserRole: action.payload,
          loading: false,
          error: null,
        };
      case UPDATE_USER_ROLE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default updateUserRoleReducer;
  