import React, { useEffect, useState } from "react";
import { TbReportAnalytics, TbDeviceAnalytics } from "react-icons/tb";
import { CgPerformance } from "react-icons/cg";
import android from "../../assets/images/android.jpg";
import IOS from "../../assets/images/apple.png";
import { Tooltip } from "react-tooltip";
import TooltipATM from "../ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { REACT_APP_ANDROID_CRASH_URL, REACT_APP_ANDROID_DEVICE_ANALYTICS_URL, REACT_APP_ANDROID_LISTING_PERFORMANCE_URL, REACT_APP_IOS_CRASH_URL, REACT_APP_IOS_DEVICE_ANALYTICS_URL, REACT_APP_IOS_LISTING_PERFORMANCE_URL } from "../../Utils/Constants";

const buttonArray = [
  { label: "Crash Report", icon: TbReportAnalytics, type: "crash" },
  {
    label: "Device Analytics",
    icon: TbDeviceAnalytics,
    type: "device analytics",
  },
  {
    label: "Store Listing Performance",
    icon: CgPerformance,
    type: "listing performance",
  },
];

const MobileAppCard = ({ platform, version, placeTooltip, contentTooltip }) => {
  const [backgroundImage, setBackgroundImage] = useState(null);
  useEffect(() => {
    const preloadImages = async () => {
      const iosImg = new Image();
      iosImg.src = IOS;
      const androidImg = new Image();
      androidImg.src = android;

      iosImg.onload = () => {
        if (platform === "iOS") setBackgroundImage(IOS);
      };
      androidImg.onload = () => {
        if (platform === "Android") setBackgroundImage(android);
      };
    };

    preloadImages();
  }, [platform]);

  const handleButtonClick = (type) => {
    if (type === "crash" && platform === "Android") {
      // Open Firebase Crashlytics link for Android in a new tab
      window.open(REACT_APP_ANDROID_CRASH_URL, "_blank");
    } else if (type === "crash" && platform === "iOS") {
      // Open Firebase Crashlytics link for iOS in a new tab
      window.open(REACT_APP_IOS_CRASH_URL, "_blank");
    }
    if (type === "device analytics" && platform === "Android") {
      // Open Firebase device analytics link for Android in a new tab
      window.open(REACT_APP_ANDROID_DEVICE_ANALYTICS_URL, "_blank");
    } else if (type === "device analytics" && platform === "iOS") {
      // Open Firebase Crashlytics link for iOS in a new tab
      window.open(REACT_APP_IOS_DEVICE_ANALYTICS_URL, "_blank");
    }

    if (type === "listing performance" && platform === "Android") {
      // Open Firebase listing performance link for Android in a new tab
      window.open(
        REACT_APP_ANDROID_LISTING_PERFORMANCE_URL,
        "_blank"
      );
    } else if (type === "listing performance" && platform === "iOS") {
      // Open Firebase Crashlytics link for iOS in a new tab
      window.open(REACT_APP_IOS_LISTING_PERFORMANCE_URL, "_blank");
    }
  };
  return (
    <div
      className="flex flex-grow max-w-[9.5rem] shadow-md  bg-white mb-2 justify-center items-center border rounded-md bg-cover bg-center relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Transparent overlay to make the text readable */}
      <div className="absolute inset-0 bg-white bg-opacity-50"></div>

      {/* Main content of the card */}
      <div className="p-2 w-full h-full relative flex flex-col gap-4 md:gap-5 z-3">
        <div className="text-sm  font-bold">
          <div className="flex flex-row gap-0 justify-start align-middle text-center align-content-center">
            Latest Version
            <div className="my-auto ml-1">
              <TooltipATM
                dataTooltipId={"dashboard-mobile-tooltip"}
                dataTooltipPlace={placeTooltip}
                dataTooltipHtml={contentTooltip}
                className={"cursor-pointer "}
              />
            </div>
          </div>

          <div className="font-bold text-xl">{version}</div>
        </div>
        <div className="py-0 px-0 flex">
          {buttonArray.map((item, index) => {
            const IconComponent = item.icon;
            return (
              <div key={index} className="w-full flex justify-end relative z-0">
                <button
                  className="p-1 m-1 focus:outline-none ring-0 active:outline-none border border-[#9ce6a4] hover:bg-[#47A04F] outline-none hover:text-white text-[0.8rem] rounded-md flex items-center"
                  data-tooltip-id={`tooltip-${index}`}
                  data-tooltip-content={item.label}
                  onClick={() => handleButtonClick(item.type)}
                >
                  <IconComponent className="text-2xl" />
                </button>

                {/* Tooltip definition */}
                <Tooltip id={`tooltip-${index}`} place="top" />
              </div>
            );
          })}
        </div>
        <ReactTooltip
          id="dashboard-mobile-tooltip"
          className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50 ibutton"
        />
      </div>
    </div>
  );
};

export default MobileAppCard;
