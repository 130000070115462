import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import { TbServerOff } from "react-icons/tb";
import Loading from "../../components/loading";
import { Search } from "../../components/Search";
import { capitalizeWords } from "../../Utils/capitalizeFn";
import { formatDate } from "../../Utils/dateFn";
import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Select } from "../../components/select/Select";
import PaginationFooter from "../../components/PaginationFooter";

const tableHeaders = [
  "Scenario",
  "Screen Name",
  "Category name",
  "Topic Name",
  "Phone",
  "Date / time",
  "Platform",
  "Values",
];

const AnalyticsActivity = () => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueInput, setSearchValueInput] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastDoc, setLastDoc] = useState(null);
  const [pageStack, setPageStack] = useState([]);
  const [itemperPage, setItemperPage] = useState(20);
  const [isSearching, setIsSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([
    {
      id: 5,
      name: "5",
    },
    {
      id: 10,
      name: "10",
    },
    {
      id: 20,
      name: "20",
    },
    {
      id: 50,
      name: "50",
    },
    {
      id: 100,
      name: "100",
    },
  ]);

  const fetchFirestoreCollection = async (
    page = 1,
    isNext = true,
    gotoFirst = false,
    isSearch = false
  ) => {
    try {
      setLoading(true);
      const collectionRef = collection(db, "Analytics_Production");

      if (page === 1) {
        let countQuery = "";
        if (isSearch) {
          countQuery = query(collectionRef, where("phone", "==", searchValue));
        } else {
          countQuery = query(collectionRef);
        }
        const snapshot = await getCountFromServer(countQuery);
        const totalItems = snapshot.data().count;
        setTotalPages(Math.ceil(totalItems / itemperPage));
      }

      let q;
      if (gotoFirst && !isSearch) {
        q = query(
          collectionRef,
          orderBy("datetime", "desc"),
          limit(itemperPage)
        );
      } else if (isSearch && page === 1) {
        q = query(
          collectionRef,
          where("phone", "==", searchValue),
          orderBy("datetime", "desc"),
          limit(itemperPage)
        );
      } else if (isSearch && isNext) {
        q = query(
          collectionRef,
          where("phone", "==", searchValue),
          orderBy("datetime", "desc"),
          startAfter(lastDoc),
          limit(itemperPage)
        );
      } else if (isSearch && !isNext && pageStack.length > 1) {
        const prevDoc = pageStack[pageStack.length - 2];
        q = query(
          collectionRef,
          where("phone", "==", searchValue),
          orderBy("datetime", "desc"),
          startAt(prevDoc),
          limit(itemperPage)
        );
      } else if (isNext && lastDoc) {
        q = query(
          collectionRef,
          orderBy("datetime", "desc"),
          startAfter(lastDoc),
          limit(itemperPage)
        );
      } else if (!isNext && pageStack.length > 1) {
        const prevDoc = pageStack[pageStack.length - 2];
        q = query(
          collectionRef,
          orderBy("datetime", "desc"),
          startAt(prevDoc),
          limit(itemperPage)
        );
      } else {
        q = query(
          collectionRef,
          orderBy("datetime", "desc"),
          limit(itemperPage)
        );
      }

      const snapshot = await getDocs(q);
      const docsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (gotoFirst) {
        setPageStack([snapshot.docs[0]]);
        setCurrentPage(page);
      } else if (isSearch && isNext) {
        setPageStack((prev) => [...prev, snapshot.docs[0]]);
      } else if (isSearch && !isNext) {
        setPageStack((prev) => prev.slice(0, -1));
      } else if (isNext) {
        setPageStack((prev) => [...prev, snapshot.docs[0]]);
      } else {
        setPageStack((prev) => prev.slice(0, -1));
      }

      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setItemData(docsList);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching documents:", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (searchValue !== "") {
      setLastDoc(null);
      setPageStack([]);
      fetchFirestoreCollection(1, true, false, true);
    } else {
      fetchFirestoreCollection(1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.trim() !== "") {
      fetchFirestoreCollection(1, true, true, true);
    } else {
      fetchFirestoreCollection(1, true, true);
    }
  }, [itemperPage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchValueInput.length !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }
      setErrorMessage("");
    setCurrentPage(1);
    setSearchValue(searchValueInput);
    setLastDoc(null);
  };

  const handleReset = () => {
    setSearchValueInput("");
    setSearchValue("");
    setLastDoc(null);
    setErrorMessage("");
    setCurrentPage(1);
    setItemperPage(20);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    if (searchValue !== "") {
      fetchFirestoreCollection(currentPage + 1, true, false, true);
    } else {
      fetchFirestoreCollection(currentPage + 1, true);
    }
  };
  const handlePrevPage = () => {
    if (searchValue !== "") {
      if (pageStack.length > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
        fetchFirestoreCollection(currentPage - 1, false, false, true);
      }
    } else if (pageStack.length > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      fetchFirestoreCollection(currentPage - 1, false);
    }
  };

  const handleItemPerPage = (id, value) => {
    setItemperPage(value);
    setLastDoc(null);
    setPageStack([]);
    setCurrentPage(1);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white p-4 shadow-md rounded-lg min-h-[85vh] gap-2">
        <span
          onClick={() => navigate(-1)}
          className="bg-blue-100 cursor-pointer hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
        >
          <MdArrowBack className="text-base" /> Back
        </span>
        <div className="flex justify-between items-center flex-wrap">
          <div className="my-2 text-lg font-bold uppercase">
            Activity Tracker
          </div>
          <div className="flex justify-end gap-2">
            <form onSubmit={handleSubmit}>
              <div className="flex justify-end gap-2">
                <Search
                  label="Phone"
                  placeholder="Search by Phone"
                  name="Search"
                  searchCallbackfn={(e) => setSearchValueInput(e.target.value)}
                  isSearching={loading}
                  searchValue={searchValueInput}
                />
                <button
                  type="submit"
                  className="font-semibold py-2 px-4 border hover:border-transparent rounded min-w-[100px] text-sm text-green-500 hover:text-green-600 hover:bg-green-200 border-green-500"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleReset}
                  className="font-semibold py-2 px-4 border hover:border-transparent rounded min-w-[100px] text-sm text-red-500 hover:text-red-600 hover:bg-red-200 border-red-500"
                >
                  Reset
                </button>
              </div>
              {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
              )}
            </form>
          </div>
        </div>
        {itemData.length ? (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3 text-start"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {itemData.map((event, idx) => (
                    <tr className="bg-white border-b" key={idx}>
                      <td className="px-3 py-2 text-start">
                        {event?.scenario_name
                          ? capitalizeWords(event?.scenario_name)
                          : "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.screen_name
                          ? capitalizeWords(event?.screen_name)
                          : "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.values?.category_name || "All"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.values?.topic_name || "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.phone || "Guest"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.datetime
                          ? formatDate(Number(event?.datetime))
                          : "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {event?.platform || "N/A"}
                      </td>
                      <td className="px-3 py-2 text-start">
                        {Object.keys(event.values || {}).length
                          ? Object.entries(event.values)
                              .sort(([keyA], [keyB]) =>
                                keyA.localeCompare(keyB)
                              )
                              .map(([key, value]) => {
                                let displayValue;
                                if (
                                  key === "datetime" ||
                                  key === "createdTime"
                                ) {
                                  displayValue = formatDate(Number(value));
                                } else if (
                                  key === "category_name" &&
                                  (!value ||
                                    value.trim() === "" ||
                                    value === "null")
                                ) {
                                  displayValue = "All";
                                } else {
                                  displayValue =
                                    typeof value === "object"
                                      ? JSON.stringify(value)
                                      : String(value);
                                }
                                return (
                                  <div key={key}>
                                    <strong>{capitalizeWords(key)}: </strong>
                                    {displayValue}
                                  </div>
                                );
                              })
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <PaginationFooter
              currentPage={currentPage}
              totalPages={totalPages}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              handlepaginateValue={handleItemPerPage}
              dropdownOptions={dropdownOptions}
              itemperPage={itemperPage}
              fetchFirestoreCollection={fetchFirestoreCollection}
              searchValue={searchValue}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center items-center pt-36">
            <TbServerOff className="text-[2rem] font-bold m-2" />
            No Data Found
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsActivity;
