import React from "react";
import { Chart } from "react-google-charts";
import { IoClose } from "react-icons/io5";

const ColumnChartQN = ({ title, onChange, apiData }) => {
  console.log(apiData,"apiData")
  const options = {
    hAxis: {
      title: apiData[0][0],
    },
    vAxis: {
      title: apiData[0][1],
    },
    bars: "vertical",
    series: {
      0: { color: "#21ba45" },
    },
    bar: { groupWidth: "75%" },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        auraColor: "none",
        color: "#555",
      },
    },
    legend: {
      position: "top", 
      textStyle: {
        color: "black", 
        fontName: "Arial",
        fontSize: 12, 
        bold: true, 
        // italic: true,
      },
      alignment: "end", 
      maxLines: 2, 
    },
  };

  // Adding annotations data
  // const dataWithAnnotations = apiData.map((row, index) => {
  //   if (index === 0) {
  //     return [...row, { role: "annotation" }];
  //   } else {
  //     return [...row, row[1].toString()];
  //   }
  // });

  return (
    <div className="w-full relative h-fit bg-white">
      <div className="w-full z-50 text-lg text-black underline font-semibold capitalize">
        {title}
        <IoClose
          className="absolute top-0 right-0 text-2xl font-bold cursor-pointer"
          onClick={() => onChange(false)}
        />
      </div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="100%"
        data={apiData}
        options={options}
      />
    </div>
  );
};

export default ColumnChartQN;
