
import React, { useState } from "react";
import Cropper from "react-easy-crop";

export const ImagePreviewModal = ({
  imagesrc,
  setOpenCancelModal,
  croppedImage,
  setCroppedImage,
  handleConfirm,
  handleCancel,
  flage,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    try {
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.src = imagesrc;
      await new Promise((resolve) => {
        image.onload = resolve;
      });
      const ctx = canvas.getContext("2d");
      const canvasWidth = flage === "option" ? 100 : 100;
      const canvasHeight = flage === "option" ? 100 : 50;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        canvasWidth,
        canvasHeight
      );

      const croppedImageUrl = canvas.toDataURL("image/jpeg");
      setCroppedImage(croppedImageUrl);
    } catch (error) {
      console.error("Failed to crop image", error);
    }
  };

  return (
    <div
      className="fixed h-full w-full top-0 right-0 z-50 flex justify-center items-center "
      style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
    >
      <div tabIndex="-1" aria-hidden="true">
        <div className="relative w-[600px] sm:w-[650px] ">
          <div className="relative bg-white rounded-lg shadow-lg">
            <button
              type="button"
              className="absolute top-4 right-4 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-xl w-10 h-10 flex justify-center items-center"
              onClick={() => setOpenCancelModal(false)}
            >
              &times;
            </button>

            <div className="p-6 pt-16 text-center w-full">
              <div className="w-full h-[300px] relative  overflow-hidden  ">
                <Cropper
                  image={imagesrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={flage === "option" ? 400 / 400 : 600 / 300}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>

              {croppedImage && (
                <div className="mt-4">
                  <img
                    src={croppedImage}
                    alt="Cropped"
                    className="max-w-full h-auto"
                  />
                </div>
              )}

              <div className="mt-4 flex justify-center gap-4">
                <button
                  onClick={() => {
                    setOpenCancelModal(false);
                    handleConfirm();
                  }}
                  type="button"
                  data-modal-hide="popup-modal"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Confirm
                </button>
                <button
                  onClick={() => {
                    setOpenCancelModal(false);
                    handleCancel();
                  }}
                  data-modal-hide="popup-modal"
                  type="button"
                  className="mt-0 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
