import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";
import {
  getPopUpMessageDataFailure,
  getPopUpMessageDataSuccess,
} from "../../Actions";

export function* getPopUpMessageDataSaga(action) {
  const token = action.payload;
  const path = `admin/messagePopup`;

  try {
    const response = yield debounce(GetAPI, token?.token, path);
    yield put(getPopUpMessageDataSuccess(response));
  } catch (error) {
    yield put(getPopUpMessageDataFailure(error));
  }
}
