import { useLocation, useNavigate } from "react-router";
import ReadMoreLess from "../ui-elements/ReadMoreLess";
import { MdArrowBack } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Loading from "../loading";
import { TbServerOff } from "react-icons/tb";
import cashQuiz from "../../assets/images/cashQuiz.svg";
import freeQuiz from "../../assets/images/freeQuiz.svg";

const RandomlyData = ({ userQuestions }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [userQuestionsArray, setUserQuestionsArray] = useState([]);

  useEffect(() => {
    if (userQuestions?.loading) {
      setIsLoading(true);
    }
    if (userQuestions?.error) {
      setIsLoading(false);
    }

    if (userQuestions?.userQuestions?.data?.questions) {
      setUserQuestionsArray(userQuestions?.userQuestions?.data?.questions);
      setIsLoading(false);
    }

    if (
      userQuestions?.userQuestions?.statusCode === 404 ||
      userQuestions?.userQuestions?.statusCode === 400
    ) {
      setUserQuestionsArray([]);
      setIsLoading(false);
    }
  }, [userQuestions, userQuestions?.userQuestions?.data?.statusCode]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mt-20 ml-3  bg-white rounded-lg min-h-[55vh] gap-2 top-10">
      <div className="flex justify-between ml-3 ">
        <div className="cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
      </div>
      <div className="m-3 font-bold text-xl">USER QUESTIONS</div>
      {userQuestionsArray?.length ? (
        <>
          <div className="relative overflow-x-auto shadow-lg sm:rounded-lg mt-4 ml-3 mr-1">
            {userQuestionsArray?.map((item, index) => (
              // <div className="bg-white border-b " key={index}>
              //   <div className="flex flex-col sm:flex-row items-center px-3 py-2 sm:py-4 gap-4">
              //     <h5 className="">{`Question Id #${item?.id}`}</h5>
              //     {/* <h5 className="">{`Utilization Score - ${item?.selectCount}`}</h5> */}
              //     <div className="flex self-center bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
              //       <div className="">{item?.level}</div>
              //     </div>
              //   </div>

              //   <div className="flex flex-wrap">
              //     <div>
              //       <td className="px-6 py-2 ">
              //         <ReadMoreLess
              //           descriptionEn={item.descriptionEn}
              //           maxLength={100}
              //         />
              //       </td>
              //       <h6 className="px-6 py-2">A : {item.optionsEn?.A}</h6>
              //       <h6 className="px-6 py-2">B : {item.optionsEn?.B}</h6>
              //       <h6 className="px-6 py-2">C : {item.optionsEn?.C}</h6>
              //       <h6 className="px-6 py-2">D : {item.optionsEn?.D}</h6>{" "}
              //       <h6 className="px-6 py-2 flex gap-2 text-center">
              //         <p className="flex justify-center text-center">
              //           Selected Answer :-
              //         </p>
              //         <p className=" bg-yellow-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
              //           {" "}
              //           {item.selectedAnswer
              //             ? item.selectedAnswer
              //             : "user not answer'd the question"}
              //         </p>
              //       </h6>
              //     </div>
              //     <div>
              //       <td className="px-6 py-2 ">
              //         <ReadMoreLess
              //           descriptionEn={item.descriptionHi}
              //           maxLength={100}
              //         />
              //       </td>
              //       <h6 className="px-6 py-2">A : {item.optionsHi?.A}</h6>
              //       <h6 className="px-6 py-2">B : {item.optionsHi?.B}</h6>
              //       <h6 className="px-6 py-2">C : {item.optionsHi?.C}</h6>
              //       <h6 className="px-6 py-2">D : {item.optionsHi?.D}</h6>{" "}
              //       <h6 className="px-6 py-2 flex gap-2 text-center">
              //         <p className="flex justify-center text-center">
              //           Correct Answer :-
              //         </p>
              //         <p className=" bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
              //           {" "}
              //           {item.correctAnswer}
              //         </p>
              //         {/* {`Correct Answer :- ` + item.correctAnswer} */}
              //       </h6>
              //     </div>
              //   </div>
              // </div>
              <div
                className="bg-white border-b p-4 m-3 border sm:rounded-lg shadow-md mb-4"
                key={index}
              >
                <div className="flex flex-row items-center gap-4">
                  <h5 className="text-lg font-semibold text-gray-800 p-0 m-0">
                    {`Question Id: #${item?.id}`}
                  </h5>

                  <div className="flex items-center justify-center">
                    <img
                      className="w-10 h-10 object-contain"
                      src={item.isFree ? freeQuiz : cashQuiz}
                      alt="Quiz Type"
                    />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row mt-4 gap-4">
                  {/* English Section */}
                  <div className="w-full sm:w-1/2">
                    <div>
                      <div>    
                      {item?.descriptionEn?.fileUrl &&
                        item?.descriptionEn?.fileType && (
                          <div className="w-full mb-3">
                            {item.descriptionEn.fileType.startsWith(
                              "image/"
                            ) && (
                              <img
                                className="w-full object-fit mb-3"
                                src={item.descriptionEn.fileUrl}
                                alt="Description Image"
                              />
                            )}
                            {item.descriptionEn.fileType.startsWith(
                              "video/"
                            ) && (
                              <video
                                className="w-full object-fit mb-3"
                                src={item.descriptionEn.fileUrl}
                                controls
                              />
                            )}
                            {item.descriptionEn.fileType.startsWith(
                              "audio/"
                            ) && (
                              <audio
                                className="w-full object-fit mb-3"
                                src={item.descriptionEn.fileUrl}
                                controls
                              />
                            )}
                            {!["image/", "video/", "audio/"].some((type) =>
                              item.descriptionEn.fileType.startsWith(type)
                            ) && (
                              <p className="text-red-500">
                                Unsupported media format
                              </p>
                            )}
                          </div>
                        )}
                    </div>
                 
                      <h6 className="font-normal">
                        {" "}
                        <span className=" font-semibold">Q.</span>{" "}
                        {item?.descriptionEn?.text}
                      </h6>

                      <div className="h-1/2 flex flex-col justify-start gap-4 mt-4">
                        {item.optionsEn?.A?.imageUrl ||
                        item.optionsEn?.B?.imageUrl ||
                        item.optionsEn?.C?.imageUrl ||
                        item.optionsEn?.D?.imageUrl ? (
                          <div>
                            {/* A and B */}
                            <div className="flex gap-4 mb-4">
                              {["A", "B"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsEn?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsEn?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsEn?.[option]?.text}

                                    {/* below is code for correct and selected answer for Image */}
                                    {item?.selectedAnswer ==
                                      item?.correctAnswer &&
                                    option == item?.correctAnswer ? (
                                      <span className=" text-green-500 font-semibold text-[16px] ml-2">
                                        Right Answer
                                      </span>
                                    ) : (
                                      option == item?.selectedAnswer && (
                                        <span className=" text-red-500 text-[16px] ml-2">
                                          Selected Answer
                                        </span>
                                      )
                                    )}
                                    {option == item?.correctAnswer &&
                                      item?.selectedAnswer != option && (
                                        <span className=" font-semibold text-green-500 text-[16px] ml-2">
                                          Correct Answer
                                        </span>
                                      )}
                                  </h6>
                                </div>
                              ))}
                            </div>
                            {/* C and D */}
                            <div className="flex gap-4">
                              {["C", "D"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsEn?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsEn?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsEn?.[option]?.text}

                                    {/* below adding correct and selected answer code for Image  */}
                                    {item?.selectedAnswer ==
                                      item?.correctAnswer &&
                                    option == item?.correctAnswer ? (
                                      <span className=" text-green-500 font-semibold text-[16px] ml-2">
                                        Right Answer
                                      </span>
                                    ) : (
                                      option == item?.selectedAnswer && (
                                        <span className=" text-red-500 text-[16px] ml-2">
                                          Selected Answer
                                        </span>
                                      )
                                    )}
                                    {option == item?.correctAnswer &&
                                      item?.selectedAnswer != option && (
                                        <span className=" font-semibold text-green-500 text-[16px] ml-2">
                                          Correct Answer
                                        </span>
                                      )}
                                  </h6>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ["A", "B", "C", "D"].map((option) => (
                            <div key={option} className="w-full ">
                              <h6 className=" text-sm font-semibold text-gray-800">
                                <span>{`${option}. `}</span>
                                {item.optionsEn?.[option]?.text}{" "}
                                {item?.selectedAnswer == item?.correctAnswer &&
                                option == item?.correctAnswer ? (
                                  <span className=" text-green-500 font-semibold text-[16px] ml-2">
                                    Right Answer
                                  </span>
                                ) : (
                                  option == item?.selectedAnswer && (
                                    <span className=" text-red-500 text-[16px] ml-2">
                                      Selected Answer
                                    </span>
                                  )
                                )}
                                {option == item?.correctAnswer &&
                                  item?.selectedAnswer != option && (
                                    <span className=" font-semibold text-green-500 text-[16px] ml-2">
                                      Correct Answer
                                    </span>
                                  )}
                              </h6>
                            </div>
                          ))
                        )}
                      </div>
                      {/* <h6 className="mt-2 font-semibold text-black text-sm">
                        {"Selected Ans :- " + item?.selectedAnswer} <br />
                        {"Correct  Ans :- " + item?.correctAnswer}
                      </h6> */}
                    </div>
                  </div>

                  {/* Add Hindi Section similarly */}
                  <div className="w-full sm:w-1/2">
                    <div>
                    <div>   
                     {item?.descriptionHi?.fileUrl &&
                           item?.descriptionHi?.fileType && (
                             <div className="w-full mb-3">
                               {item.descriptionHi.fileType.startsWith(
                                 "image/"
                               ) && (
                                 <img
                                   className="w-full object-fit mb-3"
                                   src={item.descriptionHi.fileUrl}
                                   alt="Description Image"
                                 />
                               )}
                               {item.descriptionHi.fileType.startsWith(
                                 "video/"
                               ) && (
                                 <video
                                   className="w-full object-fit mb-3"
                                   src={item.descriptionHi.fileUrl}
                                   controls
                                 />
                               )}
                               {item.descriptionHi.fileType.startsWith(
                                 "audio/"
                               ) && (
                                 <audio
                                   className="w-full object-fit h-30"
                                   src={item.descriptionHi.fileUrl}
                                   controls
                                 />
                               )}
                               {!["image/", "video/", "audio/"].some((type) =>
                                 item.descriptionHi.fileType.startsWith(type)
                               ) && (
                                 <p className="text-red-500">
                                   Unsupported media format
                                 </p>
                               )}
                             </div>
                           )}
                     </div>
                   <h6 className="font-normal">
                        {" "}
                        <span className=" font-semibold pr-1">Q.</span>
                        {item?.descriptionHi?.text}
                      </h6>

                      <div className="h-1/2 flex flex-col justify-start gap-4 mt-4">
                        {item.optionsHi?.A?.imageUrl ||
                        item.optionsHi?.B?.imageUrl ||
                        item.optionsHi?.C?.imageUrl ||
                        item.optionsHi?.D?.imageUrl ? (
                          <div>
                            {/* A and B */}
                            <div className="flex gap-4 mb-4">
                              {["A", "B"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsHi?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsHi?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsHi?.[option]?.text}
                                  </h6>
                                </div>
                              ))}
                            </div>
                            {/* C and D */}
                            <div className="flex gap-4">
                              {["C", "D"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsHi?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsHi?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsHi?.[option]?.text}
                                  </h6>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ["A", "B", "C", "D"].map((option) => (
                            <div key={option} className="w-full ">
                              <h6 className=" text-sm font-semibold text-gray-800">
                                <span>{`${option}. `}</span>
                                {item.optionsHi?.[option]?.text}{" "}
                                {item?.selectedAnswer == item?.correctAnswer &&
                                option == item?.correctAnswer ? (
                                  <span className=" text-green-500 font-semibold text-[16px] ml-2">
                                    Right Answer
                                  </span>
                                ) : (
                                  option == item?.selectedAnswer && (
                                    <span className=" text-red-500 text-[16px] ml-2">
                                      Selected Answer
                                    </span>
                                  )
                                )}
                                {option == item?.correctAnswer &&
                                  item?.selectedAnswer != option && (
                                    <span className=" font-semibold text-green-500 text-[16px] ml-2">
                                      Correct Answer
                                    </span>
                                  )}
                              </h6>
                            </div>
                          ))
                        )}
                      </div>
                      {/* <h6 className="mt-2 text-black text-sm font-semibold">
                        {"Ans:- " +
                          item?.answerHi?.option +
                          ") " +
                          item?.answerHi?.answer}
                      </h6> */}
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-wrap gap-4">
                  <div className="px-3 py-2 bg-blue-500 text-white rounded-md">
                    <span>{item.category?.nameEng}</span>
                  </div>

                  <div className="px-3 py-2 bg-yellow-500 text-white rounded-md">
                    <span>{item.topic?.nameEng}</span>
                  </div>
                </div> */}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center flex flex-col justify-center items-center pt-36">
          <TbServerOff className="text-[2rem] font-bold  m-2" />
          Questions Not Found
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userQuestions: state?.leaderboardUserQuestionReducer,
  };
};

export default connect(mapStateToProps)(RandomlyData);
