import React, { useEffect, useState } from "react";
import CheckboxGroup from "./selectOption";
import { useDebouncedInput } from "../../customHook";
import icon from ".";
import { toast } from "react-toastify";
import { ImagePreviewModal } from "../../components/modal/ImagePreviewModel";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function QuestionForm(props) {
  const {
    register,
    setValue,
    error,
    selectedOption,
    setSelectedOption,
    watch,
    setFlag,
    flag,
    isImage,
    setIsImage,
    item,
  } = props;
  const options = [
    {
      option: "A",
      prevImage: item?.optionsEn?.A?.imageUrl,
    },
    {
      option: "B",
      prevImage: item?.optionsEn?.B?.imageUrl,
    },
    {
      option: "C",
      prevImage: item?.optionsEn?.C?.imageUrl,
    },
    {
      option: "D",
      prevImage: item?.optionsEn?.D?.imageUrl,
    },
  ];

  const [inputValueEn, setInputValueEn] = useState(() => ({
    A: item?.optionsEn?.A?.text || "",
    B: item?.optionsEn?.B?.text || "",
    C: item?.optionsEn?.C?.text || "",
    D: item?.optionsEn?.D?.text || "",
  }));

  const [inputValueHi, setInputValueHi] = useState(() => ({
    A: item?.optionsHi?.A?.text || "",
    B: item?.optionsHi?.B?.text || "",
    C: item?.optionsHi?.C?.text || "",
    D: item?.optionsHi?.D?.text || "",
  }));
  const [descriptionEnValue, setDescriptionEnValue] = useState(
    item?.descriptionEn?.text || ""
  );
  const [descriptionHiValue, setDescriptionHiValue] = useState(
    item?.descriptionHi?.text || ""
  );

  const callbackFunction = (value) => {
    setTimeout(() => {
      fetch(
        `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURIComponent(
          value
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data[0] && data[0][0] && data[0][0][0]) {
            setValue("descriptionHi", data[0][0][0]);
            setDescriptionHiValue(data[0][0][0]);
          }
        })
        .catch((err) => console.error(err));
    }, 300);
    setValue("descriptionHi", "");
  };
  const [fileName, setFileName] = useState("");
  const [fileName1, setFileName1] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imagesrc, setImagesrc] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [media, setMedia] = useState(null);

  const handleImageChangeEn = (e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const baseName = name.replace(`.${extension}`, "");
      const shortenedName =
        baseName.length > 10 ? baseName.slice(0, 10) + "..." : baseName;
      setFileName(shortenedName + "." + extension);
      const fileType = file.type.split("/")[0];
      const fileSize = file.size / (1024 * 1024);
      if (fileType === "image") {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          setImageLoaded(true);
          setImagesrc(img.src);
          setMedia("image");
        };
      } else if (fileType === "video") {
        if (fileSize > 5) {
          toast.error("Video size must be less than 5 MB");
          setFileName("");
          return;
        }
        setIsImage(false);
        const videoUrl = URL.createObjectURL(file);
        const video = document.createElement("video");
        video.src = videoUrl;

        video.onloadedmetadata = () => {
          // const aspectRatio = video.videoWidth / video.videoHeight;
          // const expectedRatio = 16 / 9;
          // const tolerance = 0.01; // Allow slight deviation

          // if (Math.abs(aspectRatio - expectedRatio) > tolerance) {
          //   toast.error("Video must have a 16:9 aspect ratio");
          //   setFileName("");
          //   return;
          // }
          // if (video.videoWidth !== 640 || video.videoHeight !== 360) {
          //   toast.error("Video dimensions must be 640x360 pixels");
          //   setFileName("");
          //   return;
          // }
          if (video.duration > 30) {
            toast.error("Video duration must be less than 30 seconds");
            setFileName("");
            return;
          }
          setMedia("video");
          setImagesrc(videoUrl);
          setImageLoaded(false);
        };
      } else if (fileType === "audio") {
        if (fileSize > 3) {
          toast.error("Audio size must be less than 3 MB");
          setFileName("");
          return;
        }

        setIsImage(false);
        const audioUrl = URL.createObjectURL(file);
        const audio = new Audio(audioUrl);

        audio.onloadedmetadata = () => {
          if (audio.duration > 15) {
            toast.error("Audio duration must be less than 15 seconds");
            setFileName("");
            return;
          }
          setImagesrc(audioUrl);
          setMedia("audio");
          setImageLoaded(false);
        };
      }
    }
  };

  useEffect(() => {
    if (isImage && (media === "video" || media === "audio")) {
      setIsImage(false);
      toast.info("you cannot select image with video or audio");
    }
  }, [isImage, media]);

  const handleImageCancel = () => {
    setImagesrc("");
    setCroppedImage("");
    setFileName("");
  };
  const handleImageConfirm = () => {
    setImagesrc(croppedImage);
  };

  const handleImageChangeHi = (e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const shortenedName = name.length > 10 ? name.slice(0, 10) + "..." : name;
      setFileName1(shortenedName + "." + extension);
    }
  };
  // const [, setInputValue] = useDebouncedInput(callbackFunction, 1000);
  const onChangeEn = (e) => {
    const newValue = e.target.value;
    setDescriptionEnValue(newValue);
    setValue("descriptionEn", newValue);
    callbackFunction(newValue);
  };
  const onChangeHi = (e) => {
    setDescriptionHiValue(e.target.value);
  };

  const handleCheckboxChange = () => {
    setIsImage(!isImage);
  };
  return (
    <>
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 gap-8">
          <div className="space-y-6">
            {/* Question Description in English */}
            <div className="flex flex-col sm:flex-row items-center gap-4">
              <div className="w-full">
             <label className="block text-sm font-medium text-gray-700 mb-2">
            <span className="block lg:hidden">Que Des in English</span>
            <span className="hidden lg:block">Question Description in English</span>
          </label>
                <textarea
                  rows="2"
                  name="descriptionEn"
                  {...register("descriptionEn")}
                  onChange={onChangeEn}
                  value={descriptionEnValue}
                  required
                  className="w-full border border-gray-50 rounded-lg p-1 text-sm bg-gray-50"
                ></textarea>
              </div>
              <div className=" relative">
                <label className="block text-sm font-medium text-gray-700">
                  Upload File
                </label>
                <label className="block border border-gray-50 rounded-lg w-max p-2.5 bg-gray-50 cursor-pointer hover:bg-gray-100">
                  <input
                    type="file"
                    className="hidden"
                    id="imageInput"
                    accept="image/*, video/*, audio/*"
                    onChange={handleImageChangeEn}
                  />

                  <span className="text-center text-gray-500 w-full flex gap-1 text-sm">
                    <img src={icon} className="w-6 h-6 p-1" alt="icon" />
                    <span className="truncate">
                      {fileName
                        ? fileName
                        : item?.descriptionEn?.fileUrl
                        ? (() => {
                            // Extract the filename and remove any query parameters
                            const fullFileName = item.descriptionEn.fileUrl
                              .split("/o/")[1]
                              ?.split("?")[0];
                            const nameParts = fullFileName.split(".");
                            const baseName = nameParts[0];
                            const extension = nameParts[nameParts.length - 1];

                            // Truncate the base name and add the extension
                            return baseName.length > 5
                              ? `${baseName.slice(0, 5)}...${extension}`
                              : fullFileName;
                          })()
                        : "Choose a file"}
                    </span>
                  </span>
                </label>
                {!fileName && (
                  <span className=" text-[0.710rem] font-semibold text-blue-400 absolute">
                    * Media Text file should be 600x300 *
                  </span>
                )}
              </div>
            </div>

            {/* Is Image Check box  */}
            <div>
              <label htmlFor="isImage" className="flex items-center gap-2 pr-4 pt-3">
                <input
                  type="checkbox"
                  id="isImage"
                  className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  checked={isImage} // Bind state to checkbox
                  onChange={handleCheckboxChange} // Update state when clicked
                />

                <span className="text-sm font-bold">Is Image</span>
                <TooltipATM
                  dataTooltipId={"question-form-tooltip"}
                  dataTooltipPlace={"right"}
                  dataTooltipHtml="Rules for Image Questions:<br/>
1. Adding only an image to the question and no images in the options is allowed.  <br/>
2. Adding only images to the options, with no image for the question, is allowed.<br/>
3. Adding images to both the question and options is allowed.  <br/>
4. If images are provided only for the English version of the question or options, the same images will be used for Hindi. (If you want dedicated images for Hindi, upload them separately.) <br/>
5. If any option has an image, all options must have images. It is not possible to add an image to only 1 or 2 options."
                  className="cursor-pointer"
                />
              </label>
            </div>

            {/* Select Answer (English) */}
            <div>
              <div className="flex items-center gap-1">
                {/* Left-aligned label */}
                <label className="text-sm font-medium text-gray-700">
                  Select Answer 
                </label>
                {isImage && (
                  <span className=" text-[0.710rem] font-semibold text-blue-400 ">
                    * image size should be 400x400 *
                  </span>
                )}
              </div>
              <CheckboxGroup
                register={register}
                watch={watch}
                options={options}
                placholder={"Option"}
                Ans={"answerEn.option"}
                name={"optionsEn"}
                setValue={setValue}
                error={error}
                isImage={isImage}
                inputValueHi={inputValueHi}
                inputValueEn={inputValueEn}
                setInputValueEn={setInputValueEn}
                setInputValueHi={setInputValueHi}
              />
            </div>
          </div>
          {/* Right Section - Hindi */}
          <div className="space-y-6">
            {/* प्रश्न विवरण हिंदी में */}
            <div className="flex  flex-col sm:flex-row items-center gap-4">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  प्रश्न विवरण हिंदी में
                </label>
                <textarea
                  rows="2"
                  name="descriptionHi"
                  {...register("descriptionHi")}
                  onChange={onChangeHi}
                  required
                  value={descriptionHiValue}
                  className="w-full border border-gray-50 rounded-lg p-1 text-sm bg-gray-50"
                ></textarea>
              </div>
              <div className=" relative">
                <label className="block text-sm font-medium text-gray-700">
                  तस्वीर अपलोड करें
                </label>
                <label className="block border border-gray-50 rounded-lg w-max p-2.5 bg-gray-50 cursor-pointer hover:bg-gray-100">
                  <input
                    type="file"
                    className="hidden"
                    id="imageInput"
                    onChange={handleImageChangeHi}
                  />
                  <span className="text-center text-gray-500 w-full flex gap-1 text-sm">
                    <img src={icon} className="w-6 h-6 p-1" alt="icon" />
                    <span className="truncate">
                      {/* {fileName1 ? fileName1 : "Choose a file"} */}
                      {fileName
                        ? fileName
                        : item?.descriptionEn?.fileUrl
                        ? (() => {
                            // Extract the filename and remove any query parameters
                            const fullFileName = item.descriptionEn.fileUrl
                              .split("/o/")[1]
                              ?.split("?")[0];
                            const nameParts = fullFileName.split(".");
                            const baseName = nameParts[0];
                            const extension = nameParts[nameParts.length - 1];

                            // Truncate the base name and add the extension
                            return baseName.length > 5
                              ? `${baseName.slice(0, 5)}...${extension}`
                              : fullFileName;
                          })()
                        : "Choose a file"}
                    </span>
                  </span>
                </label>
                {!fileName && (
                  <span className=" text-[0.710rem] font-semibold text-blue-400 absolute">
                    * Media Text file should be 600x300 *
                  </span>
                )}
              </div>
            </div>
            <br />
            <br />
            {/* Select Answer (Hindi) */}
            {!isImage && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mt-2 pt-2 sm:auto">
                  उत्तर चुनें 
                </label>
                <CheckboxGroup
                  register={register}
                  watch={watch}
                  options={options}
                  placholder={"विकल्प"}
                  Ans={"answerHi.option"}
                  name={"optionsHi"}
                  setValue={setValue}
                  error={error}
                  isImage={isImage}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  inputValueHi={inputValueHi}
                  inputValueEn={inputValueEn}
                  setInputValueEn={setInputValueEn}
                  setInputValueHi={setInputValueHi}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {media && media?.type?.startsWith("image") && imageLoaded && ( */}
      {media === "image" && imageLoaded && imagesrc && (
        <ImagePreviewModal
          imagesrc={imagesrc}
          handleCancel={handleImageCancel}
          handleConfirm={handleImageConfirm}
          setCroppedImage={setCroppedImage}
          croppedImage={croppedImage}
          setOpenCancelModal={setImageLoaded}
        />
      )}
      <ReactTooltip
        id="question-form-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </>
  );
}
