import {
  UPDATE_POPUPMESSAGE_DATA_REQUEST,
  UPDATE_POPUPMESSAGE_DATA_SUCCESS,
  UPDATE_POPUPMESSAGE_DATA_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  updatePopUpMessageData: {},
  loading: false,
  error: null,
};

const updatePopUpMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_POPUPMESSAGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_POPUPMESSAGE_DATA_SUCCESS:
      return {
        ...state,
         updatePopUpMessageData: action.payload,
        loading: false,
        error: null,
      };

    case UPDATE_POPUPMESSAGE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updatePopUpMessageReducer;
