import React, { useEffect, useRef, useState } from "react";
import { SECRET_KEY } from "../Utils/Constants";
import CryptoJS from "crypto-js";
import { connect, useDispatch } from "react-redux";
import { Search } from "../components/Search";
import { FaPlus } from "react-icons/fa";
import {
  MdDelete,
  MdModeEditOutline,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import {
  ActiveCategoryRequest,
  AddCategoriesRequest,
  CategoriesRequest,
  DeleteCategoryRequest,
  SearchCategoriesRequest,
  UpdateCategoryRequest,
} from "../Actions";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DeleteModal } from "../components/modal/DeleteModal";
import { AddNewModal } from "../components/modal/AddNewModal";
import { Pagination } from "../components/Pagination";
import useResetCategoryReducers from "../components/reduxHelper/resetReducers/useResetCategoryReducers";
import useCategoryAlerts from "../components/alertHelper/useCategoryAlerts";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../components/constant";
import { ConfirmationModal } from "../components/modal/ConfirmationModal";
import Loading from "../components/loading";
import { TbServerOff } from "react-icons/tb";
import { checkAccess } from "../Utils/RoleBasedRoute";
import TooltipATM from "../components/ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipWrapper from "../components/ui-elements/TooltipWrapper";
import { renderToStaticMarkup } from "react-dom/server";

const Category = (props) => {
  const {
    ActiveCategory,
    AllCategories,
    SearchCategories,
    deleteCategory,
    newCategory,
    updateCategory,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryArr, setCategroyArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openEnableDisableModal, setOpenEnableDisableModal] = useState(false);
  const [operation, setOperation] = useState("");
  const [QuizData, setQuizData] = useState();
  const [openCategoryAddModal, setOpenCategoryAddModal] = useState(false);
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [editCategoryData, setEditCategoryData] = useState({});
  const [deleteCategoryData, setDeleteCategoryData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const timerRef = useRef(null);
  const debounceTimeout = useRef(null);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  useEffect(() => {
    const body = {
      token: token,
      pageNo: currentPage,
    };
    dispatch(CategoriesRequest(body));
  }, [
    currentPage,
    deleteCategory,
    newCategory,
    updateCategory,
    ActiveCategory,
  ]);

  useEffect(() => {
    alertHook.showAlert(newCategory, updateCategory, deleteCategory);
  }, [deleteCategory, newCategory, updateCategory]);

  const alertHook = useCategoryAlerts();
  const resetHook = useResetCategoryReducers();

  useEffect(() => {
    if (searchValue) searchByCategoryName(null, searchValue);
  }, [updateCategory, ActiveCategory]);

  useEffect(() => {
    if (AllCategories.loading || SearchCategories.loading) {
      setIsLoading(true);
      setError(false);
      setCategroyArr([]);
    }
    if (AllCategories.error || SearchCategories.error) {
      setIsLoading(false);
      setError(true);
      setCategroyArr([]);
    }
    if (AllCategories?.AllCategories?.categories) {
      setCategroyArr(AllCategories?.AllCategories?.categories);
      setIsLoading(false);
      setError(false);
    }
    if (searchValue && SearchCategories?.SearchCategories.data !== undefined) {
      setCategroyArr(SearchCategories?.SearchCategories.data);
    }
    if (AllCategories?.AllCategories?.totalPages !== undefined) {
      setTotalPages(AllCategories?.AllCategories.totalPages);
    }
  }, [AllCategories, SearchCategories]);

  const handleActive = (item, boolean) => {
    setOpenEnableDisableModal(true);
    setOperation(`${boolean ? "Enable" : "Disable"}`);
    setQuizData({ item, boolean });
  };

  const handleEnableDisable = () => {
    const body = {
      token: token,
      categoryId: QuizData?.item?.id,
      enableCategory: QuizData?.boolean,
    };
    dispatch(ActiveCategoryRequest(body));
    setOperation("");
    setQuizData(null);
  };

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();

    return () => {
      resetHook.reset();
    };
  }, []);

  const searchByCategoryName = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event.target.value);
    }
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(
        SearchCategoriesRequest({
          data: searchParam || event.target.value,
          token,
        })
      );
    }, 200);
  };

  const handleAddCategory = (
    categoryImage,
    categoryName,
    categoryNameHindi
  ) => {
    const body = {
      token: token,
      nameEng: categoryName,
      nameHin: categoryNameHindi,
      image: categoryImage,
    };
    dispatch(AddCategoriesRequest(body));
  };

  const handleEditCategory = (
    categoryImage,
    categoryName,
    categoryHindiName,
    categoryId
  ) => {
    const body = {
      categoryId: categoryId,
      token: token,
      nameEng: categoryName,
      nameHin: categoryHindiName,
      image: categoryImage,
    };
    dispatch(UpdateCategoryRequest(body));
  };

  const handleDeleteCategory = (id) => {
    const body = {
      token: token,
      categoryId: id,
    };
    dispatch(DeleteCategoryRequest(body));
  };

  if (isLoading || deleteCategory.loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] relative ">
          <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
            <div className="flex justify-start gap-1 items-center">
              <div className="font-bold align-top uppercase text-lg text-center">
                Categories
              </div>
              <TooltipATM
                dataTooltipId={"category-tooltip"}
                dataTooltipPlace={"right"}
                dataTooltipHtml={"Lists all the categories."}
                className={" cursor-pointer"}
              />
            </div>
            <div className="flex justify-between items-stretch gap-2 ">
              <Search
                label={`Category`}
                placeholder={"Search By Name or ID"}
                name={"Search"}
                searchCallbackfn={searchByCategoryName}
                isSearching={SearchCategories.loading}
              />
              <button
                onClick={() => setOpenCategoryAddModal(true)}
                disabled={!checkAccess("CATEGORY", "C")}
                className={`bg-transparent  font-semibold text-xs md:text-base hover:text-slate-700 hover:bg-[#4CAF50] text-[#4CAF50] py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("CATEGORY", "C") ? "accessDenied" : ""
                }`}
              >
                Add Category
                <FaPlus />
              </button>
            </div>
          </div>

          {categoryArr?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-700">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      {tableHeaders.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center"
                        >
                          <div className=" flex gap-1 items-center justify-center">
                            {item.name}
                            {item?.msg && (
                              <TooltipATM
                                dataTooltipId={"category-tooltip"}
                                dataTooltipPlace={"top-end"}
                                dataTooltipHtml={item?.msg}
                                className={"cursor-pointer"}
                              />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {categoryArr?.map((item, index) => (
                      <tr className="bg-white border-b" key={index}>
                        <td className="px-6 py-2 text-center">{item?.id}</td>
                        <td className="px-6 py-2 pt-4 text-center flex flex-col">
                          <span>{item?.nameEng}</span>
                          <span>{item?.nameHin || "hindi"}</span>
                        </td>
                        <td className="px-6 py-2 text-center">
                          {item?.enabled ? (
                            <button
                              onClick={() => {
                                handleActive(item, false);
                              }}
                              type="button"
                              disabled={!checkAccess("CATEGORY", "U")}
                              className={`inline-flex items-center px-5 border-green-200 border-2 py-2.5 text-sm font-medium text-center  rounded-lg  focus:ring-4 focus:outline-none focus:ring-green-100 ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "text-green-700 hover:bg-green-50"
                              }`}
                            >
                              Enabled
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                handleActive(item, true);
                              }}
                              type="button"
                              disabled={!checkAccess("CATEGORY", "U")}
                              className={`inline-flex border-red-200 border-2 items-center px-5 py-2.5 text-sm font-medium text-center  rounded-lg  focus:ring-4 focus:outline-none focus:ring-red-100 ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "text-red-700 hover:bg-red-50"
                              }`}
                            >
                              Disabled
                            </button>
                          )}
                        </td>
                        <td className="px-6 py-2 text-center">
                          <button
                            onClick={() => {
                              navigate({
                                pathname: "/topics",
                                search: createSearchParams({
                                  categoryName: item?.nameEng,
                                  categoryId: item?.id,
                                }).toString(),
                              });
                            }}
                            type="button"
                            disabled={!checkAccess("TOPIC", "R")}
                            className={`inline-flex items-center px-4 py-2.5 text-sm font-medium text-blue-700 text-center rounded-lg hover:bg-blue-50 focus:ring-4 focus:outline-none focus:ring-blue-100 ${
                              !checkAccess("TOPIC", "R") ? "accessDenied" : ""
                            }`}
                          >
                            <span className="hidden md:block">
                              View the Topics{" "}
                            </span>
                            <span className="block md:hidden">Topics </span>
                            <MdOutlineKeyboardDoubleArrowRight />
                          </button>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div className="flex gap-x-3 justify-center">
                            <div
                              onClick={() => {
                                if (checkAccess("CATEGORY", "U")) {
                                  setEditCategoryData({
                                    id: item?.id,
                                    name: item?.nameEng,
                                    nameHin: item?.nameHin,
                                  });
                                  setOpenEditCategoryModal(true);
                                }
                              }}
                              className={`hover:bg-gray-100  p-1 rounded-full ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              <MdModeEditOutline className="text-2xl" />
                            </div>
                            <div
                              onClick={() => {
                                if (checkAccess("CATEGORY", "U")) {
                                  setDeleteCategoryData({
                                    id: item?.id,
                                    name: item?.nameEng,
                                  });
                                  setSearchValue("");
                                  setOpenDeleteCategoryModal(true);
                                }
                              }}
                              className={`hover:bg-gray-100  p-1 rounded-full ${
                                !checkAccess("CATEGORY", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                            >
                              <TooltipWrapper
                                id="category-wrapper-tooltip"
                                content="If deleted, all the associated topics and their quizzes will <br/> also be deleted. This is an irreversible change."
                              >
                                <MdDelete className="text-2xl hover:cursor-pointer" />
                              </TooltipWrapper>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {totalPages > 1 && (
                <div className="flex justify-end pr-5 pt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={AllCategories.loading}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Categories Not Found
            </div>
          )}
        </div>
      </div>

      {openCategoryAddModal && (
        <AddNewModal
          heading={"Add Category"}
          nameLabel={"Name of Category"}
          hindiLabel="श्रेणी का नाम"
          submitLabel={"Add New Category"}
          submitLabelsm={"Add Category"}
          handleSubmit={handleAddCategory}
          setOpenModal={setOpenCategoryAddModal}
          categoryId={AllCategories.AllCategories.categories}
        />
      )}

      {openEditCategoryModal && (
        <AddNewModal
          heading={"Edit Category"}
          nameLabel={"Name of Category"}
          hindiLabel="श्रेणी का नाम"
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={handleEditCategory}
          openModal={openCategoryAddModal}
          setOpenModal={setOpenEditCategoryModal}
          data={editCategoryData}
          flage={"editCategory"}
        />
      )}

      {openDeleteCategoryModal && (
        <DeleteModal
          data={deleteCategoryData}
          handleDelete={handleDeleteCategory}
          setOpenDeleteModal={setOpenDeleteCategoryModal}
        />
      )}
      {openEnableDisableModal && (
        <ConfirmationModal
          operation={operation}
          data={QuizData?.item?.nameEng}
          handleCancel={handleEnableDisable}
          setOpenCancelModal={setOpenEnableDisableModal}
          source={"Category"}
          message={
            "If you disable a category then all its topics & quizzes will be disabled, are you sure you want to continue ?"
          }
        />
      )}

      <ReactTooltip
        id="category-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </>
  );
};

const tableHeaders = [
  { name: "Id" },
  {
    name: "Category",
  },
  {
    name: "Status",
    msg: renderToStaticMarkup(
      <p>
        If disabled, all the associated topics and their quizzes will also be
        disabled. Similarly, if enabled, all associated topics and their quizzes
        will be enabled.
      </p>
    ),
  },
  {
    name: "Topics",
  },
  {
    name: "Actions",
  },
];

const mapStateToProps = (state) => {
  return {
    logInData: state?.LogInReducer?.user,
    AllCategories: state?.AllCategoriesReducer,
    SearchCategories: state?.SearchCategoriesReducer,
    deleteCategory: state?.DeleteCategoryReducer,
    newCategory: state?.AddCategoryReducer,
    updateCategory: state?.UpdateCategoryReducer,
    ActiveCategory: state?.ActiveCategoryReducer,
  };
};

const mapDispatchToProps = () => {
  return {
    // loginRequest, // Map the loginRequest action creator to props
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
