import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { getPopUpMessageDataRequest, updatePopUpMessageRequest } from "../../Actions";
import Loading from "../../components/loading";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { renderToStaticMarkup } from "react-dom/server";

const tableHeaders = [
  { name: "Id" },
  {
    name: "Active",
    msg: renderToStaticMarkup(
      <p>
        If disabled, the Sound will not be generated for the specific scenario.
        To stop providing Sounds, disable the scenario instead of setting the
        minimum and maximum amount to 0.
      </p>
    ),
  },
];

const PopUpsManagement = ({ popUpMessageData, updatePopUpMessageData }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [soundDataList, setSoundDataList] = useState([]);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const getSoundData = () => {
    const body = {
      token: token,
    };
    dispatch(getPopUpMessageDataRequest(body));
  };

  useEffect(() => {
    getSoundData();
  }, [updatePopUpMessageData]);

  useEffect(() => {
    if (popUpMessageData?.loading) {
      setIsLoading(true);
    }

    if (popUpMessageData?.error) {
      setIsLoading(false);
    }

    if (popUpMessageData?.popUpMessageData?.data) {
      setSoundDataList(popUpMessageData?.popUpMessageData?.data);
      setIsLoading(false)
    }
  }, [popUpMessageData]);

  const handleActiveToggleChange = () => {
    const body = {
      data: {
        id: soundDataList.id,
        isEnabled: !soundDataList?.isEnabled,
      },
      token: token,
    };

    dispatch(updatePopUpMessageRequest(body));
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4 rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 md:gap-0">
            <div className="flex justify-start gap-1 items-center">
              <div className="font-bold uppercase text-lg text-center">
                Pop Ups Management
              </div>
              <TooltipATM
                dataTooltipId={"Sound-tooltip"}
                dataTooltipPlace={"right"}
                dataTooltipHtml={
                  "Lists all the scenarios where users will receive Sounds. New scenarios can only be added from the backend, not from the admin panel."
                }
                className={"cursor-pointer"}
              />
            </div>
          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
            <table className="w-full text-sm text-left text-gray-700">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  {tableHeaders?.map((item, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-4 py-3 text-center whitespace-nowrap"
                    >
                      <div className="flex gap-1 items-center justify-center">
                        {item.name}
                        {item?.msg && (
                          <TooltipATM
                            dataTooltipId={"Sound-tooltip"}
                            dataTooltipPlace={"top-end"}
                            dataTooltipHtml={item?.msg}
                            className={"cursor-pointer"}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b">
                      <td className="px-4 py-3 text-center">
                        <div>
                          <div>{soundDataList.id}</div>
                        </div>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <div className="">
                          <div onClick={() => handleActiveToggleChange()}>
                            {soundDataList?.isEnabled ? (
                              <span className="cursor-pointer border-2 rounded-lg px-4 py-2 border-green-300 text-green-500 hover:bg-green-50">
                                Enabled
                              </span>
                            ) : (
                              <span className="cursor-pointer border-2 px-4 py-2 rounded-lg border-red-300 hover:bg-red-50 text-red-500">
                                Disabled
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ReactTooltip
        id="Sound-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    popUpMessageData: state.getPopUpMessageDataReducer,
    updatePopUpMessageData: state?.updatePopUpMessageReducer,
  };
};

export default connect(mapStateToProps)(PopUpsManagement);