import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import {
  getBannerDataRequest,
  getQuizSpotPercentageRequest,
  updateBannerRequest,
  updateQuizSpotPercentageRequest,
} from "../../Actions";

import Loading from "../../components/loading";
import { useNavigate } from "react-router";
import Switch from "react-switch";
import { Button } from "../../components/ui-elements/Button";
import { MdDelete } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { renderToStaticMarkup } from "react-dom/server";

const BannerSetting = ({ bannerData, updateQuizSpotRate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bannerDataList, setBannerData] = useState({});
  const [bannerText, setBannerText] = useState("");
  const [bannerTextHindi, setBannerTextHindi] = useState("");
  const [isToggleTrue, setIsToggleTrue] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const onSubmit = () => {
    const body = {
      data: {
        id: 1,
        textEng: bannerText == "" ? " " : bannerText,
        textHin: bannerTextHindi == "" ? " " : bannerTextHindi,
        isEnabled: isToggleTrue,
      },
      token: token,
    };
    dispatch(updateBannerRequest(body));
  };

  const getBannerData = () => {
    const body = {
      token: token,
    };
    dispatch(getBannerDataRequest(body));
  };

  useEffect(() => {
    getBannerData();
  }, []);

  useEffect(() => {
    if (bannerData?.loading) {
      setIsLoading(true);
    }

    if (bannerData?.error) {
      setIsLoading(false);
    }

    if (bannerData?.bannerData?.data) {
      setBannerData(bannerData?.bannerData?.data);
      // console.log(bannerData?.bannerData?.data, "bannerData");
      setBannerText(
        bannerData?.bannerData?.data?.textEng == " "
          ? ""
          : bannerData?.bannerData?.data?.textEng
      );
      setBannerTextHindi(
        bannerData?.bannerData?.data?.textHin == " "
          ? ""
          : bannerData?.bannerData?.data?.textHin
      );

      setIsToggleTrue(bannerData?.bannerData?.data?.isEnabled);
      setIsLoading(false);
    }

    // eslint-disable-next-line
  }, [bannerData]);

  const handleToggleChange = (checked) => {
    setIsToggleTrue(checked);
    setChangesMade(!changesMade);
  };

  const handleTextEdit = (event) => {
    let text = event.target.value;
    setBannerText(text);

    fetch(
      `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURIComponent(
        text
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data[0] && data[0][0] && data[0][0][0]) {
          setBannerTextHindi(data[0][0][0]);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleHindiTextEdit = (text) => {
    setBannerTextHindi(text);
  };

  const handleKeyDown = (event) => {
    if (event.key == "Enter") {
      onSubmit();
    }
  };

  const handleDelete = () => {
    const body = {
      data: {
        // requiredSpotPercent: item,
        id: 0,
        textEng: " ",
        textHin: " ",
        isEnabled: false,
      },
      token: token,
    };
    setBannerText("");
    setBannerTextHindi("");
    setIsToggleTrue(false);
    dispatch(updateBannerRequest(body));
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4  rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="flex justify-start gap-1 items-center">
              <div className="font-bold uppercase text-lg text-center">
                Banner Setting
              </div>
              <TooltipATM
                dataTooltipId={"manageAccess-tooltip"}
                dataTooltipPlace={"right"}
                dataTooltipHtml={renderToStaticMarkup(
                  <p>
                    If enabled, this text is displayed at the top of the topic
                    listing each time the user opens the QuizNation app.
                  </p>
                )}
                className={" cursor-pointer"}
              />
            </div>

            <div className="px-1 sm:px-4 font-semibold flex justify-center gap-3">
              <span>
                <Switch
                  defaultChecked={bannerDataList?.isEnabled}
                  checked={isToggleTrue}
                  onChange={handleToggleChange}
                />
              </span>
              <span>Active</span>
            </div>
          </div>
          <>
            <div className="relative border overflow-x-auto shadow-md sm:rounded-lg mt-3 py-4 px-4 flex flex-col gap-1">
              <div className=" grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div>
                  <h6 className=" font-semibold">Banner Text </h6>
                  <div className=" w-full">
                    <span className=" col-span-1">
                      <input
                        className=" rounded-lg w-full outline-none border"
                        type="text"
                        placeholder="Enter Banner Text Here"
                        value={bannerText}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => handleTextEdit(e)}
                      />
                    </span>
                  </div>
                </div>
                <div>
                  <h6 className=" font-semibold">Banner Text in Hindi</h6>
                  <div className="  w-full">
                    <span className=" col-span-1">
                      <input
                        className=" rounded-lg w-full outline-none border"
                        type="text"
                        placeholder="Enter Banner Text Here"
                        value={bannerTextHindi}
                        onChange={(e) => handleHindiTextEdit(e.target.value)}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className=" flex justify-end gap-3 mt-4">
                <button
                  onClick={onSubmit}
                  className={` ${
                    changesMade ? " border-2" : "border"
                  } flex items-center justify-center gap-2 py-1.5 bg-white text-[#4CAF50] border-[#4CAF50] hover:text-black font-semibold rounded-lg px-2 sm:px-3 lg:px-4 w-1/2 sm:w-[16%]`}
                >
                  <IoIosSend size={"22px"} />
                  Publish
                </button>
                <button
                  onClick={handleDelete}
                  className=" flex items-center gap-2 hover:bg-red-200 hover:text-black justify-center py-1.5 border-2 border-red-500 font-semibold text-red-500 rounded-lg px-2 sm:px-3 lg:px-4 w-1/2 sm:w-[16%]"
                >
                  <MdDelete size={"20px"} />
                  Delete
                </button>
              </div>
            </div>
          </>
        </div>

        <ReactTooltip
          id="manageAccess-tooltip"
          className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
         />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerData: state?.getBannerDataReducer,
    // updateQuizSpotRate: state?.updateQuizSpotPercentageReducer,
  };
};

export default connect(mapStateToProps)(BannerSetting);
