import {
  UPDATE_WITHDRAWLIMIT_FAILURE,
  UPDATE_WITHDRAWLIMIT_REQUEST,
  UPDATE_WITHDRAWLIMIT_SUCCESS,
} from "../../Utils/Constants";

export const updateWithdrwaLimitRequest = (payload) => {
  return {
    type: UPDATE_WITHDRAWLIMIT_REQUEST,
    payload: payload,
  };
};

export const updateWithdrwaLimitSuccess = (data) => {
  return {
    type: UPDATE_WITHDRAWLIMIT_SUCCESS,
    payload: data,
  };
};

export const updateWithdrwaLimitFailure = (error) => {
  return {
    type: UPDATE_WITHDRAWLIMIT_FAILURE,
    payload: error,
  };
};
