// src/redux/sagas.js

import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { putPasswordRequestAPI } from "../../components/Config/Apiinterface";
import { updateUserPasswordFailure, updateUserPasswordSuccess } from "../../Actions/User/updateUserAction";

export function* updateUserPasswordSaga(action) {
  const path = "admin/player/changePassword";

  const token = action.payload.token;
  const body = action.payload;

  try {
    const response = yield call(putPasswordRequestAPI, token, body, path);
    yield put(updateUserPasswordSuccess(response));
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    alert("Something went wrong");
    yield put(updateUserPasswordFailure(error));
  }
}

export default updateUserPasswordSaga;
