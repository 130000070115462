import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { putUserRoleRequestAPI } from "../../components/Config/Apiinterface";
import { updateUserRoleSuccess ,updateUserRoleFailure } from "../../Actions/User/updateUserRoleAction";

export function* updateUserRoleSaga(action) {
  const path = "admin/player/migrate";
  const token = action.payload.token;
  const body = action.payload;

  try {
    const response = yield call(putUserRoleRequestAPI, token, body, path);
    yield put(updateUserRoleSuccess(response));
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    alert("Something went wrong");
    yield put(updateUserRoleFailure(error));
  }
}

export default updateUserRoleSaga;
