import {
  GET_WALLET_DATA_REQUEST,
  GET_WALLET_DATA_SUCCESS,
  GET_WALLET_DATA_FAILURE,
} from "../../Utils/Constants";

export const getWalletDataRequest = (payload) => {
  return {
    type: GET_WALLET_DATA_REQUEST,
    payload: payload,
  };
};

export const getWalletDataSuccess = (data) => {
  return {
    type: GET_WALLET_DATA_SUCCESS,
    payload: data,
  };
};

export const getWalletDataFailure = (error) => {
  return {
    type: GET_WALLET_DATA_FAILURE,
    payload: error,
  };
};
