import React from "react";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Select } from "./select/Select";

const PaginationFooter = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  handlepaginateValue,
  dropdownOptions,
  itemperPage,
  fetchFirestoreCollection,
  searchValue,
}) => {
  return (
    <div className="flex flex-wrap items-center justify-center md:justify-between gap-2 px-2 md:px-5 py-1 md:py-3 bg-white shadow-md sticky bottom-0 w-full border-t">
      <div className="flex flex-wrap justify-content-center align-content-center gap-3">
        <Select
          id="itemperPage"
          options={dropdownOptions}
          callbackFn={handlepaginateValue}
          initialValue={itemperPage}
        />
        {/* visible in small device  */}
        <p className="block md:hidden text-sm font-bold text-green-500 py-auto my-auto">
          Pages: {currentPage}/{totalPages}
        </p>
      </div>
      <div className="flex flex-wrap justify-content-center align-content-center justify-between md:justify-end gap-3">
        {/* visible in large device  */}
        <p className="hidden md:block text-sm font-bold text-green-500 py-auto my-auto">
          Pages: {currentPage}/{totalPages}
        </p>

        <button
          onClick={() => {
            const searching = !!searchValue;
            fetchFirestoreCollection(1, true, true, searching);
          }}
          disabled={currentPage === 1}
          className="text-sm min-w-24 px-3 h-10 leading-tight text-gray-500 border border-gray-300 rounded-lg hover:text-gray-700 hover:bg-green-300 disabled:bg-gray-200 disabled:cursor-not-allowed"
        >
          Go to first
        </button>

        <div className="flex gap-3">
          <div
            onClick={currentPage > 1 ? handlePrevPage : undefined}
            aria-disabled={currentPage <= 1}
            className={`flex items-center justify-center px-3 h-10 leading-tight text-gray-500 border border-gray-300 rounded-l-lg ${
              currentPage <= 1
                ? "bg-gray-300 cursor-not-allowed"
                : "cursor-pointer hover:text-gray-700 hover:bg-green-300"
            }`}
          >
            <MdOutlineKeyboardDoubleArrowLeft />
          </div>
          <div
            onClick={currentPage < totalPages ? handleNextPage : undefined}
            aria-disabled={currentPage === totalPages}
            className={`flex items-center justify-center px-3 h-10 leading-tight text-gray-500 border border-gray-300 rounded-r-lg ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "cursor-pointer hover:text-gray-700 hover:bg-green-300"
            }`}
          >
            <MdOutlineKeyboardDoubleArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaginationFooter;
