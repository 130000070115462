import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";
import {  getSoundDataFailure, getSoundDataSuccess } from "../../Actions";

export function* getSoundDataSaga(action) {
  const token = action.payload;
  const path = `admin/appAudio`;

  try {
    const response = yield debounce(GetAPI, token?.token, path);
    yield put(getSoundDataSuccess(response));
  } catch (error) {
    yield put(getSoundDataFailure(error));
  }
}
