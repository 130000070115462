import { combineReducers } from "redux";
import configureStore from "../store";
import LogInReducer from "./LogInReducer";
import rootSaga from "../Sagas";
import AllCategoriesReducer from "./AllCategoriesReducer";
import SearchCategoriesReducer from "./SearchCategoriesReducer";
import TopicReducer from "./TopicsReducer";
import DeleteCategoryReducer from "./DeleteCategoriesReducer";
import AddCategoryReducer from "./AddCategoryReducer";
import AddTopicReducers from "./AddTopicReducers";
import DeleteTopicReducer from "./DeleteTopicReducer";
import UpdateCategoryReducer from "./UpdateCategoryReducer";
import UpdateTopicReducer from "./UpdateTopicReducer";
import ActiveCategoryReducer from "./ActiveCategoryReducer";
import ActiveTopicReducer from "./ActiveTopicReducer";
import TopicByCategoryIdReducer from "./TopicByCategoryIdReducer";
import SearchTopicsReducer from "./SearchTopicsReducer";
import quizReducer from "./QuizReducer";
import searchQuizReducer from "./SearchQuizReducer";
import deleteQuizReducer from "./DeleteQuizReducer";
import updateQuizReducer from "./UpdateQuizReducer";
import updateQuizStatusReducer from "./updateQuizStatusReducer";
import questionsReducer from "./questionsReducers/questionsReducers";
import CancelQuizReducer from "./CancelQuizReducer";
import leaderboardReducer from "./leaderboardReducer/leaderboardReducer";
import AddQuizReducer from "./AddQuizReducer";
import selectQuestionReducer from "./SelectQuestionReducer";
import selectQuestionByQuizIdReducer from "./SelectQuestionByQuizIdReducer";
import randomQuestionReducer from "./RandomQuestionReducer";
import addUserReducer from "./User/addUserReducer";
import updateUserReducer from "./User/updateUserReducer";
import deleteUserReducer from "./User/deleteUserRaducer";
import GetUserByIdReducer from "./User/getUserByIdReducer";
import resetPasswordReducer from "./User/resetPasswordReducer";
import { addBalanceReducer } from "./User/addBalanceReducer";
import { activeUserReducer } from "./User/activeUserReducer";
import deleteMultipulQuestionsReducer from "./DeleteMultipulQuestionsReducer";
import GetAllWithdrawalReducer from "./User/getAllWithdrawalReducer";
import getBankAccountReducer from "./User/getBankAccountReducer";
import getUpiAccountReducer from "./User/getUpiAccountReducer";
import getUserAllReducer from "./User/user_all/getAllUserReducer";
import deleteBankUpiAccountsReducer from "./User/deleteBankUpiAccountsReducer";
import getUserAllPlayedQuizReducer from "./User/getUserAllPlayedQuizReducer";
import getUserAllTransectionReducer from "./User/getUserAllTransectionsReducer";
import getReferredToReducer from "./User/getReferredToReducer.js";
import { ContactUsAllReducer } from "./ContactUsAllReducer";
import AssigneeReducer from "./AssigneeReducer";
import updateContactUsReducer from "./UpdateContactUsReducer";
import notificationReducer from "./notification/getNotificationReducer";
import sendNotificationReducer from "./notification/sendNotificationReducer";
import transactionReducer from "./Transaction/transactionReducer";
import leaderboardUserQuestionReducer from "./leaderboardReducer/leaderboardUserQuestionReducer";
import activityLogReducer from "./settings/activityLogReducer";
import commissionRatesReducer from "./settings/commissionRatesReducer.js";
import updateCommissionRatesReducer from "./settings/updateCommissionRateReducer";
import reviewTimeReducer from "./settings/reviewTimeReducer";
import updateReviewTimeReducer from "./settings/updateReviewTimeReducer";
import menuReducer from "./settings/menuReducer";
import updateMenuReducer from "./settings/updateMenuReducer";
import verifyOtpWithLoginReducer from "./login/verifyOtpWithLoginReducer";
import sendOtpWithLoginReducer from "./login/sendOtpWithLoginReducer";
import VersionPostReducer from "./versionPostReducer.js";
import VersionGetReducer from "./versionGetReducer.js";
import getPlayerAllReducer from "./User/user_all/getAllPlayerReducer.js";
import getPlayerReducer from "./User/player_all/playerGetReducer.js";
import manageAccessReducer from "./settings/manageAccessReducer.js";
import DashBoardReducer from "./dashboardReducer/dashboardReducer.js";
import getGoogleReducer from "./dashboardReducer/getGoogleTokenReducer.js";
import manageAccessPutReducer from "./settings/manageAccessPutReducre.js";
import serverInfoReducer from "./settings/serverInfo.js";
import getBannerDataReducer from "./settings/getBannerDataReducer.js";
import getRewardDataReducer from "./settings/getRewardDataReducer.js";
import getSoundDataReducer from "./settings/getSoundDataReducer.js";
import getPopUpMessageDataReducer from "./settings/getPopupMessageReducer.js";
import updateBannerReducer from "./settings/updateBannerReducer.js";
import updateRewardReducer from "./settings/updateRewardReducer.js";
import updateSoundReducer from "./settings/updateSoundReducer.js";
import updatePopUpMessageReducer from "./settings/updatePopUpMessageReducer.js";
import VersionDeleteReducer from "./versionDeleteReducer.js";
import updatePlayerReducer from "./User/player_all/playerUpdateReducer.js";
import deletePlayerReducer from "./User/player_all/playerDeleteReducer.js";
import resetPlayerPasswordReducer from "./User/player_all/resetPlayerPasswordReducer.js";
import updateQuizSpotPercentageReducer from "./settings/updateQuizSpotPercentageReducer.js";
import quizSpotPercentagesReducer from "./settings/quizSpotPercentageReducer.js";
import sendSheduledNotificationReducer from "./notification/sendSheduledNotificationReducer.js";
import updateScheduleNotificationReducer from "./notification/updateScheduleNotificationReducer.js";
import deleteScheduleNotificationReducer from "./notification/deleteScheduleNotificationReducer.js";
import scheduleNotificationReducer from "./notification/getScheduleNotificationReducer.js";
import quizBotsProbabilityReducer from "./settings/quizBotsProbabilityReducer.js";
import updateQuizBotsProbabilityReducer from "./settings/updateQuizBotsProbabilityReducer.js";
import AssignQuestionReducer from "./AssignQuestionReducer.js";
import RemoveAssignedQuestionsReducer from "./RemoveAssignedQuestionsReducer.js";
import GetAssignedQuestionReducer from "./GetAssignedQuestionsReducer.js";
import availableQuestionsCountReducer from './questionsReducers/availableQuestionsCountReducers.js';
import updateUserRoleReducer from './User/updateUserRoleReducer.js';
import updateWalletReducer from "./settings/updateWalletReducer.js";
import getWalletDataReducer from "./settings/getWalletDataReducer.js";
// eslint-disable-next-line
export default () => {
  const rootReducer = combineReducers({
    LogInReducer,
    AllCategoriesReducer,
    TopicReducer,
    SearchCategoriesReducer,
    DeleteCategoryReducer,
    AddCategoryReducer,
    AddTopicReducers,
    DeleteTopicReducer,
    UpdateCategoryReducer,
    UpdateTopicReducer,
    ActiveCategoryReducer,
    ActiveTopicReducer,
    SearchTopicsReducer,
    TopicByCategoryIdReducer,
    quizReducer,
    searchQuizReducer,
    deleteQuizReducer,
    updateQuizReducer,
    AssignQuestionReducer,
    RemoveAssignedQuestionsReducer,
    GetAssignedQuestionReducer,
    updateQuizStatusReducer,
    questionsReducer,
    CancelQuizReducer,
    leaderboardReducer,
    AddQuizReducer,
    selectQuestionReducer,
    selectQuestionByQuizIdReducer,
    randomQuestionReducer,
    addUserReducer,
    updateUserReducer,
    deleteUserReducer,
    GetUserByIdReducer,
    resetPasswordReducer,
    addBalanceReducer,
    activeUserReducer,
    deleteMultipulQuestionsReducer,
    GetAllWithdrawalReducer,
    getBankAccountReducer,
    getUpiAccountReducer,
    getUserAllReducer,
    deleteBankUpiAccountsReducer,
    getUserAllPlayedQuizReducer,
    getUserAllTransectionReducer,
    getReferredToReducer,
    ContactUsAllReducer,
    AssigneeReducer,
    updateContactUsReducer,
    notificationReducer,
    sendNotificationReducer,
    sendSheduledNotificationReducer,
    transactionReducer,
    leaderboardUserQuestionReducer,
    activityLogReducer,
    commissionRatesReducer,
    updateCommissionRatesReducer,
    quizSpotPercentagesReducer,
    updateQuizSpotPercentageReducer,
    reviewTimeReducer,
    updateReviewTimeReducer,
    menuReducer,
    updateMenuReducer,
    verifyOtpWithLoginReducer,
    sendOtpWithLoginReducer,
    VersionPostReducer,
    VersionGetReducer,
    getPlayerAllReducer,
    getPlayerReducer,
    manageAccessReducer,
    DashBoardReducer,
    getGoogleReducer,
    manageAccessPutReducer,
    serverInfoReducer,
    getBannerDataReducer,
    getRewardDataReducer,
    getSoundDataReducer,
    getPopUpMessageDataReducer,
    updateBannerReducer,
    updateRewardReducer,
    updateSoundReducer,
    updatePopUpMessageReducer,
    VersionDeleteReducer,
    updatePlayerReducer,
    deletePlayerReducer,
    resetPlayerPasswordReducer,
    updateScheduleNotificationReducer,
    deleteScheduleNotificationReducer,
    scheduleNotificationReducer,
    quizBotsProbabilityReducer,
    updateQuizBotsProbabilityReducer,
    availableQuestionsCountReducer,
    updateUserRoleReducer,
    updateWalletReducer,
    getWalletDataReducer
  });

  return configureStore(rootReducer, rootSaga);
};
