// src/redux/sagas.js

import { put, call } from "redux-saga/effects";
import { UpdateAPI } from "../components/Config/Apiinterface";
import { UpdateTopicFailure, UpdateTopicSuccess } from "../Actions";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Update Category request
function* UpdateTopicSaga(action) {
  const formdata = new FormData();
  formdata.append("topicImage", action.payload.image);
  const topicId = action.payload.topicId;
  const categoryId = action.payload.categoryId;
  const topicName = action.payload.nameEng;
  const topicNameHindi = action.payload.nameHin;
  const token = action.payload.token;
  const isFavourite = action.payload?.isFavourite;
  let path;
  if (topicName.length) {
    path = `category/updateTopic?categoryId=${categoryId}&topicId=${topicId}&topicNameEng=${topicName}&topicNameHin=${topicNameHindi}&isFavourite=${isFavourite}`;
  } else {
    path = `category/updateTopic?categoryId=${categoryId}&topicId=${topicId}`;
  }

  try {
    const UpdateTopic = yield debounce(UpdateAPI, token, path, formdata);
    yield put(UpdateTopicSuccess(UpdateTopic));
  } catch (error) {
    alert("Something went wrong");
    yield put(UpdateTopicFailure(error));
  }
}

export default UpdateTopicSaga;
