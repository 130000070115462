import { Sidebar } from "./Sidebar";
import { Navbar } from "./Navbar";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const Layout = ({ user }) => {
  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 767);
  const [userData, setUserData] = useState("");

  // Effect to handle initial size and add resize event listener
  useEffect(() => {
    const handleResize = () => setShowSidebar(window.innerWidth > 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("userData");
    // If there's stored data, set it, otherwise set user data from props
    if (storedUserData) {
      setUserData(storedUserData);
    } else if (user?.user?.data?.name) {
      setUserData(user.user.data.name);
    }
  }, [user]);

  // Effect to update local storage when userData changes
  useEffect(() => {
    sessionStorage.setItem("userData", userData);
  }, [userData]);

  return (
    <div>
      <Navbar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        name={userData}
      />
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.LogInReducer,
  };
};

export default connect(mapStateToProps)(Layout);
