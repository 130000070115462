import {
  collection,
  getDocs,
  query,
  where,
  count,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../firebase";

const scenario_names = [
    "user_login",
    "user_registration",
    "quiz_joined",
    "quiz_resume",
    "quiz_selection",
    "quiz_started",
    "category_change",
    "topic_change",
    "add_cash",
    "add_cash_success",
    "add_cash_pending",
    "add_cash_failed",
    "withdraw_cash",
    "quiz_complete",
    "completed_quiz",
    "cancelled_quiz",
    "scheduled_quiz",
    "scratch_card_1st_play",
    "scratch_card_on_invitation",
    "scratch_card_from_invitation",
];

/**
 * Fetch Firestore data separately for each scenario name.
 * @param {string} collectionName - The name of the Firestore collection.
 * @returns {Promise<object>} - An object where keys are scenario names and values are fetched documents.
 */
const fetchFirestoreDataByScenario = async (collectionName) => {
  try {
    const results = [];

    // Fetch data for each scenario separately
    for (const scenario of scenario_names) {
      const q = query(
        collection(db, collectionName),
        where("scenario_name", "==", scenario)
      );
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;
      results.push({ scenario_name: scenario, count });
    }
    return results;
  } catch (err) {
    console.error("Error fetching documents:", err);
    throw err;
  }
};

export default fetchFirestoreDataByScenario;
