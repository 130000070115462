import {
  UPDATE_POPUPMESSAGE_DATA_REQUEST,
  UPDATE_POPUPMESSAGE_DATA_SUCCESS,
  UPDATE_POPUPMESSAGE_DATA_FAILURE,
} from "../../Utils/Constants";

export const updatePopUpMessageRequest  = (payload) => {
  return {
    type: UPDATE_POPUPMESSAGE_DATA_REQUEST,
    payload: payload,
  };
};

export const updatePopUpMessagSuccess = (data) => {
  return {
    type: UPDATE_POPUPMESSAGE_DATA_SUCCESS,
    payload: data,
  };
};

export const updatePopUpMessageFailure = (error) => {
  return {
    type: UPDATE_POPUPMESSAGE_DATA_FAILURE,
    payload: error,
  };
};
