import { put } from "redux-saga/effects";
import {
  updatePopUpMessagSuccess,
  updatePopUpMessageFailure,
} from "../../Actions";
import { putRequestAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";
import { toast } from "react-toastify";

export function* updatePopUpMessageSaga(action) {
  const { data, token } = action.payload;
  const path = `admin/messagePopup/enable`;

  const ToastId = toast.loading("Updating ..");

  try {
    const response = yield debounce(putRequestAPI, token, data, path);
    toast.update(ToastId, {
      render: response.message,
      type: "success",
      autoClose: 2000,
      isLoading: false,
    });
    yield put(updatePopUpMessagSuccess(response));
  } catch (error) {
    yield put(updatePopUpMessageFailure(error));
    toast.update(ToastId, {
      render: error.message,
      type: "error",
      autoClose: 2000,
      isLoading: false,
    });
  }
}
