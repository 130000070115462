import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { BiError } from "react-icons/bi";
import serverIP from "../Screens/Settings/serverIP";
import quizSpotPercentage from "../Screens/Settings/quizSpotPercentage";
import AssignQuestionReducer from "../Reducers/AssignQuestionReducer";
import RemoveAssignedQuestionsReducer from "../Reducers/RemoveAssignedQuestionsReducer";
import GetAssignedQuestionReducer from "../Reducers/GetAssignedQuestionsReducer";

const IpBlockingFn = ({
  logIn,
  allCategory,
  topic,
  searchCategory,
  deleteCategory,
  addCategory,
  addTopic,
  deleteTopic,
  updateCategory,
  updateTopic,
  activityCategory,
  activeTopic,
  searchTopic,
  topicByCategory,
  quiz,
  searchQuiz,
  deleteQuiz,
  updateQuiz,
  updateQuizStatus,
  questions,
  cancelQuiz,
  leaderboard,
  addQuiz,
  selectQuestion,
  selectQuestionByQuizId,
  randomQuestion,
  addUser,
  updateUser,
  deleteUser,
  getUserById,
  resetPassword,
  addBalance,
  activeUser,
  deleteMultipleQuestions,
  getAllWithdrawal,
  getBankAccount,
  getUpiAccount,
  getUserAll,
  deleteBankUpiAccounts,
  getUserAllPlayedQuiz,
  getUserAllTransaction,
  contactUsAll,
  assignee,
  updateContactUs,
  notification,
  getScheduleNotification,
  sendNotification,
 sendSheduledNotification,
  transaction,
  leaderboardUserQuestion,
  activityLog,
  commissionRates,
  quizBotsProbability,
  quizSpotPercentage,
  updateCommissionRates,
  updateQuizBotsProbability,
  updateQuizSpotPercentage,
  reviewTime,
  updateReviewTime,
  menu,
  updateMenu,
  verifyOtpWithLogin,
  sendOtpWithLogin,
  versionPost,
  versionGet,
  getPlayer,
  getPlayers,
  manageAccess,
  dashboard,
  googleToken,
  manageAccessReducer,
  manageAccessPutReducer,
  serverIP,
  deleteVersion,
  updatePlayer,
  deletePlayer,
  deleteScheduleNotification,
  resetPlayerPassword,
}) => {
  const navigate = useNavigate();
  const [sessionExpired, setSessionExpired] = useState(false);
  useEffect(() => {
    const statusCodeList = [
      logIn?.user?.data?.statusCode,
      allCategory?.AllCategories?.statusCode,
      topic?.Topics?.statusCode,
      searchCategory?.SearchCategories?.statusCode,
      deleteCategory?.response,
      addCategory?.newCategory?.statusCode,
      addTopic?.statusCode,
      deleteTopic?.statusCode,
      updateCategory?.updateCategory?.statusCode,
      updateTopic?.statusCode,
      activityCategory?.ActiveCategory?.statusCode,
      activeTopic?.statusCode,
      searchTopic?.statusCode,
      topicByCategory?.topics?.statusCode,
      quiz?.quiz?.statusCode,
      searchQuiz?.statusCode,
      deleteQuiz?.statusCode,
      updateQuiz?.statusCode,
      updateQuizStatus?.statusCode,
      questions?.questions?.statusCode,
      cancelQuiz?.statusCode,
      leaderboard?.statusCode,
      addQuiz?.statusCode,
      selectQuestion?.statusCode,
      selectQuestionByQuizId?.statusCode,
      randomQuestion?.statusCode,
      addUser?.addUser?.statusCode,
      updateUser?.updateUser?.statusCode,
      deleteUser?.deleteUser?.statusCode,
      getUserById?.userById?.statusCode,
      resetPassword?.resetPassword?.statusCode,
      addBalance?.addBalance?.statusCode,
      activeUser?.error?.response?.data?.statusCode,
      deleteMultipleQuestions?.deleteMultipulQuestions?.statusCode,
      getAllWithdrawal?.allWithdrawal?.statusCode,
      getBankAccount?.bankAccount?.statusCode,
      getUpiAccount?.upiAccount?.statusCode,
      getUserAll?.allUser?.statusCode,
      getPlayer?.allPlayer?.statusCode,
      deleteBankUpiAccounts?.statusCode,
      getUserAllPlayedQuiz?.allPlayedQuiz?.statusCode,
      getUserAllTransaction?.allUserTransections?.statusCode,
      contactUsAll?.conatctUs?.statusCode,
      assignee?.assignee?.statusCode,
      updateContactUs?.updateContactUs,
      notification?.notification?.statusCode,
      getScheduleNotification?.getScheduleNotification?.statusCode,
      sendNotification?.sendNotification?.statusCode,
      sendSheduledNotification?.sendSheduledNotification.statusCode,
      transaction?.transaction?.statusCode,
      leaderboardUserQuestion?.statusCode,
      activityLog?.statusCode,
      commissionRates?.commissionRate?.statusCode,
      quizBotsProbability?.quizBotsProbability.statusCode,
      quizSpotPercentage?.quizSpotPercentageRate?.statusCode,
      updateCommissionRates?.updateRate?.statusCode,
      updateQuizBotsProbability?.updateQuizBotsProbability.statusCode,
      updateQuizSpotPercentage?.updateQuizSpotRate.statusCode,
      reviewTime?.reviewTime?.statusCode,
      updateReviewTime?.statusCode,
      menu?.statusCode,
      updateMenu?.statusCode,
      verifyOtpWithLogin?.statusCode,
      sendOtpWithLogin?.statusCode,
      versionPost?.statusCode,
      versionGet?.version?.statusCode,
      manageAccess?.manageAccess?.statusCode,
      dashboard?.data?.statusCode,
      googleToken?.data?.statusCode,
      manageAccessPutReducer,
      manageAccessReducer,
      serverIP?.reviewTime?.statusCode,
      deleteVersion?.version?.statusCode,
      getPlayers?.allPlayer?.statusCode,
      updatePlayer?.updatePlayer?.statusCode,
      deletePlayer?.deletePlayer?.statusCode,
      deleteScheduleNotification?.deleteScheduleNotification?.statusCode,
      resetPlayerPassword?.resetPlayerPassword?.statusCode,
    ];
    const has403 = statusCodeList.some((code) => code === 403);
    const has401 = statusCodeList.some((code) => code === 401);

    if (has403) {
      window.location.href = "/"; // Redirect to the home page for 403 errors
    } else if (has401) {
      setSessionExpired(true);
    }
  }, [
    logIn,
    allCategory,
    topic,
    searchCategory,
    deleteCategory,
    addCategory,
    addTopic,
    deleteTopic,
    updateCategory,
    updateTopic,
    activityCategory,
    activeTopic,
    searchTopic,
    topicByCategory,
    quiz,
    searchQuiz,
    deleteQuiz,
    updateQuiz,
    updateQuizStatus,
    questions,
    cancelQuiz,
    leaderboard,
    addQuiz,
    selectQuestion,
    selectQuestionByQuizId,
    randomQuestion,
    addUser,
    updateUser,
    deleteUser,
    getUserById,
    resetPassword,
    addBalance,
    activeUser,
    deleteMultipleQuestions,
    getAllWithdrawal,
    getBankAccount,
    getUpiAccount,
    getUserAll,
    deleteBankUpiAccounts,
    getUserAllPlayedQuiz,
    getUserAllTransaction,
    contactUsAll,
    assignee,
    updateContactUs,
    notification,
    getScheduleNotification,
    sendNotification,
    sendSheduledNotification,
    transaction,
    leaderboardUserQuestion,
    activityLog,
    commissionRates,
    quizBotsProbability,
    quizSpotPercentage,
    updateCommissionRates,
    updateQuizBotsProbability,
    updateQuizSpotPercentage,
    reviewTime,
    updateReviewTime,
    menu,
    updateMenu,
    verifyOtpWithLogin,
    sendOtpWithLogin,
    versionPost,
    versionGet,
    getPlayer,
    manageAccess,
    dashboard,
    googleToken,
    manageAccessReducer,
    manageAccessPutReducer,
    serverIP,
    deleteVersion,
    getPlayers,
    updatePlayer,
    deletePlayer,
    deleteScheduleNotification,
    resetPlayerPassword,
  ]);

  if (sessionExpired) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-20">
        <div className="fixed inset-0  bg-black opacity-80 backdrop-blur-sm" />
        {/* md:ml-60 */}
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative flex flex-col items-center ">
          <BiError className="text-red-600 text-[3rem] my-2" />
          <h2 className="text-xl font-bold mb-3 text-gray-800">
            Access Rights Changed
          </h2>
          <p className="mb-6 text-gray-600 text-sm text-center">
            Your access credentials have been updated. For security reasons,
            please log out and then log back in to start your session.
          </p>
          <button
            className="bg-[#4CAF50] text-white py-2 px-12 rounded-lg shadow-md hover:bg-[#378039] focus:outline-none focus:ring-2 focus:ring-[#378039]"
            onClick={() => {
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = "/login";
            }}
          >
            Log out
          </button>
        </div>
      </div>
    );
  }

  return null; // This component doesn’t render anything
};

const mapStateToProps = (state) => {
  return {
    logIn: state.LogInReducer,
    allCategory: state.AllCategoriesReducer,
    topic: state.TopicReducer,
    searchCategory: state.SearchCategoriesReducer,
    deleteCategory: state.DeleteCategoryReducer,
    addCategory: state.AddCategoryReducer,
    addTopic: state.AddTopicReducers,
    deleteTopic: state.DeleteTopicReducer,
    updateCategory: state.UpdateCategoryReducer,
    updateTopic: state.UpdateTopicReducer,
    activityCategory: state.ActiveCategoryReducer,
    activeTopic: state.ActiveTopicReducer,
    searchTopic: state.SearchTopicsReducer,
    topicByCategory: state.TopicByCategoryIdReducer,
    quiz: state.quizReducer,
    searchQuiz: state.searchQuizReducer,
    deleteQuiz: state.deleteQuizReducer,
    updateQuiz: state.updateQuizReducer,
    updateQuizStatus: state.updateQuizStatusReducer,
    questions: state.questionsReducer,
    cancelQuiz: state.CancelQuizReducer,
    leaderboard: state.leaderboardReducer,
    addQuiz: state.AddQuizReducer,
    selectQuestion: state.selectQuestionReducer,
    selectQuestionByQuizId: state.selectQuestionByQuizIdReducer,
    randomQuestion: state.randomQuestionReducer,
    addUser: state.addUserReducer,
    updateUser: state.updateUserReducer,
    deleteUser: state.deleteUserReducer,
    getUserById: state.GetUserByIdReducer,
    resetPassword: state.resetPasswordReducer,
    addBalance: state.addBalanceReducer,
    activeUser: state.activeUserReducer,
    deleteMultipleQuestions: state.deleteMultipulQuestionsReducer,
    getAllWithdrawal: state.GetAllWithdrawalReducer,
    getBankAccount: state.getBankAccountReducer,
    getUpiAccount: state.getUpiAccountReducer,
    getUserAll: state.getUserAllReducer,
    deleteBankUpiAccounts: state.deleteBankUpiAccountsReducer,
    getUserAllPlayedQuiz: state.getUserAllPlayedQuizReducer,
    getUserAllTransaction: state.getUserAllTransectionReducer,
    referredToData:state.getReferredToReducer,
    contactUsAll: state.ContactUsAllReducer,
    assignee: state.AssigneeReducer,
    updateContactUs: state.updateContactUsReducer,
    notification: state.notificationReducer,
    getScheduleNotification: state.scheduleNotificationReducer,
    sendNotification: state.sendNotificationReducer,
    sendSheduledNotification: state.sendSheduledNotificationReducer,
    transaction: state.transactionReducer,
    leaderboardUserQuestion: state.leaderboardUserQuestionReducer,
    activityLog: state.activityLogReducer,
    commissionRates: state.commissionRatesReducer,
    quizBotsProbability:state.quizBotsProbabilityReducer,
    quizSpotPercentage: state.quizSpotPercentagesReducer,
    updateCommissionRates: state.updateCommissionRatesReducer,
    updateQuizBotsProbability:state.updateQuizBotsProbabilityReducer,
    updateQuizSpotPercentage: state.updateQuizSpotPercentageReducer,
    reviewTime: state.reviewTimeReducer,
    updateReviewTime: state.updateReviewTimeReducer,
    menu: state.menuReducer,
    updateMenu: state.updateMenuReducer,
    verifyOtpWithLogin: state.verifyOtpWithLoginReducer,
    sendOtpWithLogin: state.sendOtpWithLoginReducer,
    versionPost: state.VersionPostReducer,
    versionGet: state.VersionGetReducer,
    getPlayer: state.getPlayerAllReducer,
    manageAccess: state.manageAccessReducer,
    dashboard: state.DashBoardReducer,
    googleToken: state.getGoogleReducer,
    manageAccessReducer: state.manageAccessReducer,
    manageAccessPutReducer: state.manageAccessPutReducer,
    serverIP: state.serverInfoReducer,
    bannerData:state.getBannerDataReducer,
    rewardData:state.getRewardDataReducer,
    popUpMessageData:state.getPopUpMessageDataReducer,
    updateRewardData:state.updateRewardReducer,
    updateSoundData:state.updateSoundReducer,
    updatePopUpMessageData:state.updatePopUpMessageReducer,
    updateBannerData:state.updateBannerReducer,
    assignQuestion:AssignQuestionReducer,
    removeAssignedQuestions:RemoveAssignedQuestionsReducer,
    getAssignedQuestion:GetAssignedQuestionReducer,
    deleteVersion: state.VersionDeleteReducer,
    getPlayers: state.getPlayerReducer,
    updatePlayer: state.updatePlayerReducer,
    deletePlayer: state.deletePlayerReducer,
    deleteScheduleNotification: state.deleteScheduleNotificationReducer,
    resetPlayerPassword: state.resetPlayerPasswordReducer,
  };
};

export default connect(mapStateToProps)(IpBlockingFn);
