import { GiHamburgerMenu } from "react-icons/gi";
import { Select } from "../../components/select/SelectNew";
import { Button } from "../../components/ui-elements/Button";
import { useEffect, useState } from "react";
import { Pagination } from "../../components/Pagination";
import { MdDelete, MdModeEditOutline, MdRefresh } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { getNotificationRequest } from "../../Actions/notification/getNotificationAction";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import DescriptionModal from "../../components/modal/descriptionModal";
import { SendNotification } from "../../components/modal/sendNotificationModal";
import {
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import Loading from "../../components/loading";
import { TbServerOff } from "react-icons/tb";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { FaHistory } from "react-icons/fa";
import { useNavigate } from "react-router";
import { DeleteModal } from "../../components/modal/DeleteModal";
import {
  deletePlayerRequest,
  deleteScheduleNotificationRequest,
  getScheduleNotificationRequest,
} from "../../Actions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import TooltipWrapper from "../../components/ui-elements/TooltipWrapper";
import { renderToStaticMarkup } from "react-dom/server";

const Notification = ({
  notification,
  getScheduleNotification,
  sendNotification,
  sendSheduledNotification,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [notificationArray, setNotificationArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [openDeleteCUserModal, setOpenDeleteCUserModal] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("notifyCurrentPage")) || 1
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [sortValue, setSortValue] = useState("");
  const [sortValue, setSortValue] = useState(
    localStorage.getItem("notifyFilterValue") || ""
  );
  const [showInitial, setShowInitial] = useState(false);
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [sendNotificationModal, setSendNotificationModal] = useState(false);
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const formatDate = (dateTimeString) => {
    const formattedDateTimeString = dateTimeString?.replace(
      /(\d{2})-(\d{2})-(\d{4})/,
      "$2/$1/$3"
    );

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(formattedDateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const tableHeadersSchedule = [
    "id",
    "Schedule Time",
    "title",
    "body",
    "type",
    "role",
    "actions",
  ];
  const dropdownOptions = [
    {
      id: "ALL",
      name: "All",
    },
    {
      id: "PLAYER",
      name: "Player",
    },
    {
      id: "ADMIN",
      name: "Admins",
    },
    {
      id: "INVESTOR",
      name: "Investors",
    },
    {
      id: "FINANCE",
      name: "Finance/Legal",
    },
    {
      id: "CUSTOMER_SUPPORT",
      name: "Customer Support",
    },
    {
      id: "MARKETING_REPRESENTATIVE",
      name: "Marketing",
    },
  ];

  const [expandedIds, setExpandedIds] = useState([]);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const toggleText = (id, data) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id]
    );

    if (!expandedIds.includes(id)) {
      setModalData(data);
      setOpenDescriptionModal(true);
    }
  };

  const renderText = (item) => {
    if (expandedIds.includes(item.id)) {
      return (
        <>
          {openDescriptionModal ? null : (
            <div className="flex gap-2 items-center">
              {item.body?.slice(0, 20)}
              {item.body?.length > 20 && (
                <span
                  onClick={() => toggleText(item.id, item.body)}
                  className="text-blue-500 flex text-center items-center cursor-pointer"
                >
                  {" "}
                  Read More
                </span>
              )}
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className="flex gap-2  items-center">
          {item.body?.slice(0, 20)}
          {item.body?.length > 20 && (
            <span
              onClick={() => toggleText(item.id, item.body)}
              className="text-blue-500 flex  text-center items-center cursor-pointer"
            >
              {" "}
              Read More
            </span>
          )}
        </div>
      );
    }
  };
  const getNotification = () => {
    const body = {
      token: token,
      pageNo: currentPage,
      sortValue,
    };
    dispatch(getScheduleNotificationRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    getNotification();
  }, [currentPage, sendNotificationModal]);

  useEffect(() => {
    getNotification();
    localStorage.setItem("notifyCurrentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (getScheduleNotification?.loading) {
      setIsLoading(true);
    }
    if (getScheduleNotification?.error) {
      setIsLoading(false);
    }

    if (getScheduleNotification?.getScheduleNotification?.data?.notifications) {
      setNotificationArray(
        getScheduleNotification?.getScheduleNotification?.data?.notifications
      );
      setIsLoading(false);
    }

    if (searchValue && getScheduleNotification?.data) {
      setNotificationArray([getScheduleNotification?.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (getScheduleNotification?.getScheduleNotification?.statusCode === 404 ||
        getScheduleNotification?.getScheduleNotification?.statusCode === 400)
    ) {
      setNotificationArray([]);
      setIsLoading(false);
    }

    if (getScheduleNotification?.getScheduleNotification?.data?.totalPages) {
      setTotalPages(
        getScheduleNotification?.getScheduleNotification?.data?.totalPages
      );
    }
    setIsSubmittingFilter(false);
    setShowInitial(true);
    // eslint-disable-next-line
  }, [getScheduleNotification]);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
  }, []);

  const dropDown = localStorage.getItem("notifyDrop");

  const handleSortValues = (id, value) => {
    if (id === "dropdownOptions") {
      setSortValue(value);
      localStorage.setItem("notifyFilterValue", value);
      localStorage.setItem("notifyDrop", true);
    }
  };

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    const params = {
      token,
      pageNo: currentPage,
      sortValue,
    };

    const body = {
      ...params,
      ...(sortValue && { sortValue }),
    };
    dispatch(getScheduleNotificationRequest(body));
    setShowInitial(false);
    setIsSubmittingFilter(false);
  };

  const resetFilters = () => {
    localStorage.removeItem("notifyFilterValue");
    localStorage.removeItem("notifyDrop");
    setCurrentPage(1);
  };

  const handleClearFilter = () => {
    resetFilters();
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(getScheduleNotificationRequest(body));
    setSortValue("");
    setShowInitial(true);
  };

  const handleDeleteScheduleNotification = (data) => {
    const body = {
      data: {
        id: data,
      },
      token: token,
    };
    dispatch(deleteScheduleNotificationRequest(body));
    getNotification();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white  p-4 shadow-md rounded-lg">
        <div className="flex justify-between items-center">
          <div className="font-bold uppercase text-lg text-center">
            Notification
          </div>
        </div>
        {showFilterArea && (
          <div className="flex flex-col md:flex-row justify-between md:items-center mt-3">
            <div className="flex flex-col md:flex-row gap-2 md:items-center">
              <Select
                id="dropdownOptions"
                header="Filter By"
                options={dropdownOptions}
                callbackFn={handleSortValues}
                showInitial={showInitial}
                dropDown={dropDown}
              />
            </div>
            <div className="flex md:gap-2 md:self-end justify-between mt-3">
              <span>
                <Button
                  callbackFn={() => {
                    setCurrentPage(1);
                    handleSubmitFilter();
                  }}
                  displayName={"Submit"}
                  type={"submit"}
                  isLoading={isSubmittingFilter}
                />
              </span>
              <span>
                <Button
                  callbackFn={handleClearFilter}
                  displayName={"Reset"}
                  type={"reset"}
                />
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
        <div className="flex justify-between flex-wrap">
          <button onClick={getNotification}>
            <MdRefresh className="w-6 h-6 m-2" />
          </button>

          <div className="flex justify-end gap-2">
            <TooltipWrapper
              id="notification-wrapper-tooltip"
              content="Lists all the notifications that have been sent."
              position="bottom-left"
            >
              <button
                disabled={!checkAccess("NOTIFICATION", "R")}
                onClick={() => {
                  // setSendNotificationModal(true);
                  navigate("/notificationHistory");
                }}
                className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("NOTIFICATION", "R")
                    ? "accessDenied"
                    : "hover:cursor-pointer"
                }`}
              >
                Notification History
                <FaHistory className=" font-extrabold" />
              </button>
            </TooltipWrapper>
              <button
                disabled={!checkAccess("NOTIFICATION", "C")}
                onClick={() => {
                  setSendNotificationModal(true);
                }}
                className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                  !checkAccess("NOTIFICATION", "C")
                    ? "accessDenied"
                    : "hover:cursor-pointer"
                }`}
              >
                Send Notification
                <FaPlus />
              </button>
          </div>
        </div>
        <div>
          <div>
            <div className="flex justify-start gap-1 items-center">
              <div className="font-bold my-1 uppercase text-md">
                Scheduled Notification
              </div>
              <TooltipATM
                dataTooltipId={"notification-tooltip"}
                dataTooltipPlace={"right"}
                dataTooltipHtml={renderToStaticMarkup(<p>
                  Lists all the notifications scheduled to be sent in the future based on the specified time. Once a notification is sent, it will be removed from  this list and will be visible in the notification history.
                </p>)}
                className={" cursor-pointer"}
              />
            </div>

            <div>
              {notificationArray?.length ? (
                <>
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-700">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                          {tableHeadersSchedule?.map((item, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-3 text-center"
                            >
                              {item}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {notificationArray.map((item, index) => (
                          <tr className="bg-white border-b" key={index}>
                            <td className="px-6 py-2 text-center">{item.id}</td>
                            <td className="px-6 py-2 text-center">
                              {formatDate(item.sendAt)}
                            </td>
                            <td className="px-6 py-2 text-center">
                              {item.title}
                            </td>
                            <td className="px-6 py-2  text-center">
                              {renderText(item)}
                            </td>
                            <td className="px-6 py-2 text-center">
                              {item.type}
                            </td>
                            <td className="px-6 py-2 text-center">
                              {item.role}
                            </td>
                            <td className="px-3 py-2 text-center">
                              <div className="flex gap-2 justify-center">
                                <div
                                  className={`hover:bg-gray-100 p-2 rounded-full ${
                                    !checkAccess("NOTIFICATION", "U")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    if (checkAccess("NOTIFICATION", "U")) {
                                      navigate(
                                        {
                                          pathname:
                                            "/updateScheduleNotification",
                                        },
                                        { state: item }
                                      );
                                    }
                                  }}
                                >
                                  <MdModeEditOutline className="text-3xl" />
                                </div>
                                <div
                                  onClick={() => {
                                    if (checkAccess("NOTIFICATION", "D")) {
                                      setDeleteUserData({
                                        name: `Notification with id ${item.id}`,
                                        id: item.id,
                                      });
                                      setOpenDeleteCUserModal(true);
                                      // //   setSearchValue("");
                                    }
                                  }}
                                  className={`hover:bg-gray-100 p-2 rounded-full ${
                                    !checkAccess("NOTIFICATION", "D")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                >
                                  <MdDelete className="text-3xl" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}

                        {openDescriptionModal && (
                          <DescriptionModal
                            setOpenModal={setOpenDescriptionModal}
                            data={modalData}
                            heading="Body"
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                  {totalPages > 1 && !searchValue && (
                    <div className="flex justify-end pr-5 pt-4">
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={handlePageChange}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center flex flex-col justify-center items-center pt-10">
                  <TbServerOff className="text-[2rem] font-bold  m-2" />
                  Notification Not Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {openDeleteCUserModal && (
        <DeleteModal
          data={deleteUserData}
          handleDelete={handleDeleteScheduleNotification}
          setOpenDeleteModal={setOpenDeleteCUserModal}
        />
      )}
      {sendNotificationModal && (
        <SendNotification
          setOpenModal={setSendNotificationModal}
          heading={"Send Notification"}
          userType={dropdownOptions}
          token={token}
          dispatch={dispatch}
          SendNotification={sendNotification}
          sendSheduledNotification={sendSheduledNotification}
          place="top"
          content="If 'No' is selected, the notification will be sent immediately."
        />
      )}
     
       <ReactTooltip
        id="notification-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notification: state?.notificationReducer,
    getScheduleNotification: state?.scheduleNotificationReducer,
    sendNotification: state?.sendNotificationReducer,
    sendSheduledNotification: state?.sendSheduledNotificationReducer,
  };
};

export default connect(mapStateToProps)(Notification);
