import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import {
  getQuizBotsProbabilityRequest,
  updateQuizBotsProbabilityRequest,
} from "../../Actions";
import { MdModeEditOutline } from "react-icons/md";
import { PasswordModal } from "../../components/modal/passwordModal";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import Loading from "../../components/loading";
import { useNavigate } from "react-router";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { renderToStaticMarkup } from "react-dom/server";

const QuizBotsProbability = ({
  quizBotsProbability,
  updateQuizBotsProbability,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [botData, setBotData] = useState("");
  const [
    openUpdateQuizBotsProbabibilityModal,
    setOpenUpdateQuizBotsProbabibilityModal,
  ] = useState(false);
  // state for bots Joining probability Modal //
  const [
    openUpdateBotsJoiningProbabilityModal,
    setOpenUpdateBotsJoiningProbabilityModal,
  ] = useState(false);

  const [rateArray, setRateArray] = useState({});
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const tableHeaders = [
    -"Id",
    "Winning Probability",
    "Bots Rank",
    "Update Date",
    "Edit",
  ];
  const tableHeaders2 = ["Id", "Joining Probability", "Update Date", "Edit"];

  const onSubmit = (name, botRank) => {
    const body = {
      data: {
        probabilityInPercent: name,
        botWinnerCount: botRank,
      },
      token: token,
    };
    dispatch(updateQuizBotsProbabilityRequest(body));
  };
  const JoiningBotsOnSubmit = (item) => {
    const body = {
      data: {
        userThresholdForBotJoin: item,
      },
      token: token,
    };
    dispatch(updateQuizBotsProbabilityRequest(body));
  };

  const getQuizBotsProbability = () => {
    const body = {
      token: token,
    };
    dispatch(getQuizBotsProbabilityRequest(body));
  };

  useEffect(() => {
    getQuizBotsProbability();
  }, [updateQuizBotsProbability]);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  useEffect(() => {
    if (
      quizBotsProbability?.quizBotsProbability?.statusCode === 403 ||
      updateQuizBotsProbability?.updateQuizBotsProbability?.statusCode === 403
    ) {
      window.location.href = "/";
    }
    if (quizBotsProbability?.loading) {
      setIsLoading(true);
    }
    setTimeout(() => {
      if (quizBotsProbability?.loading === false) {
        setFormOpen(false);
      }
    }, [1000]);

    if (quizBotsProbability?.error) {
      setIsLoading(false);
    }

    if (quizBotsProbability?.quizBotsProbability.data) {
      setRateArray(quizBotsProbability?.quizBotsProbability.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [quizBotsProbability, updateQuizBotsProbability]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Quiz Winning Bots Probability
            </div>
            <TooltipATM
              dataTooltipId={"version-tooltip"}
              dataTooltipPlace={"left"}
              dataTooltipHtml={renderToStaticMarkup(
                <p>
                  This is the bot's quiz winning probability, which always
                  ranges from 0 to the specified value
                </p>
              )}
              className={" cursor-pointer"}
            />
          </div>
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-center"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b " key={rateArray.id}>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.id}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.probabilityInPercent} %</div>
                      </div>
                    </td>

                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.botWinnerCount}</div>
                      </div>
                    </td>

                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.updateDate}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>
                          {" "}
                          <div className="flex gap-x-3 justify-center">
                            <div
                              className={`hover:bg-gray-100 p-2 rounded-full ${
                                !checkAccess("QUIZBOTPROBABILITY", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                              onClick={() => {
                                if (checkAccess("QUIZBOTPROBABILITY", "U")) {
                                  setOpenUpdateQuizBotsProbabibilityModal(true);
                                  let botdata = {
                                    botWinnerCount: rateArray.botWinnerCount,
                                    probabilityInPercent:
                                      rateArray.probabilityInPercent,
                                  };
                                  setBotData(botdata);
                                }
                              }}
                            >
                              <MdModeEditOutline className="text-2xl" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
          {/* --------------------- writing code for bots probability ----------------------- */}

          <div className="flex justify-between items-center mt-20">
            <div className="font-bold uppercase text-lg text-center">
              Quiz Joining Bots Probability
            </div>
            <TooltipATM
              dataTooltipId={"version-tooltip"}
              dataTooltipPlace={"left"}
              dataTooltipHtml={renderToStaticMarkup(
                <p>
                  This is the bot's quiz joining probability. This specified
                  percentage value denotes how many spots (users) need to be
                  filled out of the total quiz spots, after which the remaining
                  bots will join. If the required number of spots is not filled,
                  the bots will not join the quiz, and it will be cancelled.
                </p>
              )}
              className={" cursor-pointer"}
            />
          </div>
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    {tableHeaders2?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-center"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b " key={rateArray.id}>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.id}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>
                          {rateArray.userThresholdForBotJoin} % Users Joined
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.updateDate}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>
                          {" "}
                          <div className="flex gap-x-3 justify-center">
                            <div
                              className={`hover:bg-gray-100 p-2 rounded-full ${
                                !checkAccess("QUIZBOTPROBABILITY", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                              onClick={() => {
                                if (checkAccess("QUIZBOTPROBABILITY", "U")) {
                                  // setOpenUpdateQuizBotsProbabibilityModal(true);
                                  setOpenUpdateBotsJoiningProbabilityModal(
                                    true
                                  );
                                }
                              }}
                            >
                              <MdModeEditOutline className="text-2xl" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
      {openUpdateQuizBotsProbabibilityModal && (
        <PasswordModal
          heading={"Update Quiz Bots Probability"}
          nameLabel={"Enter Winning Probability"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={onSubmit}
          openModal={openUpdateQuizBotsProbabibilityModal}
          setOpenModal={setOpenUpdateQuizBotsProbabibilityModal}
          flage={"quizWinningBotsProbability"}
          prevValue={`${rateArray.probabilityInPercent}`}
          data={botData}
        />
      )}
      {openUpdateBotsJoiningProbabilityModal && (
        <PasswordModal
          heading={"Update Bots Joining Probability"}
          nameLabel={"Enter Joining Probability"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={JoiningBotsOnSubmit}
          openModal={openUpdateBotsJoiningProbabilityModal}
          setOpenModal={setOpenUpdateBotsJoiningProbabilityModal}
          flage={"quizBotsProbability"}
          prevValue={`${rateArray.userThresholdForBotJoin}`}
        />
      )}

      <ReactTooltip
        id="version-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
         />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    quizBotsProbability: state?.quizBotsProbabilityReducer,
    updateQuizBotsProbability: state?.updateQuizBotsProbabilityReducer,
  };
};

export default connect(mapStateToProps)(QuizBotsProbability);
