import React, { useState } from "react";
import { Select } from "../select/SelectNew";
import { Input } from "../constant";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";

const AddVeersionModal = ({ setShowModal, callSubmit, prefillData }) => {
  const [showInitial, setShowInitial] = useState(false);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: async () => {
      return {
        id: prefillData?.id,
        latestVersion: prefillData?.latestVersion,
        forceUpdateEnabled: prefillData?.forceUpdateEnabled,
        maintenanceModeEnabled:prefillData?.maintenanceModeEnabled,
        deviceType: prefillData?.deviceType,
      };
    },
  });
  const { register, setValue, getValues, reset } = form;
  const dropdownOptions = [
    { id: "", name: "Select" },
    { id: "IOS", name: "IOS" },
    { id: "ANDROID", name: "Android" },
  ];

  const dropdownOptions1 = [
    { id: "", name: "Select" },
    { id: true, name: "Yes" },
    { id: false, name: "No" },
  ];
  const handleSortValues = (id, value) => {
    if (id === "deviceType") {
      setValue("deviceType", value);
    }
    if (id === "forceUpdate") {
      setValue("forceUpdateEnabled", value);
    }
    if (id === "maintenanceUpdate") {
      setValue("maintenanceModeEnabled", value);
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center p-4 overflow-y-auto"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
    >
      <div className="relative w-full max-w-2xl mx-auto">
        <div className="relative bg-white rounded-lg shadow">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
            data-modal-hide="popup-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 pt-12">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <div className="w-full">
                <Select
                  id="deviceType"
                  header="Device Type"
                  options={dropdownOptions}
                  callbackFn={handleSortValues}
                  showInitial={true}
                  initialValue={
                    prefillData?.deviceType === "ANDROID" ? "Android" : "IOS"
                  }
                />
              </div>
              <div className="w-full">
                <Select
                  id="forceUpdate"
                  header="Force Update"
                  options={dropdownOptions1}
                  callbackFn={handleSortValues}
                  showInitial={true}
                  initialValue={prefillData?.forceUpdateEnabled ? "Yes" : "No"}
                />
              </div>
              <div className="w-full">
                <Select
                  id="maintenanceUpdate"
                  header="Maintenance Update"
                  options={dropdownOptions1}
                  callbackFn={handleSortValues}
                  showInitial={true}
                  initialValue={
                    prefillData?.maintenanceModeEnabled ? "Yes" : "No"
                  }
                />
              </div>
            </div>
            <div className="px-2 md:px-6 mb-6">
              <Input
                type="text"
                name="latestVersion"
                label="Latest Version"
                register={register}
              />
            </div>
            <div className="flex flex-col-reverse sm:flex-row sm:justify-between gap-4 p-4 md:p-6">
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                data-modal-hide="popup-modal"
                type="button"
                className="w-full sm:w-auto text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  callSubmit({ ...getValues() });
                  setShowInitial(true);
                  setShowModal(false);
                }}
                data-modal-hide="popup-modal"
                type="button"
                className="w-full sm:w-auto text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVeersionModal;