import {
  GET_POPUPMESSAGE_DATA_FAILURE,
  GET_POPUPMESSAGE_DATA_REQUEST,
  GET_POPUPMESSAGE_DATA_SUCCESS,
} from "../../Utils/Constants";

export const getPopUpMessageDataRequest = (payload) => {
  return {
    type: GET_POPUPMESSAGE_DATA_REQUEST,
    payload: payload,
  };
};
export const getPopUpMessageDataSuccess = (data) => {
  return {
    type: GET_POPUPMESSAGE_DATA_SUCCESS,
    payload: data,
  };
};
export const getPopUpMessageDataFailure = (error) => {
  return {
    type: GET_POPUPMESSAGE_DATA_FAILURE,
    payload: error,
  };
};
