import {
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAILURE,
  } from "../../Utils/Constants";
  
  export const updateUserRequest = (payload) => {
      return {
          type: UPDATE_USER_REQUEST,
          payload: payload
      }
  }
  
  export const updateUserSuccess = (data) => {
      return {
          type: UPDATE_USER_SUCCESS,
          payload: data
      }
  }
  
  export const updateUserFailure = (error) => {
      return {
          type: UPDATE_USER_FAILURE,
          payload: error
      }
  }

  export const updateUserPasswordRequest = (payload) => {
    return {
        type: UPDATE_USER_PASSWORD_REQUEST,
        payload: payload
    }
}

export const updateUserPasswordSuccess = (data) => {
    return {
        type: UPDATE_USER_PASSWORD_SUCCESS,
        payload: data
    }
}

export const updateUserPasswordFailure = (error) => {
    return {
        type: UPDATE_USER_PASSWORD_FAILURE,
        payload: error
    }
}
