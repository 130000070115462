import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Pagination } from "../Pagination";
import { Select } from "../select/Select";
import { Search } from "../Search";
import { MdRefresh } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Button } from "../ui-elements/Button";
import { getAssigneeRequiest, updateContactUsRequest } from "../../Actions";

const DescriptionModal = ({
  data,
  heading,
  setOpenModal,
  flage,
  token,
  assignee,
  id,
  assigneId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showFilterArea, setShowFilterArea] = useState(true);
  const [sortValue, setSortValue] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInitial, setShowInitial] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [assigneeData, setAssigneeData] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [selectAssigneeId, setSelectAssigneeId] = useState(assigneId);
  const [note,updateNoteText] = useState(data)

  const timerRef = useRef(null);

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const getAssigneName = () => {
    const body = {
      token: token,
      pageNo: currentPage,
    };
    dispatch(getAssigneeRequiest(body));
  };

  const tableHeaders = [
    "id",
    "name",
    "Countery Code",
    "phone",
    "email",
    "Role",
  ];

  if (assigneId) {
    tableHeaders.push("Action");
  } else if (selectedId) {
    tableHeaders.push("Action");
  }

  const dropdownOptions = [
    {
      id: "",
      name: "All",
    },
    {
      id: "ADMIN",
      name: "Admin",
    },
    {
      id: "CUSTOMER_SUPPORT",
      name: "Customer Support",
    },
    {
      id: "MARKETING_REPRESENTATIVE",
      name: "Marketing Representative",
    },
    {
      id: "FINANCE",
      name: "Fainance",
    },
  ];

  const statusOptions = [
    {
      id: "",
      name: "Select",
    },
    {
      id: "IN_PROGRESS",
      name: "In Progress",
    },
    {
      id: "UNDER_REVIEW",
      name: "Under Review",
    },
    {
      id: "COMPLETED",
      name: "Completed",
    },
  ];

  useEffect(() => {
    if (flage === "Assign") {
      getAssigneName();
    }
  }, [currentPage]);

  useEffect(() => {
    if (assignee?.loading) {
      setIsLoading(true);
    }
    if (assignee?.error) {
      setIsLoading(false);
    }

    if (assignee?.assignee?.data?.assignee) {
      setAssigneeData(assignee?.assignee?.data?.assignee);
      setIsLoading(false);
    }

    if (searchValue && assignee.data) {
      setAssigneeData([assignee.data]);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (assignee?.data?.statusCode === 404 ||
        assignee?.assignee?.data?.statusCode === 400)
    ) {
      setAssigneeData([]);
      setIsLoading(false);
    }

    if (assignee?.assignee?.data?.totalPages) {
      setTotalPages(assignee?.assignee?.data?.totalPages);
    }
    setIsSubmittingFilter(false);
    // eslint-disable-next-line
  }, [assignee]);

  const handleSubmitFilter = () => {
    setIsSubmittingFilter(true);
    const params = {
      token,
      pageNo: currentPage,
      sortValue,
    };
    const body = {
      ...params,
      ...(sortValue && { sortValue }),
    };
    dispatch(getAssigneeRequiest(body));
    setShowInitial(false);
    setIsSubmittingFilter(false);
  };

  const handleSortValues = (id, value) => {
    if (id === "dropdownOptions") {
      setSortValue(value);
    } else if (id === "statusOptions") {
      setStatusValue(value);
    }
  };

  const handleCheckboxChange = (item) => {
    if (assigneId !== null) {
      setSelectAssigneeId((prevId) => (prevId === item.id ? null : item.id));
    } else {
      setSelectedId((prevId) => (prevId === item.id ? null : item.id));
    }
  };

  const searchByAssigneName = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
    }
    const body = {
      token,
      searchValue: searchParam || event?.target.value,
      pageNo: currentPage,
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getAssigneeRequiest(body));
    }, 10);
  };

  const handleClearFilter = () => {
    const body = {
      token,
      pageNo: currentPage,
    };
    dispatch(getAssigneeRequiest(body));
    setSortValue("");
    setShowInitial(true);
  };

  const updateAssignee = (assigneeId) => {
    const params = {
      token: token,
      assigneeId: assigneeId,
      id: id,
      statusValue,
    };

    const body = {
      ...params,
      ...(statusValue && { statusValue }),
    };

    dispatch(updateContactUsRequest(body));
  };

  const updateStatus = () => {
    const params = {
      token: token,
      id: id,
      statusValue,
    };

    const body = {
      ...params,
      ...(statusValue && { statusValue }),
    };

    dispatch(updateContactUsRequest(body));
  };
  const updateNotes =()=>
  {
    const params = {
      token: token,
      id: id,
     note
    };

    const body = {
      ...params,
      ...(note && { note }),
    };
    dispatch(updateContactUsRequest(body));
      // setOpenModal(false);
  }

  if (isLoading) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-5">Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed h-[100vh] w-full top-0 right-0 z-50 flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
    >
      <div tabIndex="-1" aria-hidden="true">
        <div
          className={` ${
            flage === "Assign"
              ? "relative  shadow-md sm:rounded-lg md:w-[900px] mt-3 sm:w-[420px]"
              : " relative w-[300px] sm:w-[420px]"
          }`}
        >
          <div
            className={`relative bg-white rounded-lg ${
              flage === "Assign" ? " h-[43rem]" : ""
            } shadow`}
          >
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
              data-modal-hide="authentication-modal"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-3 md:mb-4 text-xl font-medium text-gray-900">
                {heading}
              </h3>
              {flage === "status" && (
                <div className="flex gap-3 h-[20vh]">
                  <div>
                    <Select
                      id="statusOptions"
                      options={statusOptions}
                      callbackFn={handleSortValues}
                      showInitial={showInitial}
                    />
                  </div>
                  <div className="mt-2">
                    <button
                      className="text-green-600 border p-2 rounded-lg hover:bg-green-200 font-bold"
                      onClick={() => updateStatus()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              {flage === "Assign" ? (
                <>
                  <div className="bg-[#e8f5e9] h-[39em] rounded-lg px-2 md:px-14 pt-4 md:pt-20 flex flex-col gap-3">
                    <div className="bg-white p-4 shadow-md rounded-lg">
                      <div className="flex justify-between items-center">
                        <div className="font-bold uppercase text-lg text-center">
                          Assign
                        </div>

                        {screenWidth < 768 && (
                          <div onClick={handleFilterAreaView}>
                            <GiHamburgerMenu className="text-2xl" />
                          </div>
                        )}
                      </div>
                      {showFilterArea && (
                        <div className="flex flex-col md:flex-row justify-between md:items-center mt-3">
                          <div className="flex flex-col md:flex-row gap-2 md:items-center">
                            <Select
                              id="dropdownOptions"
                              header="Filter By"
                              options={dropdownOptions}
                              callbackFn={handleSortValues}
                              showInitial={showInitial}
                            />
                          </div>
                          <div className="flex md:gap-2 md:self-end justify-between mt-3">
                            <span onClick={handleFilterAreaView}>
                              <Button
                                callbackFn={handleSubmitFilter}
                                displayName={"Submit"}
                                type={"submit"}
                                isLoading={isSubmittingFilter}
                              />
                            </span>
                            <span onClick={handleFilterAreaView}>
                              <Button
                                callbackFn={handleClearFilter}
                                displayName={"Reset"}
                                type={"reset"}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="bg-white p-4 shadow-md rounded-lg h-[25rem] gap-2">
                      <div className="flex justify-between flex-wrap">
                        <button onClick={getAssigneName}>
                          <MdRefresh className="w-6 h-6 mt-2" />
                        </button>

                        <div className="flex justify-end gap-2">
                          <Search
                            label={`Category`}
                            placeholder={"Search by name"}
                            name={"Search"}
                            searchCallbackfn={searchByAssigneName}
                            isSearching={isLoading}
                          />
                        </div>
                      </div>
                      {assigneeData.length ? (
                        <>
                          <div className="relative overflow-x-auto h-[17rem] shadow-md sm:rounded-lg mt-3">
                            <table className="w-full text-sm text-left text-gray-700 ">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                  {tableHeaders?.map((item, index) => (
                                    <th
                                      key={index}
                                      scope="col"
                                      className="px-6 py-3 text-center"
                                    >
                                      {item}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {assigneeData?.map((item, index) => (
                                  <tr
                                    className="bg-white border-b "
                                    key={index}
                                  >
                                    <td className="px-6 py-2 text-center">
                                      <div className="flex gap-2">
                                        <div>
                                          <input
                                            type="checkbox"
                                            onChange={() =>
                                              handleCheckboxChange(item)
                                            }
                                            checked={
                                              assigneId !== null
                                                ? selectAssigneeId === item.id
                                                : selectedId === item.id
                                            }
                                          />
                                        </div>
                                        <div>{item.id}</div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-2 text-center">
                                      <div>
                                        <div>{item.name}</div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-2 text-center">
                                      {item.countryCode}
                                    </td>
                                    <td className="px-6 py-2 text-center">
                                      <div>
                                        <div>{item.mobile}</div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-2 text-center">
                                      <div>
                                        <div>{item.emailId}</div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-2 text-center">
                                      <div>{item.role}</div>
                                    </td>
                                    {((assigneId !== null &&
                                      selectAssigneeId === item.id) ||
                                      selectedId === item.id) && (
                                      <>
                                        {/* <td className="px-6 py-2 text-center">
                                          <Select
                                            id="statusOptions"
                                            options={statusOptions}
                                            callbackFn={handleSortValues}
                                            showInitial={showInitial}
                                          />
                                        </td> */}
                                        <td className="px-6 py-2 text-center">
                                          <button
                                            className="text-green-600 border p-2 rounded-lg hover:bg-green-200 font-bold"
                                            onClick={() =>
                                              updateAssignee(item.id)
                                            }
                                          >
                                            Confirm
                                          </button>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {totalPages > 1 && !searchValue && (
                            <div className="flex justify-end pr-5 pt-2">
                              <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                isLoading={isLoading}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="font-semibold text-center pt-24">
                          No Assign found !!!
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {flage === "notes" ? (
                    <div className="flex w-full gap-3 h-[10vh] items-center justify-center">
                      <div className=" w-full">
                        <textarea
                          onChange={(e) => updateNoteText(e.target.value)}
                          className=" py-2 focus:border-blue-300 rounded-md border-2 outline-none   w-full px-3"
                          placeholder="Enter Note"
                          value={note}
                        />
                      </div>
                      <div className="mt-1">
                        <button
                          className="text-green-600 border p-2 rounded-lg hover:bg-green-200 font-bold"
                          onClick={() => updateNotes()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : flage === "status" ? (
                    ""
                  ) : (
                    <p>{data}</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionModal;
