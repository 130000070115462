import React, { useState, useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import {
  getTopicsByCategoryIdRequest,
  CategoriesRequest,
  createQuestionRequest,
  updateQuestionRequest,
} from "../../Actions";
import { SECRET_KEY } from "../../Utils/Constants";
import { style } from "../../components/constant";
import { QuestionForm, SelectDropdown } from "../../layout";

import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { GiTruce } from "react-icons/gi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../../components/ui-elements/TooltipATM";

function AddQuestion(props) {
  const location = useLocation();
  const { item, returnPage, returnSearch } = location.state || {};
  const dispatch = useDispatch();
  const [topicId, setTopicId] = useState();
  const [categoryId, setCategoryId] = useState();
  const [questionLavel, setQuestionLavel] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [questionType, setQuestionType] = useState(null);
  const [isReload, setReload] = useState(false);
  const [flag, setFlag] = useState(true);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const navigate = useNavigate();

  const { topics } = props?.TopicsByCategoryId?.topics;
  const { categories } = props?.allCategoriesProp?.AllCategories || [];

  const categoryOptions = categories?.map((item) => ({
    id: item.id,
    name: item.nameEng,
  }));

  const TopicsOptions = topics?.map((item) => ({
    id: item.id,
    name: item.nameEng,
  }));

  const form = useForm({
    defaultValues: async () => {
      return {
        descriptionEn: item?.descriptionEn?.text,
        descriptionHi: item?.descriptionHi?.text,
        optionsEn: {
          A: item?.optionsEn?.A?.text,
          B: item?.optionsEn?.B?.text,
          C: item?.optionsEn?.C?.text,
          D: item?.optionsEn?.D?.text,
        },
        optionsHi: {
          A: item?.optionsHi?.A?.text,
          B: item?.optionsHi?.B?.text,
          C: item?.optionsHi?.C?.text,
          D: item?.optionsHi?.D?.text,
        },
        answerEn: {
          option: item?.answerEn?.option,
        },
        answerHi: {
          option: item?.answerHi?.option,
        },
      };
    },
  });

  const { register, handleSubmit, setValue, control, watch, reset } = form;
  const { error } = useFormState({ control });

  useEffect(() => {
    dispatch(CategoriesRequest({ token }));
    // eslint-disable-next-line
  }, []);

  const [isImage, setIsImage] = useState(false);

  const handleSortValues = (id, value) => {
    if (id === "categoryOptions") {
      getTopic(value);
    } else if (id === "topicOptions") {
      setTopicId(value);
    } else if (id === "levelOptions") {
      setQuestionLavel(value);
    } else if (id === "questionType") {
      setQuestionType(
        (value === "true" && true) || (value === "false" && false)
      );
    }
  };

  const getTopic = (cat) => {
    setCategoryId(cat);
    const body = {
      token: token,
      categoryId: cat,
    };
    dispatch(getTopicsByCategoryIdRequest(body));
  };

  const onSubmit = async (data) => {
    let keys = Object.keys(data.optionsEn);
    let English;
    let Hindi;
    if (
      // data.answerEn.option === data.answerHi.option &&
      data.answerHi.option ||
      data.answerHi.option
    ) {
      if (questionLavel && topicId) {
        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] === data.answerEn.option ||
            keys[i] === data.answerHi.option
          ) {
            setValue("answerEn.answer", data.optionsEn[data.answerEn.option]);
            setValue("answerHi.answer", data.optionsEn[data.answerHi.option]);
            English = data.optionsEn[data.answerEn.option];
            Hindi = data.optionsEn[data.answerHi.option];
          }
        }

        if (questionType === null) {
          toast.info("Please select the question type");
          return;
        }

        const requestDto = item?.id
          ? {
              id: item?.id,
              questionEn: data.descriptionEn,
              questionHi: data.descriptionHi,
              optionAEn: data.optionsEn.A,
              optionAHi: data.optionsHi.A,
              optionBEn: data.optionsEn.B,
              optionBHi: data.optionsHi.B,
              optionCEn: data.optionsEn.C,
              optionCHi: data.optionsHi.C,
              optionDEn: data.optionsEn.D,
              optionDHi: data.optionsHi.D,
              correctOption: data.answerEn.option,
              level: questionLavel,
              isFree: questionType,
              topicId: topicId,
              categoryId: categoryId,
            }
          : {
              questionEn: data.descriptionEn,
              questionHi: data.descriptionHi,
              optionAEn: data.optionsEn.A,
              optionAHi: data.optionsHi.A,
              optionBEn: data.optionsEn.B,
              optionBHi: data.optionsHi.B,
              optionCEn: data.optionsEn.C,
              optionCHi: data.optionsHi.C,
              optionDEn: data.optionsEn.D,
              optionDHi: data.optionsHi.D,
              correctOption: data.answerEn.option,
              level: questionLavel,
              isFree: questionType,
              topicId: topicId,
              categoryId: categoryId,
            };

        // Step 1: Prepare JSON Blob for requestDto
        const jsonString = JSON.stringify(requestDto);
        const blob = new Blob([jsonString], { type: "application/json" });

        // Step 2: Construct FormData
        const formData = new FormData();
        formData.append("requestDto", blob);

        const questionEnFile = document.querySelector("#imageInput")?.files[0];
        if (questionEnFile) {
          formData.append("questionEnFile", questionEnFile);
        } else {
          console.warn("Image not selected or input not found.");
        }

        const optionFiles = ["A", "B", "C", "D"];
        optionFiles.forEach((option) => {
          const fileInput = document.querySelector(`#file-input-${option}`);
          if (fileInput?.files[0]) {
            formData.append(`option${option}EnImage`, fileInput.files[0]);
          }
        });

        // Step 4: Debug FormData Content (Readable Output)
        // for (let [key, value] of formData.entries()) {
        //   // console.log(`${key}:`, value);
        // }

        // Step 5: Create Final Payload
        const body = {
          token: token,
          data: formData,
        };
        // Debug Final Payload
        setFlag(false);

        if (item?.id) {
          dispatch(updateQuestionRequest(body));
          // reset();
          setTimeout(() => {
            history("/questionPool" ,{ state: {
              item,
              returnPage,
              returnSearch, 
            }},);
          }, 2500);
        } else {
          dispatch(createQuestionRequest(body));
          reset();
          localStorage.setItem("qcurrentPage", "1");
          localStorage.setItem("qscrollPosition", "0");
          setTimeout(() => {
            history("/questionPool");
          }, 2500);
        }
      } else if (!questionLavel && !topicId) {
        toast.info("Please select the Topic and Question level.");
      } else if (!topicId) {
        toast.info("Please select the Topic.");
      } else if (!questionLavel) {
        toast.info("Please select the Question level.");
      }
    } else {
      toast.info("Please Select Correct Answer.");
    }
  };

  useEffect(() => {
    if (item?.topicId && item?.level) {
      setTopicId(item?.topicId);
      setQuestionLavel(item?.level);
    }
    if (item?.isImagePresentInOptions) {
      setIsImage(true);
    } else {
      setIsImage(false);
    }
    if (item?.isFree) {
      setQuestionType(true);
    } else {
      setQuestionType(false);
    }
  }, [item]);

  useEffect(() => {
    if (isReload) {
      window.location.reload();
      setReload(false);
    }
    // eslint-disable-next-line
  }, [props?.newQuiestion]);

  const history = useNavigate();

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <div className="flex justify-between">
            <div>
              <div className="flex justify-start cursor-pointer">
                <span
                  onClick={() => navigate(-1)}
                  className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border-blue-400 inline-flex items-center justify-center"
                >
                  <MdArrowBack className="text-base" /> Back
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="font-bold uppercase text-lg text-center">
              {item?.id ? "Edit Question Form" : "Add Questions Form"}
              {/* Add Questions Form */}
            </div>
          </div>
          <form className="space-y-6 mt-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 mb-8 sm:grid-cols-2 lg:grid-cols-4">
              <SelectDropdown
                categories={categoryOptions}
                topics={TopicsOptions}
                handleSortValues={handleSortValues}
                item={item}
              />
            </div>

            <div className="space-y-6">
              <QuestionForm
                register={register}
                watch={watch}
                setValue={setValue}
                error={error}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                flag={flag}
                setFlag={setFlag}
                isImage={isImage}
                setIsImage={setIsImage}
                item={item}
              />
            </div>

            <button type="submit" className={style.buttonClass}>
              {/* change */}
              <span className=" md:inline-block">{"Save Question"}</span>
            </button>
          </form>
        </div>
      </div>

       <ReactTooltip
        id="add-question-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
         />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    TopicsByCategoryId: state?.TopicByCategoryIdReducer,
    allCategoriesProp: state?.AllCategoriesReducer,
    newQuiestion: state?.questionsReducer,
  };
};

export default connect(mapStateToProps)(AddQuestion);