import {
  UPDATE_SOUND_REQUEST,
  UPDATE_SOUND_SUCCESS,
  UPDATE_SOUND_FAILURE,
} from "../../Utils/Constants";

export const updateSoundRequest  = (payload) => {
  return {
    type: UPDATE_SOUND_REQUEST,
    payload: payload,
  };
};

export const updateSoundSuccess = (data) => {
  return {
    type: UPDATE_SOUND_SUCCESS,
    payload: data,
  };
};

export const updateSoundFailure = (error) => {
  return {
    type: UPDATE_SOUND_FAILURE,
    payload: error,
  };
};
