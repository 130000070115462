import React from "react";
import { MdModeEditOutline } from "react-icons/md";

const ReviewTimeTable = ({
  headers,
  rateArray,
  field,
  setOpenModal,
  checkAccess,
}) => {
  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
        <table className="w-full text-sm text-left text-gray-700 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers?.map((item, index) => (
                <th key={index} scope="col" className="px-6 py-3 text-center">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b " key={rateArray?.id}>
              <td className="px-6 py-2 text-center">
                <div>
                  <div>{rateArray?.id}</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center">
                <div>
                  <div>
                    {` ${String(Math.floor(Number(rateArray[field]) / 60)).padStart(
                      2,
                      "0"
                    )}m : ${String(Number(rateArray[field] % 60)).padStart(2, "0")}s `}
                  </div>
                </div>
              </td>
              <td className="px-6 py-2 text-center">
                <div>
                  <div>{rateArray?.updateDate}</div>
                </div>
              </td>
              <td className="px-6 py-2 text-center">
                <div>
                  <div>
                    {" "}
                    <div className="flex gap-x-3 justify-center">
                      <div
                        className={`hover:bg-gray-100 p-2 rounded-full ${
                          !checkAccess("REVIEWTIME", "U")
                            ? "accessDenied"
                            : "hover:cursor-pointer"
                        }`}
                        onClick={() => {
                          if (checkAccess("REVIEWTIME", "U")) {
                            setOpenModal(true);
                          }
                        }}
                      >
                        <MdModeEditOutline className="text-2xl" />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReviewTimeTable;
