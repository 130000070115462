import React, { useEffect, useState } from "react";
import "./Select.css";
import TooltipATM from "../ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const ModalOptionsSelector = ({
  id,
  header,
  initialValue,
  options,
  callbackFn,
  content = "",
  place = "top",
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // 768px is typically the breakpoint for tablets
    };

    // Set initial value
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    callbackFn(id, value);
  };

  const handleOptionSelect = (optionId) => {
    setSelectedValue(optionId);
    setIsDropdownOpen(false);
    callbackFn(id, optionId);
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 md:mb-2 "
      >
        {content !== "" ? (
          <div className="flex flex-row justify-start align-middle gap-1">
            {header}
            <div className="my-auto">
              <TooltipATM
                dataTooltipId={"model-tooltip"}
                dataTooltipPlace={place}
                dataTooltipHtml={content}
                className={"cursor-pointer"}
              />
            </div>
          </div>
        ) : (
          header
        )}
      </label>

      {/* Desktop/Tablet - Native Select */}
      {!isMobile && (
        <select
          id={id}
          onChange={handleSelectChange}
          value={selectedValue}
          className="focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mb-1 md:mb-2 font-medium bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 sm:w-full"
        >
          {initialValue && (
            <option
              value=""
              defaultValue={initialValue}
              className="sm:text-sm text-[#000000]"
            >
              {initialValue}
            </option>
          )}
          {options?.map((option, index) => (
            <option
              value={option.id}
              key={index}
              className="custom-option text-[#000000]"
            >
              {option.name}
            </option>
          ))}
        </select>
      )}

      {/* Mobile - Custom Dropdown */}
      {isMobile && (
        <div className="relative">
          <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mb-1 md:mb-2 font-medium bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 sm:w-full cursor-pointer"
          >
            {options?.find(opt => opt.id === selectedValue)?.name || initialValue || "Select an option"}
          </div>
          
          {isDropdownOpen && (
            <ul className="absolute left-0 top-full w-full bg-white border border-gray-300 shadow-lg rounded-lg mt-1 z-10 max-h-60 overflow-y-auto">
              {initialValue && (
                <li
                  className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-[#000000]"
                  onClick={() => handleOptionSelect("")}
                >
                  {initialValue}
                </li>
              )}
              {options?.map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-[#000000]"
                  onClick={() => handleOptionSelect(option.id)}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      <ReactTooltip
        id="model-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </div>
  );
};