import React from "react";
import {
  MdOutlineKeyboardDoubleArrowDown,
  MdOutlineKeyboardDoubleArrowUp,
} from "react-icons/md";
import GreenLoader from "../Loaders/GreenLoader";
import { formatNumber } from "../../Utils/numberConversion";
import TooltipATM from "../ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

const DashboardCard1 = ({
  title,
  value,
  percentage,
  loading,
  dataPrefernce,
  placeTooltip,
  contentTooltip,
}) => {
  function getComparisonString(selectedValue) {
    switch (selectedValue) {
      case "DAILY":
        return "Compare to last Day";
      case "WEEKLY":
        return "Compare to last Week";
      case "MONTHLY":
        return "Compare to last Month";
      case "YEARLY":
        return "Compare to last Year";
      default:
        return "Invalid selection";
    }
  }

  return (
    <div className=" border mb-2 max-w-[9.5rem] shadow-md rounded-lg p-2 flex-grow">
      <div className="text-sm text-gray-500 font-semibold">
        <div className="flex flex-row gap-0 justify-start align-middle text-center align-content-center">
          {title}
          <div className="my-auto ml-1">
            <TooltipATM
              dataTooltipId={"card1-tooltip"}
              dataTooltipPlace={placeTooltip}
              dataTooltipHtml={contentTooltip}
              className={"cursor-pointer"}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="min-h-12 flex items-center">
          <GreenLoader />
        </div>
      ) : (
        <div className="text-[2.1rem] px-2 font-bold">
          {formatNumber(value)}
        </div>
      )}
      <div className="flex gap-2">
        <div
          className={`flex items-center px-2 text-[0.8rem] ${
            percentage > 0 ? "text-green-600" : "text-red-600"
          } rounded-full`}
        >
          {percentage > 0 ? (
            <MdOutlineKeyboardDoubleArrowUp className="text-xl font-bold  text-green-600" />
          ) : (
            <MdOutlineKeyboardDoubleArrowDown className="text-xl font-bold text-red-600" />
          )}
          <div className="my-1">
            {percentage > 0 ? percentage : percentage * -1}
          </div>
        </div>
      </div>
      <div className="text-[0.7rem] my-1 text-gray-500">
        {getComparisonString(dataPrefernce)}
      </div>
      <ReactTooltip
        id="card1-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50 ibutton"
      />
    </div>
  );
};

export default DashboardCard1;
