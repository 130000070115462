import React, { useState, useMemo } from "react";
import { capitalizeWords } from "../../Utils/capitalizeFn";
import { Pagination } from "../../components/Pagination";
import Loading from "../../components/loading";
import { useNavigate } from "react-router";

const tableHeaders = ["Scenario", "Total Impressions", "Action"];

const DashboardTable = ({ data, loading }) => {
  const navigate = useNavigate();
  // Pagination
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  }, [data, currentPage]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
      <table className="w-full text-sm text-left text-gray-700">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index} scope="col" className="px-6 py-3 text-start">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((scenarioData, index) => (
            <tr className="bg-white border-b" key={index}>
              <td className="px-6 py-2 text-start">
                {capitalizeWords(scenarioData.scenario_name)}
              </td>
              <td className="px-6 py-2 text-start">{scenarioData.count}</td>
              <td className="px-6 py-2 text-start">
                <div
                  className="text-blue-500 cursor-pointer"
                  onClick={() => {
                    navigate("/dashboardAnalytics", {
                      state: { scenario_name: scenarioData.scenario_name },
                    });
                  }}
                >
                  View Details
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div className="flex justify-end pr-5 pt-4 mb-2">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardTable;
