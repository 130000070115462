import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { SECRET_KEY } from "../../Utils/Constants";
import CryptoJS from "crypto-js";
import { getUrlString } from "../../components/Config/appConfig";
import { toast } from "react-toastify";
import { MdContentCopy } from "react-icons/md";
import Loading from "../../components/loading";
import { TbServerOff } from "react-icons/tb";
import { useNavigate } from "react-router";
import { BiError } from "react-icons/bi";
import { getServerInfoRequest } from "../../Actions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { renderToStaticMarkup } from "react-dom/server";

const ServerIP = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const serverInfo = useSelector((state) => state.serverInfoReducer);
  const [sessionExpired, setSessionExpired] = useState(false);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const [ServerList, setServerList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(serverInfo?.loading);
    if (serverInfo) {
      setServerList(serverInfo?.reviewTime?.data);
    }
  }, [serverInfo]);

  // const tableHeaders = ["Private IP", "Public IP"];
  const tableHeaders = ["Public IP"];

  const getData = () => {
    dispatch(getServerInfoRequest(token));
  };
  useEffect(() => {
    getData();
    // getServerInformation();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (sessionExpired) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-20">
        <div className="fixed inset-0  bg-black opacity-80 backdrop-blur-sm" />
        {/* md:ml-60 */}
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative flex flex-col items-center ">
          <BiError className="text-red-600 text-[3rem] my-2" />
          <h2 className="text-xl font-bold mb-3 text-gray-800">
            Access Rights Changed
          </h2>
          <p className="mb-6 text-gray-600 text-sm text-center">
            Your access credentials have been updated. For security reasons,
            please log out and then log back in to start your session.
          </p>
          <button
            className="bg-[#4CAF50] text-white py-2 px-12 rounded-lg shadow-md hover:bg-[#378039] focus:outline-none focus:ring-2 focus:ring-[#378039]"
            onClick={() => (window.location.href = "/login")}
          >
            Log out
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] h-[85vh] relative ">
          <div className="flex flex-row justify-center sm:justify-between items-center gap-2 pb-3">
            <div className="font-bold uppercase text-lg text-center select-none">
              Server Info
            </div>
            <TooltipATM
              dataTooltipId={"publicIp-tooltip"}
              dataTooltipPlace={"left"}
              dataTooltipHtml={renderToStaticMarkup(
                <p>
                  This is the current IP address of the backend server.It should
                  be whitelisted on Razor Payouts for withdrawals to work
                </p>
              )}
              className={" cursor-pointer"}
            />
          </div>

          {!ServerList?.length ? (
            <>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-700">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 select-none">
                    <tr>
                      {tableHeaders.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-center justify-center items-center flex"
                        >
                          <span className=" flex gap-2 items-center self-center text-center">
                            {item}
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b">
                      {/* <td className="px-6 py-2 text-center select-none">
                        {ServerList?.privateIp}
                      </td> */}
                      <td className="px-6 py-3 text-center">
                        <div className="flex justify-center items-center gap-2">
                          {ServerList?.publicIp}
                          {ServerList?.publicIp && (
                            <MdContentCopy
                              className="text-[1rem] font-bold cursor-pointer"
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(ServerList?.publicIp)
                                  .then(() => {
                                    toast.success("Copied to clipboard!");
                                  })
                                  .catch((err) => {
                                    toast.error("Failed to copy: ", err);
                                  });
                              }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="text-center flex flex-col justify-center items-center pt-36">
              <TbServerOff className="text-[2rem] font-bold  m-2" />
              Server Not Found
            </div>
          )}
        </div>
      </div>

      <ReactTooltip
        id="publicIp-toolt
        ip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ServerIP);
