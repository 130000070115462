import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { getSoundDataRequest, updateSoundRequest } from "../../Actions";
import Loading from "../../components/loading";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { renderToStaticMarkup } from "react-dom/server";
import { capitalizeWords } from "../../Utils/capitalizeFn";

const tableHeaders = [
  {name:"Id"},
  { name: "Scenario" },
  {
    name: "Active",
    msg: renderToStaticMarkup(
      <p>
        If disabled, the Sound will not be generated for the specific scenario.
        To stop providing Sounds, disable the scenario instead of setting the
        minimum and maximum amount to 0.
      </p>
    ),
  },
];

const SoundManagement = ({ soundData, updateSoundData }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [soundDataList, setSoundDataList] = useState([]);
  const [isAllEnabled, setIsAllEnabled] = useState(false);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const getSoundData = () => {
    const body = {
      token: token,
    };
    dispatch(getSoundDataRequest(body));
  };

  useEffect(() => {
    getSoundData();
  }, [updateSoundData]);

  useEffect(() => {
    if (soundData?.loading) {
      setIsLoading(true);
    }

    if (soundData?.error) {
      setIsLoading(false);
    }

    if (soundData?.soundData?.data) {
      setSoundDataList(soundData?.soundData?.data?.appAudiosList);
      setIsLoading(false);
      setIsAllEnabled(soundData?.soundData?.data?.canDisableAll);
    }
  }, [soundData]);

  const handleActiveToggleChange = (itm) => {
    const body = {
      data: {
        id: itm.id,
        isEnabled: !itm?.isEnabled,
      },
      token: token,
    };

    dispatch(updateSoundRequest(body));
  };

  const handleActiveToggleAllChange = () => {
    const newState = !isAllEnabled;
    const body = {
      data: {
        disableAll: !newState,
      },
      token: token,
    };

    dispatch(updateSoundRequest(body));
    setIsAllEnabled(newState);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4 rounded-lg">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 md:gap-0">
            <div className="flex justify-start gap-1 items-center">
              <div className="font-bold uppercase text-lg text-center">
                Sound Management
              </div>
              <TooltipATM
                dataTooltipId={"Sound-tooltip"}
                dataTooltipPlace={"right"}
                dataTooltipHtml={
                  "Lists all the scenarios where users will receive Sounds. New scenarios can only be added from the backend, not from the admin panel."
                }
                className={"cursor-pointer"}
              />
            </div>
            <div
              onClick={handleActiveToggleAllChange}
              className="flex justify-center"
            >
              {isAllEnabled ? (
                <span className="cursor-pointer border-2 px-4 py-2 rounded-lg border-red-300 hover:bg-red-50 text-red-500">
                  Disable All
                </span>
              ) : (
                <span className="cursor-pointer border-2 rounded-lg px-4 py-2 border-green-300 text-green-500 hover:bg-green-50">
                  Enable All
                </span>
              )}
            </div>
          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
            <table className="w-full text-sm text-left text-gray-700">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  {tableHeaders?.map((item, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-4 py-3 text-center whitespace-nowrap"
                    >
                      <div className="flex gap-1 items-center justify-center">
                        {item.name}
                        {item?.msg && (
                          <TooltipATM
                            dataTooltipId={"Sound-tooltip"}
                            dataTooltipPlace={"top-end"}
                            dataTooltipHtml={item?.msg}
                            className={"cursor-pointer"}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {soundDataList?.map((item, index) => {
                  return (
                    <tr className="bg-white border-b" key={index}>
                    <td className="px-4 py-3 text-center">
                        <div>
                          <div>{item?.id}</div>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-center">
                        <div>
                          <div>{capitalizeWords(item?.scenarioName)}</div>
                        </div>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <div className="">
                          <div onClick={() => handleActiveToggleChange(item)}>
                            {item?.isEnabled ? (
                              <span className="cursor-pointer border-2 rounded-lg px-4 py-2 border-green-300 text-green-500 hover:bg-green-50">
                                Enabled
                              </span>
                            ) : (
                              <span className="cursor-pointer border-2 px-4 py-2 rounded-lg border-red-300 hover:bg-red-50 text-red-500">
                                Disabled
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ReactTooltip
        id="Sound-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    soundData: state.getSoundDataReducer,
    updateSoundData: state?.updateSoundReducer,
  };
};

export default connect(mapStateToProps)(SoundManagement);