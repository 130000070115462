import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { TbServerOff } from "react-icons/tb";
import { MdArrowBack } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getManageRequest, postManageRequest } from "../../Actions";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import Loading from "../../components/loading";
import { encrypt } from "../../Utils/RoleBasedRoute";

const settingTabheader = ["module name", "read", "create", "update", "delete"];

const AssignAccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  // gather redux data
  const userAccessArray = useSelector((state) => state?.manageAccessReducer);
  const updateUserserAccessArray = useSelector(
    (state) => state?.manageAccessPutReducer
  );
  const [userAccess, setUserAccess] = useState([]);
  const [loading, setLoading] = useState(false);

  // token decrypt
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const getAccessData = () => {
    dispatch(getManageRequest({ token, id: state?.id }));
  };

  useEffect(() => {
    getAccessData();
  }, [state, updateUserserAccessArray]);

  useEffect(() => {
    if (userAccessArray?.manageAccess?.statusCode === 200) {
      setUserAccess(userAccessArray?.manageAccess?.data);
    }
    setLoading(userAccessArray?.loading);
  }, [userAccessArray]);

  // * BELOW  FUNCTION HANDLE
  // * if read false make all access of that object false

    const handleCheckboxChange = (index, action) => {
    const updatedUserAccess = userAccess.map((access, i) => {
      if (i === index) {
        // if the action is "read" and its new value is false
        if (action === "read" && access.readKey) {
          return {
            ...access,
            readKey: false,
            updateKey: false,
            deleteKey: false,
            createKey: false,
          };
        }
        return {
          ...access,
          [`${action}Key`]: !access[`${action}Key`],
        };
      }

      if (index === 3) {
        if (access.moduleName === "TOPIC") {
          return { ...access, readKey: true };
        }
        if (access.moduleName === "CATEGORY") {
          return { ...access, readKey: true };
        }
      }
      return access;
    });
    setUserAccess(updatedUserAccess);
  };

  
  const handleSubmit = async () => {
    dispatch(postManageRequest({ token, data: userAccess }));
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div
      className={`bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3`}
    >
      <div className="bg-white p-4 shadow-md rounded-lg min-h-[85vh] gap-2">
        <div className="justify-between items-center">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 mb-3 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
          <div className="font-bold uppercase text-lg text-start">
            {`${state?.name} (${state?.role})`}
          </div>
        </div>

        {userAccess?.length ? (
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    {settingTabheader.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3 text-start"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {userAccess?.map((item, index) => (
                    <tr className="bg-white text-black border-b " key={index}>
                      <>
                        <td className="px-3 py-2 font-semibold capitalize text-start">
                          {item?.moduleName}
                        </td>
                        <td className="px-3 py-2 text-start">
                          <input
                            type="checkbox"
                            checked={item?.readKey}
                            onChange={() => handleCheckboxChange(index, "read")}
                            className="focus:outline-none focus:ring-0 active:outline-none active:ring-0"
                          />
                        </td>
                        <td className="px-3 py-2 text-start">
                          <input
                            type="checkbox"
                            checked={item?.createKey}
                            onChange={() =>
                              handleCheckboxChange(index, "create")
                            }
                          />
                        </td>
                        <td className="px-3 py-2 text-start">
                          <input
                            type="checkbox"
                            checked={item?.updateKey}
                            onChange={() =>
                              handleCheckboxChange(index, "update")
                            }
                          />
                        </td>
                        <td className="px-3 py-2 text-start">
                          <input
                            type="checkbox"
                            checked={item?.deleteKey}
                            onChange={() =>
                              handleCheckboxChange(index, "delete")
                            }
                          />
                        </td>
                      </>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end mt-4">
              <div className="gap-4 w-max">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="px-8 py-1 border-2 rounded-sm mx-2"
                >
                  <span className="md:inline-block">{"Cancel"}</span>
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="px-8 py-1 text-white border-2 border-[#4CAE50] rounded-sm bg-[#4CAE50] mx-2 hover:bg-[#3c893f]"
                >
                  <span className="md:inline-block">{"Save"}</span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center flex flex-col justify-center items-center pt-36">
            <TbServerOff className="text-[2rem] font-bold m-2" />
            Modules Not Found
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignAccess;
