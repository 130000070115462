import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { SpinnerLoader } from "../Loaders/SpinnerLoader";
import { toast } from "react-toastify";
import { Select } from "../select/Select";
import { style } from "../constant";
import { useForm } from "react-hook-form";
import {
  sendNotificationRequest,
  sendSheduledNotificationRequest,
} from "../../Actions";
import DatePicker from "react-datepicker";

const notification = [
  {
    id: "",
    name: "Select",
  },
  {
    id: "PUSH",
    name: "Push Notification",
  },
  {
    id: "EMAIL",
    name: "Email Notification",
  },
];
const NotificationTypeOption = [
  {
    id: "",
    name: "Select",
  },
  {
    id: "WITH_NAME",
    name: "With Name",
  },
  {
    id: "WITHOUT_NAME",
    name: "Without Name",
  },
];
const scheduleNotification = [
  {
    id: false,
    name: "No",
  },
  {
    id: true,
    name: "Yes",
  },
];

export const SendNotification = ({
  heading,
  setOpenModal,
  userType,
  token,
  dispatch,
  SendNotification,
  sendSheduledNotification,
  place,
  content
}) => {
  const [showInitial, setShowInitial] = useState(false);
  const [isSchedule, setIsSchedule] = useState(true);
  const [scheduleTime, setScheduleTime] = useState();
  const [notificationType, setNotificationType] = useState("");
  const [pushNotificationType, setPushNotificationType] = useState("");
  const [notificationUserType, setNotificationUserType] = useState("ALL");
  const [dateFocus, setDateFocus] = useState(false);
  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const onSubmit = async (data) => {
    if (!notificationType || !notificationUserType || !pushNotificationType) {
      toast.error("Please select both Notification type and Users type");
      return;
    }
    // Convert date to the required format "dd-MM-yyyy HH:mm:ss"
    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const formattedSendAt = scheduleTime ? formatDate(scheduleTime) : "";

    const body = {
      data: {
        ...data,
        notificationType,
        notificationUserType,
        pushNotificationType: pushNotificationType,
      },
      token: token,
    };
    const scheduleBody = {
      data: {
        ...data,
        notificationType,
        notificationUserType,
        pushNotificationType: pushNotificationType,
        sendAt: formattedSendAt,
      },
      token: token,
    };

    try {
      let response;

      if (isSchedule) {
        response = await dispatch(sendNotificationRequest(body));
      } else {
        response = await dispatch(
          sendSheduledNotificationRequest(scheduleBody)
        );
      }
      // if (
      //   response?.payload?.statusCode === 200 ||
      //   response?.payload?.statusCode === 201
      // ) {
      setTimeout(() => {
        setOpenModal(false);
      }, 2000);
      // }
    } catch (error) {
    } finally {
      setShowInitial(true);
      setNotificationUserType("ALL");
      setNotificationType("");
      setPushNotificationType("");
    }
  };

  useEffect(() => {
    if (!SendNotification.loading || !sendSheduledNotification.loading) {
      setShowInitial(true);
      reset();
    }
  }, [SendNotification.loading, sendSheduledNotification.loading]);
  

  const handleSortValues = (id, value) => {
    if (id === "notificationType") {
      setNotificationType(value);
    } else if (id === "usersType") {
      setNotificationUserType(value);
    } else if (id === "pushNotificationType") {
      setPushNotificationType(value);
    }
  };

  const handleScheduleMSG = (id, value) => {
    if (value === "false") {
      setScheduleTime("");
    }
    setIsSchedule(value !== "true" ? true : false);
  };

  const handleTimeChange = (date) => {
    setScheduleTime(date);

  }; 

  const handleDateFocus = () => {
    setDateFocus(true);
  };

  return (
    <div
      className="fixed h-[100vh] w-full top-0 right-0 z-50 flex overflow-auto justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
    >
      <div tabIndex="-1">
        <div className="relative md:w-[900px] w-[400px]">
          <div className="relative bg-white rounded-lg shadow ">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
              data-modal-hide="authentication-modal"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <svg
                className="w-3 h-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-3 py-6 lg:px-8">
              <h3 className="mb-3 md:mb-4 text-xl font-medium text-gray-900 ">
                {heading}
              </h3>
              <form
                className="border-1 pt-3 md:p-0 p-2 border-gray-100 shadow-md rounded-2xl"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="w-full flex flex-col items-center">
                  <div className="flex flex-wrap gap-4 md:w-[70%] w-full">
                    <Select
                      id="usersType"
                      header="Users type"
                      options={userType}
                      callbackFn={handleSortValues}
                      showInitial={showInitial}
                    />
                    <Select
                      id="notificationType"
                      header="Notification type"
                      options={notification}
                      callbackFn={handleSortValues}
                      showInitial={showInitial}
                    />
                    <Select
                      id="pushNotificationType"
                      header="Push Notification type"
                      options={NotificationTypeOption}
                      callbackFn={handleSortValues}
                      showInitial={showInitial}
                      disabled={notificationType !== "PUSH"}
                    />
                  </div>

                  <div className="w-full flex justify-center">
                    <div className="md:w-[70%] w-full flex my-3 text-sm rounded-lg gap-4">
                      <div>
                        <Select
                          id="scheduleNotification"
                          header="Schedule Notification"
                          options={scheduleNotification}
                          callbackFn={handleScheduleMSG}
                          showInitial={showInitial}
                          content="If 'No' is selected, the notification will be sent immediately."
                          place="top"
                        />
                      </div>
                      <div className="w-full">
                        <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                          {"Schedule Time"}
                        </label>
                        <DatePicker
                          selected={scheduleTime}
                          onChange={handleTimeChange}
                          showTimeSelect
                          required
                          disabled={isSchedule}
                          dateFormat="dd-MM-yyyy HH:mm:ss"
                          minDate={new Date()}
                          className="custom-date-picker bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2 font-medium"
                          onKeyDown={(e) => e.preventDefault()}
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-center">
                    <textarea
                      {...register("title", { required: true })}
                      className="md:w-[70%] w-full my-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block p-2.5 "
                      placeholder="Title"
                    />
                  </div>
                  <div className="w-full flex justify-center">
                    <textarea
                      {...register("body", { required: true })}
                      className="md:w-[70%] w-full h-[6rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block p-2.5"
                      placeholder="Body"
                    />
                  </div>
                </div>
                <div className="flex my-3 justify-center flex-wrap">
                  <button type="submit" className={style.buttonClass}>
                    {SendNotification && sendSheduledNotification.loading ? (
                      <SpinnerLoader size={"sm"} />
                    ) : (
                      <span className=" md:inline-block">{"Submit"}</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
