import CryptoJS from "crypto-js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { updateUserRequest } from "../../../Actions";
import { MdArrowBack } from "react-icons/md";
import { Input } from "../../../components/constant";
import { useForm } from "react-hook-form";
import { SECRET_KEY } from "../../../Utils/Constants";
import { updateUserPasswordRequest } from "../../../Actions/User/updateUserAction";

const EditAdminUser = () => {
  const form = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const item = location.state;
  const [name, setName] = useState(item.name);
  const [mobile, setMobile] = useState(item.mobile);
  const [emailId, setEmailId] = useState(item.emailId);
  const [password, setPassword] = useState(item.password);
  const [showPassword, setShowPassword] = useState(false);
  const encrypted = sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const oldPassword= item.password;
  const { register, handleSubmit } = form;
  const onSubmit = (data) => {
    const body = {
      ...data,
      token: token,
      id: item.id,
    };
    dispatch(updateUserRequest(body));

if(oldPassword!==password){
    const passwordbody={
      token:token,
      newPassword:password,
      userId:item.id
    }    
    dispatch(updateUserPasswordRequest(passwordbody));
  }
    navigate(-1);
  };

  const totalWin = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "mobile") {
      setMobile(value);
    } else if (name === "emailId") {
      setEmailId(value);
    }else if(name==="password"){
      setPassword(value);
    }
  };
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
      <div className="bg-white p-4 shadow-md rounded-lg">
        <div className="flex justify-start cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
          <div className="font-bold uppercase text-lg text-center">
            Update User
          </div>
        </div>
        <form
          className="space-y-6 mt-4 my-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid gap-4 mb-4 sm:grid-cols-1 lg:grid-cols-2">
            <div className="">
              <Input
                type="text"
                name="name"
                label="Enter Full Name"
                register={register}
                required
                value={name}
                totalWinnings={totalWin}
              />
            </div>
            <div className="">
              <Input
                type="text"
                name="mobile"
                label="Enter Mobile Number"
                register={register}
                required
                value={mobile}
                totalWinnings={totalWin}
              />
            </div>
            <div>
              <Input
                type="email"
                name="emailId"
                label="Enter Email ID"
                register={register}
                required
                value={emailId}
                totalWinnings={totalWin}
              />
            </div>

      <div className="py-2" data-show={showPassword}>
              <div className="relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label="Enter Passcode *"
                  register={register}
                  required
                  value={password}
                totalWinnings={totalWin}
                />
                <div className="absolute inset-y-0 mt-4  right-0  pr-5 flex items-center text-sm leading-5">
                  <svg
                    className="h-6 text-gray-700 cursor-pointer"
                    fill="none"
                    onClick={togglePasswordVisibility}
                    style={{ display: showPassword ? "block" : "none" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                    ></path>
                  </svg>
                  <svg
                    className="h-6 text-gray-700 cursor-pointer"
                    fill="none"
                    onClick={togglePasswordVisibility}
                    style={{ display: showPassword ? "none" : "block" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-auto text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            Update Player
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditAdminUser;
