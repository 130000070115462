import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { getReviewTimeRequest, updateReviewTimeRequest } from "../../Actions";
import { MdModeEditOutline } from "react-icons/md";
import { PasswordModal } from "../../components/modal/passwordModal";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import Loading from "../../components/loading";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import ReviewTimeTable from "../../components/table/ReviewTimeTable";
import { renderToStaticMarkup } from "react-dom/server";

const ReviewTime = ({ reviewTime, updateReviewTime }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [rateArray, setRateArray] = useState({});
  const [updateReviewTimeModel, setOpenUpdateReviewTimeModel] = useState(false);
  const [updateField, setUpdateField] = useState("reviewTime");
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const tableHeaders = ["Id", "Review Time", "Update Date", "Edit"];
  const tableHeadersimmi = [
    "Id",
    "Immediate Quiz Time",
    "Update Date",
    "Edit",
  ];

  const onSubmit = (item) => {
    const [minutes, seconds] = item.split(":");
    const duration =
      (!isNaN(Number(minutes)) ? Number(minutes) : 0) * 60 +
      (!isNaN(Number(seconds)) ? Number(seconds) : 0);

    const body = {
      data: {
        [updateField]: duration,
      },
      token: token,
    };
    dispatch(updateReviewTimeRequest(body));
  };

  const getReviewTime = () => {
    const body = {
      token: token,
    };
    dispatch(getReviewTimeRequest(body));
  };

  useEffect(() => {
    getReviewTime();
  }, [updateReviewTime]);

  useEffect(() => {
    if (
      reviewTime?.reviewTime?.statusCode === 403 ||
      updateReviewTime?.updateReviewTime?.statusCode === 403
    ) {
      window.location.href = "/";
    }
    if (reviewTime?.loading) {
      setIsLoading(true);
    }
    setTimeout(() => {
      if (reviewTime?.loading === false) {
        setFormOpen(false);
      }
    }, [1000]);

    if (reviewTime?.error) {
      setIsLoading(false);
    }

    if (reviewTime?.reviewTime?.data) {
      setRateArray(reviewTime.reviewTime.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [reviewTime, updateReviewTime]);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Review Time
            </div>
            <TooltipATM
              dataTooltipId={"review-tooltip"}
              dataTooltipPlace={"left"}
              dataTooltipHtml={renderToStaticMarkup(<p>
                After the quiz goes live and its duration ends, it will enter review mode, The quiz will remain in review mode for the duration specified here, in minutes
             </p>) }
              className={" cursor-pointer"}
            />
          </div>
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <ReviewTimeTable
                headers={tableHeaders}
                rateArray={rateArray}
                field="reviewTime"
                setOpenModal={() => {
                  setUpdateField("reviewTime");
                  setOpenUpdateReviewTimeModel(true);
                }}
                checkAccess={checkAccess}
              />
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <ReviewTimeTable
                headers={tableHeadersimmi}
                rateArray={rateArray}
                field="immediateQuizReviewTime"
                setOpenModal={() => {
                  setUpdateField("immediateQuizReviewTime");
                  setOpenUpdateReviewTimeModel(true);
                }}
                checkAccess={checkAccess}
              />
            </div>
          </>
        </div>
      </div>
      {updateReviewTimeModel && (
        <PasswordModal
          heading={ updateField==="immediateQuizReviewTime" ? "Update Immediate Quiz Time": "Update Review Time"}
          nameLabel={"Enter Time (mm:ss)"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={onSubmit}
          openModal={updateReviewTimeModel}
          setOpenModal={setOpenUpdateReviewTimeModel}
          prevValue={` ${String(
            Math.floor(Number(rateArray[updateField]) / 60)
          ).padStart(2, "0")} : ${String(
            Number(rateArray[updateField] % 60)
          ).padStart(2, "0")} `}
          flage={"review"}
        />
      )}
   
      <ReactTooltip
        id="review-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
   
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reviewTime: state?.reviewTimeReducer,
    updateReviewTime: state?.updateReviewTimeReducer,
  };
};

export default connect(mapStateToProps)(ReviewTime);
