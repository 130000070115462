import React, { isValidElement } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { renderToStaticMarkup } from "react-dom/server";
import "react-tooltip/dist/react-tooltip.css";

function TooltipATM({
  dataTooltipId,
  dataTooltipPlace = "top",
  dataTooltipContent,
  dataTooltipHtml,
  className,
  variant = "dark",
}) {
  const htmlContent = isValidElement(dataTooltipHtml)
    ? renderToStaticMarkup(dataTooltipHtml)
    : dataTooltipHtml;

  return (
    <div>
      <span
        data-tooltip-id={dataTooltipId}
        data-tooltip-place={dataTooltipPlace}
        data-tooltip-variant={variant}
        data-tooltip-delay-show={50}
        {...(htmlContent
          ? { "data-tooltip-html": htmlContent }
          : { "data-tooltip-content": dataTooltipContent })}
        className={`${className}`}
      >
        <IoMdInformationCircleOutline className="cursor-pointer" />
      </span>
    </div>
  );
}

export default TooltipATM;
