import {
    UPDATE_USER_ROLE_REQUEST,
    UPDATE_USER_ROLE_SUCCESS,
    UPDATE_USER_ROLE_FAILURE,
  } from "../../Utils/Constants";
  
  export const updateUserRoleRequest = (payload) => {
      return {
          type: UPDATE_USER_ROLE_REQUEST,
          payload: payload
      }
  }
  
  export const updateUserRoleSuccess = (data) => {
      return {
          type: UPDATE_USER_ROLE_SUCCESS,
          payload: data
      }
  }
  
  export const updateUserRoleFailure = (error) => {
      return {
          type: UPDATE_USER_ROLE_FAILURE,
          payload: error
      }
  }
