import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import {
  getWalletDataRequest,
  updateWithdrwaLimitRequest,
} from "../../Actions";

import Loading from "../../components/loading";
import { MdModeEditOutline } from "react-icons/md";
import { PasswordModal } from "../../components/modal/passwordModal";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { renderToStaticMarkup } from "react-dom/server";

const WalletManagement = ({ walletData, updateWalletData }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedItem, setClickedItem] = useState("");
  const [walletDataList, setWalletDataList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const onSubmit = (minAmount, maxAmount, isEnabled) => {
    const body = {
      data: {
        id: clickedItem?.id,
        minAmount: minAmount,
        maxAmount: maxAmount,
        isEnabled: isEnabled,
      },
      token: token,
    };

    dispatch(updateWithdrwaLimitRequest(body));
  };
  const getWalletData = () => {
    const body = {
      token: token,
    };
    dispatch(getWalletDataRequest(body));
  };

  useEffect(() => {
    getWalletData();
  }, [updateWalletData]);

  useEffect(() => {
    if (walletData?.loading) {
      setIsLoading(true);
    }

    if (walletData?.error) {
      setIsLoading(false);
    }

    if (walletData?.walletData?.data) {
      setWalletDataList(walletData?.walletData?.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [walletData]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  const tableHeaders = [
    { name: "Scenario" },
    { name: "Minimum Amount" },
    { name: "Maximum Amount " },
    {
      name: "Active",
      msg: renderToStaticMarkup(<p>Active</p>),
    },
    { name: "Action" },
  ];
  const handleEditClick = (item) => {
    let dataFormat = {
      id: item?.id,
      name: item?.minAmount,
      maxAmount: item?.maxAmount,
      isEnabled: item?.isEnabled,
    };
    setClickedItem(dataFormat);
    setEditModalOpen(true);
  };

  const handleActiveToggleChange = (itm) => {
    const body = {
      data: {
        id: itm?.id,
        minAmount: itm?.minAmount,
        maxAmount: itm?.maxAmount,
        isEnabled: !itm?.isEnabled,
      },
      token: token,
    };
    dispatch(updateWithdrwaLimitRequest(body));
  };

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4  rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="flex justify-start gap-1 items-center">
              <div className="font-bold uppercase text-lg text-center">
                Wallet Management
              </div>
              <TooltipATM
                dataTooltipId={"wallet-tooltip"}
                dataTooltipPlace={"right"}
                dataTooltipHtml={"Wallet Management"}
                className={" cursor-pointer"}
              />
            </div>
          </div>
          <>
            <div className="relative overflow-x-auto  shadow-md  sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-center"
                      >
                        <div className=" flex gap-1 items-center justify-center">
                          {item.name}
                          {item?.msg && (
                            <TooltipATM
                              dataTooltipId={"wallet-tooltip"}
                              dataTooltipPlace={"top-end"}
                              dataTooltipHtml={item?.msg}
                              className={"cursor-pointer"}
                            />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b ">
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>Set Withdraw Limit</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{walletDataList.minAmount} </div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{walletDataList?.maxAmount}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div className="">
                        <div
                          onClick={() =>
                            handleActiveToggleChange(walletDataList)
                          }
                        >
                          {walletDataList?.isEnabled ? (
                            <span className=" cursor-pointer border-2 rounded-lg px-4 py-2 border-green-300 text-green-500 hover:bg-green-50">
                              Enabled
                            </span>
                          ) : (
                            <span className=" cursor-pointer border-2 px-4 py-2 rounded-lg border-red-300 hover:bg-red-50 text-red-500">
                              Disabled
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center ">
                      <div className="flex gap-x-3 justify-center">
                        <div
                          onClick={() => handleEditClick(walletDataList)}
                          className={`hover:bg-gray-100 p-2 rounded-full cursor-pointer ${
                            //   !checkAccess("QUIZBOTPROBABILITY", "U")
                            //     ? "accessDenied"
                            //     : "hover:cursor-pointer"
                            ""
                          }`}
                        >
                          <MdModeEditOutline className="text-2xl" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
      {isEditModalOpen && (
        <PasswordModal
          heading={`Update Wallet Scenario`}
          nameLabel={"Enter Min Amount"}
          secondNameLable={"Enter Max Amount"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={onSubmit}
          openModal={isEditModalOpen}
          setOpenModal={setEditModalOpen}
          flage={"reward"}
          data={clickedItem}
          // prevValue={`${rateArray.userThresholdForBotJoin}`}
        />
      )}

      <ReactTooltip
        id="wallet-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    walletData: state.getWalletDataReducer,
    updateWalletData: state?.updateWalletReducer,
  };
};

export default connect(mapStateToProps)(WalletManagement);
