import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Search } from "../Search";
import ReadMoreLess from "../ui-elements/ReadMoreLess";
import { style } from "../constant";
import { MdDelete } from "react-icons/md";
import cashQuiz from "../../assets/images/cashQuiz.svg";
import freeQuiz from "../../assets/images/freeQuiz.svg";
import { FiUpload } from "react-icons/fi";
import { BiExport } from "react-icons/bi";
import { FaFileExport } from "react-icons/fa6";
import { TbServerOff } from "react-icons/tb";
import Loading from "../loading";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { assignQuestionRequest } from "../../Actions";
import { useDispatch, useSelector } from "react-redux";

export default function ManageQuestionTable(props) {
  const navigate = useNavigate();
  //   const ManageQuestionRes = useSelector((state) => state.AssignQuestionReducer);
  const {
    isLoading,
    searchQuizProp,
    itemsArray,
    setOpenDeleteModal,
    setDeleteData,
    searchByQuestionId,
    isSubmittingFilter,
    handleCheckboxChange,
    checkedBoxes,
    handleCheckboxId,
    setOpenDeleteQuestionModal,
    setDeleteQuestionData,
    setCheckedBoxes,
    searchValue,
    countOfQuestion,
    handleQuestionExport,
    userId,
    quizId,
    token,
    flag,
  } = props;

  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const dispatch = useDispatch();

  const handleCheckboxToggle = (id) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((questionId) => questionId !== id)
        : [...prevSelected, id]
    );
  };

  if (isLoading) {
    return <Loading noGap={true} />;
  }

  const handleSubmit = () => {
    let body = {
      token: token,
      data: {
        userId: userId,
        quizId: quizId,
        questionIds: selectedQuestions,
      },
    };
    dispatch(assignQuestionRequest(body));
  };

  return (
    <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
      <div className="md:hidden block text-left font-bold">
        Count: <span className=" font-normal ">{countOfQuestion}</span>
      </div>
      <div className="flex justify-between items-center">
        <div className="md:block hidden text-left font-bold uppercase">
          Count: <span className="font-bold">{countOfQuestion}</span>
        </div>
        <div className="flex justify-between gap-2">
          {flag == "view-questions" ? (
            ""
          ) : (
            <Search
              label={`Category`}
              placeholder={"Enter Question Id"}
              name={"Search"}
              searchCallbackfn={searchByQuestionId}
              isSearching={searchQuizProp?.loading}
              searchValue={searchValue}
            />
          )}

          <button
            disabled={!checkAccess("QUESTIONPOOL", "R")}
            onClick={() => {
              handleQuestionExport();
            }}
            className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 px-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
              !checkAccess("QUESTIONPOOL", "R")
                ? "accessDenied"
                : "hover:cursor-pointer"
            }`}
          >
            Export
            <FaFileExport />
          </button>
        </div>
      </div>
      {itemsArray?.length ? (
        <>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
            {itemsArray?.map((item, index) => (
              <div className="border-b py-2 shadow-md" key={index}>
                <div className="flex flex-row sm:flex-row items-center px-3 py-2 sm:py-4 gap-2 sm:gap-4 mt-2 ">
                  {flag === "view-questions" ? (
                    ""
                  ) : (
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxToggle(item?.id)}
                      checked={selectedQuestions.includes(item?.id)}
                    />
                  )}

                  <h5 className="">{`Question Id #${item?.id}`}</h5>
                  <h5>
                    <img
                      className="w-12"
                      src={item?.isFree ? freeQuiz : cashQuiz}
                      alt=""
                    />
                  </h5>
                  <div className="flex self-center bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                    <div className="">{item?.level}</div>
                  </div>
                </div>

                <div className="flex flex-wrap sm:flex-nowrap">
                  {/* English Section */}
                  {/* <div className="w-full sm:w-1/2 p-3">
                    <td className="px-6 py-2">
                      <ReadMoreLess
                        descriptionEn={item?.descriptionEn?.text}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">
                      A : {item?.optionsEn?.A?.text}
                    </h6>
                    <h6 className="px-6 py-2">
                      B : {item?.optionsEn?.B?.text}
                    </h6>
                    <h6 className="px-6 py-2">
                      C : {item?.optionsEn?.C?.text}
                    </h6>
                    <h6 className="px-6 py-2">
                      D : {item?.optionsEn?.D?.text}
                    </h6>
                    <h6 className="px-6 py-2">
                      {`Ans:- ${item.answerEn?.option}) ` +
                        item.answerEn?.answer}
                    </h6>
                  </div> */}
                  <div className="w-full sm:w-1/2 p-3">
                    <div className=" px-6 py-1">
                      <div>
                        {item?.descriptionEn?.fileUrl &&
                          item?.descriptionEn?.fileType && (
                            <div className="w-full mb-4">
                              {item.descriptionEn.fileType.startsWith(
                                "image/"
                              ) && (
                                <img
                                  className="w-full object-fit mb-3"
                                  src={item.descriptionEn.fileUrl}
                                  alt="Description Image"
                                />
                              )}
                              {item.descriptionEn.fileType.startsWith(
                                "video/"
                              ) && (
                                <video
                                  className="w-full object-fit mb-3"
                                  src={item.descriptionEn.fileUrl}
                                  controls
                                />
                              )}
                              {item.descriptionEn.fileType.startsWith(
                                "audio/"
                              ) && (
                                <audio
                                  className="w-full object-fit mb-3"
                                  src={item.descriptionEn.fileUrl}
                                  controls
                                />
                              )}
                              {!["image/", "video/", "audio/"].some((type) =>
                                item.descriptionEn.fileType.startsWith(type)
                              ) && (
                                <p className="text-red-500">
                                  Unsupported media format
                                </p>
                              )}
                            </div>
                          )}
                      </div>

                      <h6 className="font-normal">
                        {" "}
                        <span className=" font-semibold">Q.</span>{" "}
                        {item?.descriptionEn?.text}
                      </h6>

                      <div className="h-1/2 flex flex-col justify-start gap-4 mt-4">
                        {item.optionsEn?.A?.imageUrl ||
                        item.optionsEn?.B?.imageUrl ||
                        item.optionsEn?.C?.imageUrl ||
                        item.optionsEn?.D?.imageUrl ? (
                          <div>
                            {/* A and B */}
                            <div className="flex gap-4 mb-4">
                              {["A", "B"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsEn?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsEn?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsEn?.[option]?.text}
                                  </h6>
                                </div>
                              ))}
                            </div>
                            {/* C and D */}
                            <div className="flex gap-4">
                              {["C", "D"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsEn?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsEn?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsEn?.[option]?.text}
                                  </h6>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ["A", "B", "C", "D"].map((option) => (
                            <div key={option} className="w-full ">
                              <h6 className=" text-sm font-semibold text-gray-800">
                                <span>{`${option}. `}</span>
                                {item.optionsEn?.[option]?.text}
                              </h6>
                            </div>
                          ))
                        )}
                      </div>
                      <h6 className="mt-2 font-semibold text-black text-sm">
                        {"Ans:- " +
                          item?.answerEn?.option +
                          ") " +
                          item?.answerEn?.answer}
                      </h6>
                    </div>
                  </div>

                  {/* Hindi Section */}
                  <div className="w-full sm:w-1/2 p-3">
                    <div className=" px-6 py-1">
                      <div>
                        {item?.descriptionHi?.fileUrl &&
                          item?.descriptionHi?.fileType && (
                            <div className="w-full mb-4">
                              {item.descriptionHi.fileType.startsWith(
                                "image/"
                              ) && (
                                <img
                                  className="w-full object-fit mb-3"
                                  src={item.descriptionHi.fileUrl}
                                  alt="Description Image"
                                />
                              )}
                              {item.descriptionHi.fileType.startsWith(
                                "video/"
                              ) && (
                                <video
                                  className="w-full object-fit mb-3"
                                  src={item.descriptionHi.fileUrl}
                                  controls
                                />
                              )}
                              {item.descriptionHi.fileType.startsWith(
                                "audio/"
                              ) && (
                                <audio
                                  className="w-full object-fit mb-3"
                                  src={item.descriptionHi.fileUrl}
                                  controls
                                />
                              )}
                              {!["image/", "video/", "audio/"].some((type) =>
                                item.descriptionHi.fileType.startsWith(type)
                              ) && (
                                <p className="text-red-500">
                                  Unsupported media format
                                </p>
                              )}
                            </div>
                          )}
                      </div>

                      <h6 className="font-normal">
                        {" "}
                        <span className=" font-semibold pr-1">Q.</span>
                        {item?.descriptionHi?.text}
                      </h6>

                      <div className="h-1/2 flex flex-col justify-start gap-4 mt-4">
                        {item.optionsHi?.A?.imageUrl ||
                        item.optionsHi?.B?.imageUrl ||
                        item.optionsHi?.C?.imageUrl ||
                        item.optionsHi?.D?.imageUrl ? (
                          <div>
                            {/* A and B */}
                            <div className="flex gap-4 mb-4">
                              {["A", "B"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsHi?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsHi?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsHi?.[option]?.text}
                                  </h6>
                                </div>
                              ))}
                            </div>
                            {/* C and D */}
                            <div className="flex gap-4">
                              {["C", "D"].map((option) => (
                                <div key={option} className="w-1/2">
                                  <h6 className="mt-2 text-sm font-medium text-gray-800">
                                    <span>{`${option}. `}</span>
                                    {item.optionsHi?.[option]?.imageUrl && (
                                      <img
                                        className="inline-block w-30 h-30 mx-2 object-fit"
                                        src={item.optionsHi?.[option]?.imageUrl}
                                        alt={`Option ${option}`}
                                      />
                                    )}
                                    {item.optionsHi?.[option]?.text}
                                  </h6>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ["A", "B", "C", "D"].map((option) => (
                            <div key={option} className="w-full">
                              <h6 className="text-sm font-semibold text-gray-800">
                                <span>{`${option}. `}</span>
                                {item.optionsHi?.[option]?.text}
                              </h6>
                            </div>
                          ))
                        )}
                      </div>
                      <h6 className="mt-2 text-black text-sm font-semibold">
                        {"Ans:- " +
                          item?.answerHi?.option +
                          ") " +
                          item?.answerHi?.answer}
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 flex-wrap mb-3 px-6">
                  <div className="flex flex-col sm:flex-row gap-2">
                    <div className="bg-blue-500 text-white px-3 py-2 rounded-md text-center sm:text-sm">
                      {item?.category?.nameEng}
                    </div>
                    <div className="bg-yellow-500 text-white px-3 py-2 rounded-md text-center sm:text-sm">
                      {item?.topic?.nameEng}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {flag != "view-questions" && (
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleSubmit}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
              >
                Submit
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="text-center flex flex-col justify-center items-center pt-36">
          <TbServerOff className="text-[2rem] font-bold  m-2" />
          Questions Not Found
        </div>
      )}
    </div>
  );
}
