import { GiHamburgerMenu } from "react-icons/gi";
import { Select } from "../../components/select/SelectNew";
import { Button } from "../../components/ui-elements/Button";
import { useEffect, useRef, useState } from "react";
import { Search } from "../../components/Search";
import { useLocation, useNavigate } from "react-router";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdModeEditOutline, MdRefresh } from "react-icons/md";
import { SpinnerLoader } from "../../components/Loaders/SpinnerLoader";
import avtar from "../../assets/images/download.png";
import { DeleteModal } from "../../components/modal/DeleteModal";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { connect, useDispatch } from "react-redux";
import {
  deletePlayerRequest,
  getPlayerAllRequest,
  resetPlayerPasswordRequest,
} from "../../Actions";
import { Pagination } from "../../components/Pagination";
import password from "../../assets/images/password.png";
import { PasswordModal } from "../../components/modal/passwordModal";
import activity from "../../assets/images/arrows.png";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import { getUserByIdEmpty } from "../../Actions/User/getUserByIdAction";
import { TbServerOff } from "react-icons/tb";
import Loading from "../../components/loading";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import DateRangePicker from "../../components/DateRangePicker";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

const UserScreen = ({ allUser, deletePlayer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const [sortValue, setSortValue] = useState("");
  const [sortValue, setSortValue] = useState(
    localStorage.getItem("sortValue") || ""
  );
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSortOrder, setIsSortOrder] = useState(
    localStorage.getItem("isSortOrder") || true
  );
  const [showInitial, setShowInitial] = useState(false);
  const [showFilterArea, setShowFilterArea] = useState(window.innerWidth >= 768);
  const [searchValue, setSearchValue] = useState("");
  // const [searchValue, setSearchValue] = useState(
  //   localStorage.getItem("searchValue") || ""
  // );
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportDate, setExportDate] = useState({
    startDate: localStorage.getItem("playerStartDate") || "",
    endDate: localStorage.getItem("playerEndDate") || "",
  });
  // const [currentPage, setCurrentPage] = useState(
  //   parseInt(localStorage.getItem("currentPage")) || 1
  // );

  // const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openDeleteCUserModal, setOpenDeleteCUserModal] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [userArray, setUserArray] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [fullPic, setFullPic] = useState("");
  const [userId, setUserId] = useState("");
  const timerRef = useRef(null);
  const [resetDateRange, setResetDateRange] = useState(false);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const toggleFullscreen = (profilePic) => {
    setFullscreen(!fullscreen);
    setFullPic(profilePic);
  };

  const handleClose = () => {
    setFullscreen(false);
    setFullPic(null);
  };

  const dropdownOptions = [
    { id: "", name: "Recent" },
    { id: "mostQuizPlayed", name: "Most Quizzes played" },
    { id: "wallet", name: "Wallet balance" },
    { id: "mostQuizWon", name: "Most quizzes won" },
    { id: "createDate", name: "Date of Joining" },
  ];

  const sortOrder = [
    { id: true, name: "Ascending Order" },
    { id: false, name: "Descending Order" },
  ];

  const tableHeaders = [
    "Image",
    "ID",
    "Name",
    "E-mail",
    "Phone",
    "wallet balance",
    "Details",
    "Actions",
  ];

  const settingTabheader = [
    "id",
    "Full Name",
    "Email",
    "last login",
    "user type",
    "reset password",
  ];

  const getUser = (obj) => {
    const body = {
      pageNo: currentPage,
      token: token,
      sortValue: sortValue,
      isSortOrder: isSortOrder,
      searchBy: searchValue,
      filterBy: obj?.filterBy,
      startDate: exportDate.startDate,
      endDate: exportDate.endDate,
    };
    dispatch(getPlayerAllRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    resetQuestionLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  const resetFilterInLocal = () => {
    localStorage.removeItem("sortValue");
    localStorage.removeItem("isSortOrder");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("userDrop");
    localStorage.removeItem("playerStartDate");
    localStorage.removeItem("playerEndDate");
    localStorage.removeItem("dateRange-filterPlayerDate");
    setCurrentPage(1);
  };

  const refreshUserData = (obj) => {
    const body = {
      pageNo: currentPage,
      token: token,
      sortValue: sortValue,
      isSortOrder: isSortOrder,
      ...obj,
    };
    dispatch(getPlayerAllRequest(body));
    setShowInitial(true);
  };

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    const storedSortValue = localStorage.getItem("sortValue");
    const storedIsSortOrder = localStorage.getItem("isSortOrder");
    if (storedSortValue) {
      setSortValue(storedSortValue);
    }
    if (storedIsSortOrder) {
      setIsSortOrder(storedIsSortOrder === "true");
    }

    const storedPage = localStorage.getItem("currentPage");
    if (storedPage) {
      setCurrentPage(parseInt(storedPage, 10));
    }

    setExportDate({
      startDate: localStorage.getItem("playerStartDate"),
      endDate: localStorage.getItem("playerEndDate"),
    });

  }, []);

// Update the resize effect
useEffect(() => {
  const handleResize = () => {
    const newScreenWidth = window.innerWidth;
    setScreenWidth(newScreenWidth);
    // Automatically show filter area when screen is large enough
    if (newScreenWidth >= 768) {
      setShowFilterArea(true);
    }
  };
  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

  const searchByUser = (event, searchParam) => {
    if (event) {
      event.preventDefault();
      setSearchValue(event?.target.value);
      if (event?.target.value !== null) {
        localStorage.setItem("searchValue", event?.target.value);
      } else {
        localStorage.setItem("searchValue", "");
      }
    }
    let body = {};

    body = {
      token,
      pageNo: currentPage,
      searchBy: searchParam || event?.target.value,
      filterBy: "PLAYER",
    };
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      dispatch(getPlayerAllRequest(body));
    }, 10);
  };

  useEffect(() => {
    if (allUser?.loading) {
      setIsLoading(true);
    }

    if (allUser?.error) {
      setIsLoading(false);
    }

    // setIsSubmittingFilter(allUser?.loading);

    if (allUser?.allPlayer?.data?.users) {
      setUserArray(allUser?.allPlayer?.data?.users);
      setIsLoading(false);
    }

    if (searchValue && allUser?.allPlayer?.data) {
      setUserArray(allUser?.allPlayer?.data?.users);
      setIsLoading(false);
    }
    if (
      searchValue &&
      (allUser?.allPlayer?.data?.statusCode === 404 ||
        allUser?.allPlayer?.data?.statusCode === 400)
    ) {
      setUserArray([]);
      setIsLoading(false);
    }

    if (allUser?.allPlayer?.data?.totalPages) {
      setTotalPages(allUser?.allPlayer?.data?.totalPages);
    }
    if (allUser?.allPlayer?.statusCode === 403) {
      window.location.href = "/";
    }
    // eslint-disable-next-line
  }, [allUser]);

  useEffect(() => {
    getUser({ filterBy: "PLAYER" });
  }, [currentPage, deletePlayer]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchValue(localStorage.getItem("searchValue") || "");
  };

  const handleFilterAreaView = () => {
    if (screenWidth < 768) {
      setShowFilterArea((prev) => !prev);
    }
  };

  const dropDown = localStorage.getItem("userDrop");

  useEffect(() => {
    if (searchValue !== null) {
      localStorage.setItem("searchValue", searchValue);
    } else {
      localStorage.setItem("searchValue", "");
    }
  }, [searchValue]);

  useEffect(() => {
    const currentPageFromState = location.state?.currentPage;
    if (currentPageFromState) {
      setCurrentPage(currentPageFromState);
      localStorage.setItem("currentPage", currentPageFromState);
    }
  }, [location.state]);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  const handleSortValues = (id, value) => {
    setError1(false);
    if (id === "dropdownOptions") {
      setSortValue(value);
      localStorage.setItem("sortValue", value);
      localStorage.setItem("userDrop", true);
    }
    if (id === "sortOrder") {
      setIsSortOrder(value);
      localStorage.setItem("isSortOrder", value.toString());
    }
    if (id === "filterPlayerDate") {
      localStorage.setItem("playerStartDate", exportDate?.startDate);
      localStorage.setItem("playerEndDate", exportDate?.endDate);
    }
  };

  const handleSubmitFilter = () => {
    if (!sortValue || !isSortOrder || isSortOrder === "") {
      setError1(true);
      return;
    }
    // setIsSubmittingFilter(true);
    let params = {
      token,
      pageNo: currentPage,
      sortValue,
      isSortOrder,
      filterBy: "PLAYER",
      startDate: exportDate.startDate,
      endDate: exportDate.endDate,
    };
    const body = {
      ...params,
      ...(sortValue && { sortValue }),
      ...(isSortOrder && { isSortOrder }),
      ...(exportDate.startDate && { startDate: exportDate.startDate }),
      ...(exportDate.endDate && { endDate: exportDate.endDate }),
    };
    dispatch(getPlayerAllRequest(body));
    setShowInitial(false);
  };

  const handleClearFilter = () => {
    setResetDateRange(true);
    setError1(false);
    resetFilterInLocal();
    const body = {
      token,
      pageNo: currentPage,
      searchBy: searchValue,
      filterBy: "PLAYER",
    };
    dispatch(getPlayerAllRequest(body));
    setExportDate({ startDate: "", endDate: "" });
    setSortValue("");
    setIsSortOrder("");
    setShowInitial(true);
    setTimeout(() => setResetDateRange(false), 0);
  };
  // useEffect(() => {
  //   getUser();
  // }, [currentPage, exportDate]);

  const handleDeleteUser = (data) => {
    const body = {
      data: {
        id: data,
      },
      token: token,
    };
    dispatch(deletePlayerRequest(body));
  };

  const onSelect = (date, dateString) => {
    setExportDate({
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };

  const handleChangePassword = (newPassword) => {
    const body = {
      data: {
        userId: userId,
        newPassword: newPassword,
      },
      token: token,
    };

    dispatch(resetPlayerPasswordRequest(body));
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh]  px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] ">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={` bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3`}
      >
        <div className="bg-white  p-4 shadow-md rounded-lg">
          <div className="flex justify-between items-center">

          <div className="flex justify-start gap-1 items-center">
          <div className="font-bold uppercase text-lg text-center">
              Players
            </div>
            <TooltipATM
              dataTooltipId={"player-tooltip"}
              dataTooltipPlace={"right"}
              dataTooltipHtml={
                "Lists all the players."
              }
              className={" cursor-pointer"}
            />            
            </div>
            {screenWidth < 768 && (
              <div onClick={handleFilterAreaView}>
                <GiHamburgerMenu className="text-2xl" />
              </div>
            )}
          </div>
          {showFilterArea && (
            <div className="flex flex-col md:flex-row justify-between md:items-center mt-3 space-y-3 md:space-y-0 md:space-x-4">
              <div className="flex flex-col md:flex-row gap-2 md:items-center w-full md:w-auto">
                <Select
                  id="dropdownOptions"
                  header="Sort Players"
                  options={dropdownOptions}
                  callbackFn={handleSortValues}
                  showInitial={showInitial}
                  dropDown={dropDown}
                />
                <Select
                  id="sortOrder"
                  header="Sort Order"
                  initialValue="Select Sorting Order"
                  options={sortOrder}
                  showInitial={showInitial}
                  callbackFn={handleSortValues}
                  dropDown={dropDown}
                  error={error1}
                />
                <div className="w-full md:w-auto flex flex-col">
                  <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
                    Date
                  </label>
                  <DateRangePicker
                    onSelect={onSelect}
                    callbackFn={handleSortValues}
                    id="filterPlayerDate"
                    resetDateRange={resetDateRange}
                  />
                </div>
              </div>
              <div className="flex md:gap-2 md:self-end justify-between mt-3">
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={() => {
                      setCurrentPage(1);
                      handleSubmitFilter();
                    }}
                    displayName={"Submit"}
                    type={"submit"}
                  />
                </span>
                <span onClick={handleFilterAreaView}>
                  <Button
                    callbackFn={handleClearFilter}
                    displayName={"Reset"}
                    type={"reset"}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
          <div className="bg-white p-4 shadow-md rounded-lg min-h-[85vh] gap-2">
            <div className="flex justify-between flex-wrap">
              <div className="flex gap-4 items-center">
                <button
                  onClick={() => {
                    localStorage.removeItem("searchValue");
                    localStorage.removeItem("currentPage");
                    setSearchValue("");
                    setCurrentPage(1);
                    refreshUserData({ filterBy: "PLAYER" });
                  }}
                >
                  <MdRefresh className="w-6 h-6 mt-2" />
                </button>
                <div className="mt-2 font-bold uppercase">
                  Count: {allUser?.allPlayer?.data?.totalRecords}
                </div>
              </div>

              <div className="flex justify-end gap-2">
                <Search
                  label={`Category`}
                  placeholder={"Search by name, email, phone"}
                  name={"Search"}
                  searchCallbackfn={searchByUser}
                  isSearching={allUser?.loading}
                  searchValue={searchValue === null ? "" : searchValue}
                />

                <button
                  disabled={!checkAccess("PLAYER", "C")}
                  onClick={() => {
                    {
                      navigate(
                        {
                          pathname: "/createUser",
                        },
                        {
                          state: "Player",
                        }
                      );
                    }
                  }}
                  className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 py-1 px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                    !checkAccess("PLAYER", "C")
                      ? "accessDenied"
                      : "hover:cursor-pointer"
                  }`}
                >
                  Create Player
                  <FaPlus />
                </button>
              </div>
            </div>
            {userArray?.length ? (
              <>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
                  <table className="w-full text-sm text-left text-gray-700 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                      <tr>
                        {tableHeaders?.map((item, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="px-3 py-3 text-start"
                          >
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {userArray?.map((item, index) => (
                        <tr className="bg-white border-b " key={index}>
                          <>
                            <td
                              scope="row"
                              className="flex items-center px-3 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <img
                                className="w-10 h-10 rounded-full cursor-pointer"
                                src={item.profilePic ? item.profilePic : avtar}
                                alt="User Image"
                                onClick={() =>
                                  toggleFullscreen(item.profilePic)
                                }
                              />
                              {fullscreen && fullPic && (
                                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#fff] bg-opacity-75">
                                  <img
                                    className="max-w-full max-h-full cursor-pointer"
                                    src={fullPic}
                                    alt="Fullscreen Image"
                                  />
                                  <button
                                    type="button"
                                    className="absolute top-[100px] right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                                    data-modal-hide="authentication-modal"
                                    onClick={handleClose}
                                  >
                                    <svg
                                      className="w-3 h-3"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 14 14"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                      />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                  </button>
                                </div>
                              )}
                            </td>

                            <td className="px-3 py-2 text-start">
                              <div>
                                <div>{item.id}</div>
                              </div>
                            </td>
                            <td className="px-3 py-2 text-start">
                              <div>
                                <div>{item.name}</div>
                              </div>
                            </td>
                            <td className="px-3 py-2 text-start">
                              {item.emailId}
                            </td>
                            <td className="px-3 py-2 text-start">
                              <div>
                                <div>{item.mobile}</div>
                              </div>
                            </td>
                            <td className="px-3 py-2 text-start">
                              <div>
                                <div>₹{item.wallet}</div>
                              </div>
                            </td>
                            <td className="px-3 py-2 text-start">
                              <button
                                disabled={!checkAccess("PLAYER", "R")}
                                type="button"
                                onClick={() =>
                                  navigate(
                                    {
                                      pathname: "/viewPlayers",
                                    },
                                    {
                                      state: {
                                        ...item,
                                        currentPage: currentPage,
                                      },
                                    }
                                  )
                                }
                                className={`inline-flex items-center px-3 py-2 text-sm justify-start font-medium text-start text-blue-500 rounded-lg hover:bg-blue-100 focus:ring-4 focus:outline-none focus:ring-green-100 ${
                                  !checkAccess("PLAYER", "R")
                                    ? "accessDenied"
                                    : "hover:cursor-pointer"
                                }`}
                              >
                                <span className="hidden md:inline-block">
                                  View Details{" "}
                                </span>
                                <span className="inline-block md:hidden">
                                  Details{" "}
                                </span>
                              </button>
                            </td>
                            <td className="px-3 py-2 text-start">
                              <div className="flex gap-x-3 justify-start">
                                <div
                                  className={`hover:bg-gray-100 p-2 rounded-full ${
                                    !checkAccess("PLAYER", "U")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    if (checkAccess("PLAYER", "U")) {
                                      navigate(
                                        {
                                          pathname: "/updatePlayer",
                                        },
                                        { state: item }
                                      );
                                    }
                                  }}
                                >
                                  <MdModeEditOutline className="text-2xl" />
                                </div>
                                <div
                                  onClick={() => {
                                    if (checkAccess("PLAYER", "U")) {
                                      setDeleteUserData({
                                        name: `User with id ${item.id}`,
                                        id: item.id,
                                      });
                                      setOpenDeleteCUserModal(true);
                                      //   setSearchValue("");
                                    }
                                  }}
                                  className={`hover:bg-gray-100 p-2 rounded-full ${
                                    !checkAccess("PLAYER", "U")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                >
                                  <MdDelete className="text-2xl" />
                                </div>
                              </div>
                            </td>
                          </>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {totalPages > 1 && (
                  <div className="flex justify-end pr-5 pt-4">
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      setCurrentPage={handlePageChange}
                      isLoading={allUser?.loading}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="text-center flex flex-col justify-center items-center pt-36">
                <TbServerOff className="text-[2rem] font-bold m-2" />
                Users Not Found
              </div>
            )}
          </div>
      </div>

      {openDeleteCUserModal && (
        <DeleteModal
          data={deleteUserData}
          handleDelete={handleDeleteUser}
          setOpenDeleteModal={setOpenDeleteCUserModal}
        />
      )}

      {openResetPasswordModal && (
        <PasswordModal
          heading={"Change Passcode"}
          nameLabel={"New Passcode"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={handleChangePassword}
          openModal={openResetPasswordModal}
          setOpenModal={setOpenResetPasswordModal}
          secondNameLable={"Confirm Password"}
          flage={"resetPassword"}
        />
      )}
    
       <ReactTooltip
        id="player-tooltip"
        className="max-w-[90vw] md:max-w-[400px] py-2 px-3 md:p-3 text-white shadow-xs rounded-lg z-50"
        />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allUser: state?.getPlayerAllReducer,
    deletePlayer: state?.deletePlayerReducer,
  };
};

export default connect(mapStateToProps)(UserScreen);
