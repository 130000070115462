import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "./Screens/AdminLogin";
import { ProtectedRoutes } from "./Utils/ProtectedRoutes";
import { PageNotFound } from "./Screens/PageNotFound";
import Category from "./Screens/Category";
import Topic from "./Screens/Topic";
import Quizzes from "./Screens/Quizzes";
import ViewQuizScreen from "./Screens/ViewQuiz";
import "./assets/styles/Style.css";
import CreateQuizScreen from "./Screens/CreateQuiz";
import QuestionPool from "./Screens/questionsPool/Questions";
import AddQuestion from "./Screens/questionsPool/AddQuestion";
import EditQuestion from "./Screens/questionsPool/EditQuestion"
import RandomlyData from "./components/table/randomlyData";
import ReplaceQuestion from "./Screens/replaceQuestion";
import SelectedQuestions from "./layout/addQuestionLayout/selectedQuestions";
import MainComponent from "./components/modal/leaderBoardModal";
import UserScreen from "./Screens/Users/usersScreen";
import CreateUser from "./Screens/Users/createUser";
import EditUser from "./Screens/Users/updateUser";
import ViewUser from "./Screens/Users/viewUser";
import AllWidthrawal from "./Screens/Users/viewUser/buttonAction/allWidthrawal";
import CoustomerSuport from "./Screens/customerSupport";
import Notification from "./Screens/notification/notificationScreen";
import Transections from "./Screens/Transactions/transactionsScreen";
import Setting from "./Screens/Settings/settings";
import ActivityTracker from "./Screens/Settings/activityTracker";
import CommissionRates from "./Screens/Settings/commissionRates";
import ReviewTime from "./Screens/Settings/reviewTime";
import Assign from "./Screens/Assign";
import AccessDenied from "./Screens/AccessDenied";
import VersionControl from "./Screens/Settings/versionControl";
import ServerIP from "./Screens/Settings/serverIP";
import SiteBlocked from "./Screens/siteBlocked";
import AssignAccess from "./Screens/Settings/AssignAccess";
import "react-tooltip/dist/react-tooltip.css";
import AnalyticsPage from "./Screens/dashboard/analytics";
import AnalyticsActivity from "./Screens/dashboard/analyticsActivity";
import Dashboard from "./Screens/dashboard/Dashboard";
import EditAdminUser from "./Screens/Users/viewUser/updateAdminUser";
import NotificationHistory from "./Screens/notification/notificationHistory";
import QuizSpotPercentage from "./Screens/Settings/quizSpotPercentage";
import QuizBotsProbability from "./Screens/Settings/quizBotProbability";
import UpdateScheduleNotification from "./Screens/notification/updateScheduleNotification";
import ParticipantsPage from "./Screens/dashboard/participantsPage";
import ManageQuestion from "./components/viewQuiz/ManageQuestion";
import BannerSetting from "./Screens/Settings/BannerSetting";
import RewardManagement from "./Screens/Settings/RewardManagement";
import ViewUserQuestion from "./components/viewQuiz/ViewUserQuestion";
import WalletManagement from "./Screens/Settings/WalletManagement";
import SoundManagement from "./Screens/Settings/soundManagement";
import PopupsManagement from "./Screens/Settings/PopupsManagement";

const App = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboardAnalytics" element={<AnalyticsPage />} />
        <Route path="/analyticsActivity" element={<AnalyticsActivity />} />
        {/* <Route path="/displayFirestoreData" element={<DisplayFirestoreData />} /> */}
        <Route path="/categories" element={<Category />} />
        <Route path="/topics" element={<Topic />} />
        <Route path="/quizzes" element={<Quizzes />} />
        <Route path="/createQuiz" element={<CreateQuizScreen />} />
        <Route path="/viewQuiz" element={<ViewQuizScreen />} />
        <Route path="/questionPool" element={<QuestionPool />} />
        <Route path="/addQuestion" element={<AddQuestion />} />
        <Route path="/updateQuestion" element={<EditQuestion />} />
        <Route path="/ReplaceQuestion" element={<ReplaceQuestion />} />
        <Route path="/UserQuestions" element={<RandomlyData />} />
        <Route path="/selectedQuestions" element={<SelectedQuestions />} />
        <Route path="/BulkUpload" element={<MainComponent />} />
        <Route path="/players" element={<UserScreen />} />
        <Route path="/createUser" element={<CreateUser />} />
        <Route path="/updatePlayer" element={<EditUser />} />
        <Route
          path="/updateScheduleNotification"
          element={<UpdateScheduleNotification />}
        />
        <Route path="/updateUser" element={<EditAdminUser />} />
        <Route path="/viewPlayers" element={<ViewUser />} />
        <Route path="/viewWithdrawal" element={<AllWidthrawal />} />
        <Route path="/customerSupport" element={<CoustomerSuport />} />
        <Route path="/customerSupport/:id?" element={<Assign />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/notificationHistory" element={<NotificationHistory />} />
        <Route path="/transaction" element={<Transections />} />
        <Route path="/manageAccess" element={<Setting />} />
        <Route path="/manageAccess/:id?" element={<AssignAccess />} />
        <Route path="/activity" element={<ActivityTracker />} />
        <Route path="/commissionRate" element={<CommissionRates />} />
        <Route path="/quizSpotsPercent" element={<QuizSpotPercentage />} />
        <Route path="/quizBotProbability" element={<QuizBotsProbability />} />
        <Route path="/reviewTime" element={<ReviewTime />} />
        <Route path="/versionControl" element={<VersionControl />} />
        <Route path="/serverInfo" element={<ServerIP />} />
        <Route path="/participants" element={<ParticipantsPage />} />
        <Route path="/banner" element={<BannerSetting />} />
        <Route path="/reward" element={<RewardManagement />} />
        <Route path="/wallet" element={<WalletManagement/>} />
        <Route path="/manageQuestion" element={<ManageQuestion />} />
        <Route path="/viewQuestions" element={<ViewUserQuestion />} />
        <Route path="/sound" element={<SoundManagement/>}/>
        <Route path="/popups" element={<PopupsManagement/>}/>
      </Route>
      <Route path="/blocked" element={<SiteBlocked />} />
      <Route exact path="/not-authorized" element={<AccessDenied />} />
      <Route path="/login" element={<AdminLogin />} />
      <Route exact path="*" element={<PageNotFound />} />
    </Routes>
  );
};
export default App;
